<template>
  <div>

    <gnb class="mbg">
      <template  slot="desc">
        집행 완료된 캠페인의 최종 결과 데이터를 업로드합니다.

        <table style="margin-top:40px">
          <tr>
            <td>
              <select class="form-select" aria-label="광고주"
                      v-model="advertiserId"
                      style="min-width: 250px"
              >
                <option :value="null" selected>광고주명 선택</option>
                <option
                    v-for="item in advertisers"
                    :key="item.value"
                    :value="item.value">{{item.name}}</option>
              </select>

            </td>
            <td style="width: 15px">
            </td>
            <td>
              <select class="form-select" aria-label="매체 유형"
                      v-if="type_list"
                      v-model="mediaTypeTemplateId"
              >
                <option :value="null" selected>매체 유형 선택</option>
                <option
                    v-for="item in type_list"
                    :key="item.templateId"
                    :value="item.templateId">{{ item.displayName }}</option>
              </select>
            </td>
          </tr>
        </table>

      </template>
    </gnb>

    <div class="mcontent" v-if="mediaTypeTemplateId">

      <block-tab
          v-model="mytab"
          :tabs="[{name:'파일 업로드', id:0},{name:'직접 기입', id:1}]"
      />

      <div v-if="mytab==0">

        <div style="padding: 20px">
          <div class="d-grid">

            <div style="background-color: white;padding: 25px;border-radius: 8px">
              <div style="border-radius: 8px;border: #797f86 2px dotted">
                <vue-dropzone
                    @vdropzone-sending="vdropzone_sending"
                    @vdropzone-queue-complete="vdropzone_queue_complete"
                    ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
              </div>
            </div>


            <div class="input-group mb-3" v-if="false">
              <input type="file" ref="file" class="form-control" id="customFile"
                     @change="handleFileUpload"
              >
            </div>

            <div style="display:flex;padding-top: 12px">
              <div style="display: flex;width: 100%">
                <button type="button"
                        v-for="(i,idx) in ['Digital','TV','OOH','Radio','PPL']"
                        @click="download_ty(i)"
                        :style="idx==0?'flex:1':'flex: 1;margin-left: 8px'"
                        class="btn btn-secondary waves-effect waves-light"
                >
                  템플릿 다운로드 ({{i}})
                </button>
              </div>
              <button type="button"
                      v-if="false"
                      style="margin-left: 10px;flex: 1"
                      @click="upload"
                      class="btn btn-primary waves-effect waves-light"
                      :disabled="uploading"
              >
                등록하기
              </button>

            </div>

          </div>
        </div>


      </div>
      <div v-if="mytab==1" style="padding: 20px 0;position: relative;">

        <div style="display: flex;padding-bottom: 12px">
          <div style="flex-grow: 1">

          </div>
          <div>
            <button type="button"
                    class="btn btn-danger waves-effect waves-light"
                    @click="appendRow"
            >
              행 추가
            </button>
          </div>
        </div>

        <grid
            theme="striped"
            :key="mediaTypeTemplateId"
            ref="detail"
            :columnOptions="columnOptions"
            :data="gridData['data'+mediaTypeTemplateId]"
            :rowHeaders="rowHeaders"
            :columns="cols"
        />

        <div class="mt-3 d-grid">
          <button type="button"
                  class="btn btn-primary waves-effect waves-light"
                  @click="upload_manual"
          >
            등록하기
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import BlockTab from "@/components/common/BlockTab";
import {mapGetters} from "vuex";
import Gnb from "@/nav/gnb";
export default {
  computed: {
    ...mapGetters({
      advertisers:'advertisers',
      main_media_type:'main_media_type',
      first_media_type:'first_media_type',
      dashboard:'dashboard'
    }),
    cols:function () {

      var that = this

      var number = function (w) {
        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (w + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        return n
      }

      class CustomTextButton {
        constructor(props) {

          const el = document.createElement('button');

          el.setAttribute('rowKey', props.rowKey)
          el.onclick=function (){
            var a = this.getAttribute('rowKey')
            a = parseInt(a)
            that.removeRow(a)
          }
          el.type = 'button';
          el.setAttribute('class','btn btn-secondary waves-effect waves-light x-small')
          //el.maxLength = maxLength;
          //el.value='del'
          el.setAttribute('style','font-size: 10px;line-height: 1;padding: 3px 3px;')
          el.innerHTML='삭제'
          this.el = el;
        }

        getElement() {
          return this.el;
        }

        getValue() {
          //return this.el.value;
        }

        mounted() {
          //this.el.select();
        }
      }

      class CustomTextEditor {
        constructor(props) {
          const el = document.createElement('input');
          //const { maxLength } = props.columnInfo.editor.options;

          el.type = 'text';
          //el.maxLength = maxLength;
          var v = String(props.value);
          el.value = v
          // comma

          this.el = el;
        }

        getElement() {
          return this.el;
        }

        getValue() {
          return this.el.value;
        }

        mounted() {
          this.el.select();
        }
      }

      class CustomTextEditorNumber {
        constructor(props) {
          const el = document.createElement('input');
          //const { maxLength } = props.columnInfo.editor.options;

          el.type = 'text';
          //el.maxLength = maxLength;
          var v = String(props.value);
          v = v.replaceAll(',','')
          el.value = v
          // comma
          el.onkeydown=function (e) {
            var a = '0123456789'
            if(a.indexOf(e.key)>=0)
              return true

            if(e.key=='.')
              return true
            if(e.key=='Backspace')
              return true
            if(e.key=='Enter')
              return true
            if(e.key=='ArrowLeft')
              return true
            if(e.key=='ArrowRight')
              return true

            e.preventDefault()
            return false
          }

          this.el = el;
        }

        getElement() {
          return this.el;
        }

        getValue() {
          var v = number(this.el.value)
          return v
        }

        mounted() {
          this.el.select();
        }
      }

      var sel = this.my_selected_type
      var a = this.first_media_type.historyDB
      var l = a[sel]

      var filter = ['영상길이(초)',
        'Exp.Unit Price', 'Act.Unit Price',
        'Actual Budget(Net)', 'Actual Imps.', 'Actual Clicks',
        'Actual Engagement', 'Actual Views',
        'Play Completion ', 'Actual Install',
        'Actual CPM', 'Actual CPC', 'Actual CPE',
        'Actual CPVC', 'Actual CPV', 'Actual CPI']

      var arr = []
      arr.push({
        header:'',
        name:'',
        width:40,
        align:'center',
        renderer:{
          type:CustomTextButton
        }
      })
      l.forEach(el=>{
        var name = el.displayName
        //
        var b = filter.includes(name)
        if(b) {
          arr.push({
            header:el.displayName,
            name:el.displayName,
            width:120,
            editor:{
              type:CustomTextEditorNumber
            }
          })
        } else {
          arr.push({
            header:el.displayName,
            name:el.displayName,
            width:150,
            editor:{
              type:CustomTextEditor
            }
          })
        }
      })

      return arr
    }
  },
  components: {
    vueDropzone:vue2Dropzone,
    Gnb,
    BlockTab
  },
  data() {
    return {

      dropzoneOptions: {
        //var url = '/api/history/uploadFile?'
        url: '/api/history/uploadFile?',
        timeout: 0,
        maxFilesize: 1000,
        maxThumbnailFilesize: 100,
        thumbnailWidth: 150,
        dictDefaultMessage: "<div style='letter-spacing: -1px'><div style='font-size: 40px'><i class=\"fas fa-file-upload\"></i></div><div style='font-size: 17px;font-weight: bold'>Drag and Drop your files here (csv, xlsx)</div><div style='font-size:13px;color: #948e8e'>You can alose upload files by</div><div style='font-size:12px;color: #0d6efd;text-decoration: underline'>clicking here</div></div>",
        headers: {
          //'Content-Type': 'multipart/form-data'
        }

      },
      type_list:null,
      uploading:false,
      my_selected_type:null,
      file:null,
      advertiserId:null,
      mediaTypeTemplateId:null,
      gridData:{},
      columnOptions : {
        //frozenCount: 1,
        //frozenBorderWidth: 1,
        resizable: true
      },
      rowHeaders : [
      ],

      mytab:0,
      advertiserOptions:null,
      mediaOptions:null,
    }
  },
  methods: {
    download_ty:function (ty) {
      this.$http({
        method: 'GET',
        url: '/api/history/download/history-template/' + ty.toLowerCase(),
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template_'+ty+'.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    download:function () {
      this.$http({
        method: 'GET',
        url: '/api/history/download/history-template',
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    removeRow:function (k) {
      this.$refs.detail.invoke('removeRow',k);
    },
    appendRow:function() {
      this.$refs.detail.invoke('appendRow', {});
    },
    handleFileUpload:function () {
      this.file = this.$refs.file.files[0];
    },
    upload_manual:function () {

      if(!this.advertiserId || !this.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '광고주 & 매체 유형을 모두 입력해주세요')
        return
      }

      //파일: Post http://localhost:8091/api/history/uploadFile?advertiserId=1&mediaTypeTemplateId=3
      var url = '/api/history/uploadData?'

      var data = {}
      data['advertiserId'] = this.advertiserId
      data['mediaTypeTemplateId'] = this.mediaTypeTemplateId
      data['rows'] = []

      var sel = this.my_selected_type
      var ab = this.first_media_type.historyDB
      var l = ab[sel]
      //console.log(l)

      var a = data['rows']
      var v = this.$refs.detail.invoke('getData')
      v.forEach(el=>{

        var o = {}
        var o1 = []

        l.forEach(v2=>{
          var k = v2.displayName
          var value = {}
          value['value']=el[k]
          o1.push(value)
        })
        o['cols'] = o1
        a.push(o)
      })

      this.$http.post(url,data)
          .then(() => {
            this.$eventBus.$emit('info', '등록완료 되었습니다.')
          })
          .catch(error => {

            // this.my_selected_type
            if(this.my_selected_type.toLowerCase() == 'tv') {
              this.$eventBus.$emit('error', 'Campaign, Product, Menu Category, Date, Type(TV), Channel Group, Channel 입력해주세요.')
              return
            }
            else if(this.my_selected_type.toLowerCase() == 'ooh') {
              this.$eventBus.$emit('error', 'Day, Product Category, Product, Media, Type(Digital OOH) 입력해주세요.')
              return
            }
            else if(this.my_selected_type.toLowerCase() == 'digital') {
              this.$eventBus.$emit('error', 'Product Category(없다면 공백), Campaign, Product, Device, Media Type, Media, 년도, 월, 일 입력해주세요.')
              return
            }
            else if(this.my_selected_type.toLowerCase() == 'radio') {
              this.$eventBus.$emit('error', 'Campaign, Product, Menu category, Date, station 입력해주세요.')
              return
            }
            else if(this.my_selected_type.toLowerCase() == 'ppl') {
              this.$eventBus.$emit('error', 'Campaign, Product, Menu category, Week, platform, channel 입력해주세요.')
              return
            }
            this.$eventBus.$emit('error',error.errorMessage)
          })
    },
    vdropzone_queue_complete:function() {
      //this.$eventBus.$emit('info', '등록완료 되었습니다.')
      //this.uploading = false
      //this.$eventBus.$emit('loading', false)
      console.log('comp??')
    },
    vdropzone_sending:function(file, xhr, formData){


      //formData.append('file', this.file);
      formData.append('advertiserId', this.advertiserId);
      formData.append('mediaTypeTemplateId', this.mediaTypeTemplateId);

      console.log(file)
      console.log(formData)
    },
    upload:function () {

      if(!this.advertiserId || !this.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '광고주 & 매체 유형을 모두 입력해주세요')
        return
      }

      this.uploading = true

      //파일: Post http://localhost:8091/api/history/uploadFile?advertiserId=1&mediaTypeTemplateId=3
      var url = '/api/history/uploadFile?'
      //url += '&advertiserId='+this.advertiserId
      //url += '&mediaTypeTemplateId='+this.mediauiTypeTemplateId

      this.$eventBus.$emit('loading', true)

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('advertiserId', this.advertiserId);
      formData.append('mediaTypeTemplateId', this.mediaTypeTemplateId);

      this.$http.post(url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            this.$eventBus.$emit('info', '등록완료 되었습니다.')
            this.uploading = false

            this.$eventBus.$emit('loading', false)
          })
          .catch(error => {
            this.$eventBus.$emit('error','오류가 발생했습니다.')
            this.uploading = false

            this.$eventBus.$emit('loading', false)
          })
    },
    getAdvertiserList() {
      this.$getAdvertisers()
    },
  },
  watch: {
    mediaTypeTemplateId:function () {

      var sel = ''
      var v = this.mediaTypeTemplateId
      this.first_media_type.mediaType.forEach(el=>{
        if(el.templateId == v) {
          sel = el.displayName.toLowerCase()
        }
      })

      this.my_selected_type = sel
    },
  },
  mounted() {

    this.type_list = []
    this.first_media_type.mediaType.forEach(el=>{
      if(this.dashboard) {
        if(el.displayName.toUpperCase() == 'DIGITAL') {
          this.type_list.push(el)
        }
      } else {
        this.type_list.push(el)
      }
    })

    this.gridData={}
    this.first_media_type.mediaType.forEach(el=>{
      this.gridData['data'+el.templateId.toString()] = [{}]
    })

    this.getAdvertiserList()
  }
}
</script>
<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 95% !important;max-width: 95% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              캠페인 상세 진행 현황
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div
                style="max-height: 80vh;overflow-y: auto;padding:0"
            >

              <my-table
                  clickable
                  @rowClick="rowClick"

                  :head-sort="head_sort"
                  :key="flag"
                  :head="myheader"
                  :tableData="items"
                  :format="myformat"
                  @grp="on_grp"
                  :head-id="myHeaderId"
                  :head-split="myHeaderSplit"
              >
                <template slot="__slot_header_th2" v-if="header_colspan">
                  <tr v-if="!show_app">
                    <th :colspan="header_colspan[0]" style=";padding:0 5px" v-if="header_colspan[0]">
                    </th>
                    <th  v-if="header_colspan[1]" :colspan="header_colspan[1]" style="background-color: #BDD4FF;color: white;padding:0 5px">
                      Progress
                    </th>
                    <th  v-if="header_colspan[2]" :colspan="header_colspan[2]" style="background-color: #8BB3FF;color: white;padding:0 5px">
                      Actual
                    </th>
                    <th  v-if="header_colspan[3]" :colspan="header_colspan[3]" style="background-color: #425A99;color: white;padding:0 5px">
                      Goal
                    </th>
                  </tr>
                  <tr v-else>
                    <th :colspan="header_colspan[0]" style=";padding:0 5px" v-if="header_colspan[0]">
                    </th>
                    <th  v-if="header_colspan[1]" :colspan="header_colspan[1]" style="background-color: #BDD4FF;color: white;padding:0 5px">
                      Progress
                    </th>
                    <th  v-if="header_colspan[2]" :colspan="header_colspan[2]" style="background-color: #A08BFFFF;color: white;padding:0 5px">
                      Kochava
                    </th>
                    <th  v-if="header_colspan[3]" :colspan="header_colspan[3]" style="background-color: #8BB3FF;color: white;padding:0 5px">
                      Actual
                    </th>
                    <th  v-if="header_colspan[4]" :colspan="header_colspan[4]" style="background-color: #425A99;color: white;padding:0 5px">
                      Goal
                    </th>
                  </tr>
                </template>

                <template slot="__slot_header">
                  <h4 style="padding: 10px">
                    캠페인명 : {{campaignName}}
                  </h4>

                  <div style="display: flex;padding: 10px 0">
                    <div style="align-self: end;padding-left: 10px">
                      <input class="form-check-input" type="checkbox" v-model="show_danger" id="formCheck2">
                      <label class="form-check-label" for="formCheck2" style="margin-left: 5px">
                        주의 매체만 확인
                      </label>
                    </div>
                    <div style="margin-left:12px;align-self: end;">
                      <input class="form-check-input" type="checkbox" v-model="show_app" id="formCheck3">
                      <label class="form-check-label" for="formCheck3" style="margin-left: 5px">
                        앱수치 확인
                      </label>
                    </div>
                    <div style="flex-grow: 1"/>
                    <button
                        v-if="user.userinfo.roleId!=1"
                        type="button"
                        class="btn btn-primary waves-effect waves-light"
                        @click="mng_campaign"
                    >
                      캠페인 관리 바로가기
                    </button>
                    <button type="button"
                            style="margin-left: 10px;margin-right: 10px"
                            class="btn btn-dark waves-effect waves-light"
                            @click="download"
                    >
                      다운로드
                      <i class="fas fa-file-excel"></i>
                    </button>
                  </div>

                </template>

                <template slot="_slot_status" slot-scope="slotProps">
                  <span v-if="slotProps.item.status==1" class="safe"
                        @click.stop="change_status(slotProps.item)"
                  />
                  <div v-else >
                    <img src="/img/bulb_icon.png" style="width: 16.5px;cursor: pointer"
                         @click.stop="change_status(slotProps.item)"
                    />
                    <i v-if="slotProps.item.statusMessage" class="fas fa-exclamation-circle align-self-center"
                       style="vertical-align:middle;padding-left: 16px;font-size: 15px" @click.stop="alert_msg(slotProps.item.statusMessage)"></i>
                  </div>
                </template>


                <template slot="_slot_kochava_install" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.kochava"
                      v-html="my_number(slotProps.item.kochava.installs)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_kochava_reg" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.kochava"
                      v-html="my_number(slotProps.item.kochava.completedRegistrations)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_kochava_offer" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.kochava"
                      v-html="my_number(slotProps.item.kochava.redeemedOffers)"></span>
                  <span v-else>-</span>
                </template>


                <template slot="_slot_imps" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.imps)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_grps" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.grps)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_click" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.click)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_view" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.view)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_install" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.install)"></span>
                  <span v-else>-</span>
                </template>

                <template slot="_slot_spent_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.budgetRate)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_imp_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.impRate)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_grps_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.grpsRate)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_clk_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.clickRate)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_view_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.viewRate)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_install_rate" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.progress"
                      v-html="my_number(slotProps.item.progress.installRate)"></span>
                  <span v-else>-</span>
                </template>


                <template slot="_slot_ctr" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.ctr)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_vtr" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.vtr)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpm" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.cpm)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpc" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.cpc)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpv" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.cpv)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpvc" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.cpvc)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpi" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.cpi)"></span>
                  <span v-else>-</span>
                </template>

                <template slot="_slot_imps2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.imps)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_click2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.click)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_grps2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.grps)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_view2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.view)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_install2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.install)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cprp2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.cprp)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpm2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.cpm)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpc2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.cpc)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpv2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.cpv)"></span>
                  <span v-else>-</span>
                </template>
                <template slot="_slot_cpi2" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.goal"
                      v-html="my_number(slotProps.item.goal.cpi)"></span>
                  <span v-else>-</span>
                </template>

                <template slot="_slot_budget" slot-scope="slotProps">
                  <span
                      v-if="slotProps.item.actual"
                      v-html="my_number(slotProps.item.actual.spentMoney)"></span>
                  <span v-else>-</span>
                </template>


              </my-table>


            </div>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

    <detail2 @download="download2" ref="detail"/>
    <config ref="config" :subitem="true" @updated="load"/>
  </div>
</template>

<script>

import Detail2 from "@/pages/present/detail2";
import config from "@/pages/present/config";
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
import store from "@/store";
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
    MyTable,
    config,
    Detail2
  },
  props: {
  },
  computed: {
    myHeaderId:function () {
      //if(this)
      if(!this.show_app)
        return 'n_present_detail'
      else
        return 'n_present_detail_kochava'
    },
    myHeaderSplit:function () {
      if(!this.show_app) {
        return [
          {clr:'#CCC',name:'Status',cnt:this.getHeader2(0)},
          {clr:'#BDD4FF',name:'Progress',cnt:this.getHeader2(1)},
          {clr:'#8BB3FF',name:'Actual',cnt:this.getHeader2(2)},
          {clr:'#425A99',name:'Goal',cnt:this.getHeader2(3)},
        ]
      } else {
        return [
          {clr:'#CCC',name:'Status',cnt:this.getHeader2(0)},
          {clr:'#BDD4FF',name:'Progress',cnt:this.getHeader2(1)},
          {clr:'#A08BFFFF',name:'Kochava',cnt:3},
          {clr:'#8BB3FF',name:'Actual',cnt:this.getHeader2(2)},
          {clr:'#425A99',name:'Goal',cnt:this.getHeader2(3)},
        ]
      }
    },
    myheader:function () {

      var a = []
      if(!this.show_app) {
        if(!this.dashboard) {
          a = ['상태::@CENTER','매체명', '상품명',  //2
            '노출 달성율(%)::@NUM','GRPs 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM', //'설치 달성율(%)::@NUM',  //5
            'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPV(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM', //7
            ' CPM(원)::@NUM',' CPRP(원)::@NUM', ' CPC(원)::@NUM',' CPV(원)::@NUM',' CPI(원)::@NUM']  //5
        } else {
          a = ['상태::@CENTER','매체명', '상품명',  //2
            '노출 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM', //'설치 달성율(%)::@NUM',  //4
            'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPV(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM', //7
            ' CPM(원)::@NUM',
            ' CPC(원)::@NUM',' CPV(원)::@NUM',' CPI(원)::@NUM']  //4
        }
      } else {
        if(!this.dashboard) {
          a = ['상태::@CENTER','매체명', '상품명',  //2stat
            '노출 달성율(%)::@NUM','GRPs 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM', //'설치 달성율(%)::@NUM',  //5
            'Installs::@NUM','completed_registrations::@NUM','redeemed_offers::@NUM',
            'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPV(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM', //10
            ' CPM(원)::@NUM',' CPRP(원)::@NUM',
            ' CPC(원)::@NUM',' CPV(원)::@NUM',' CPI(원)::@NUM']  //5 goal
        } else {
          a = ['상태::@CENTER','매체명', '상품명', //2stat
            '노출 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM', //'설치 달성율(%)::@NUM',  //4
            'Installs::@NUM','completed_registrations::@NUM','redeemed_offers::@NUM',
            'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPV(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM', //10
            ' CPM(원)::@NUM',
            ' CPC(원)::@NUM',' CPV(원)::@NUM',' CPI(원)::@NUM']  //4
        }
      }

      return a
    },
    myformat:function () {

      if(!this.show_app) {

        if(!this.dashboard) {
          this.head_sort = ['status','mediaName', 'adProductName',
            'progress@impRate::@NUM','progress@grpsRate::@NUM','progress@clickRate::@NUM','progress@viewRate::@NUM', //'progress@installRate',
            'actual@ctr::@NUM','actual@vtr::@NUM','actual@cpm::@NUM','actual@cpc::@NUM','actual@cpv::@NUM','actual@cpvc::@NUM','actual@cpi::@NUM',
            'goal@cpm::@NUM','goal@cprp::@NUM',
            'goal@cpc::@NUM','goal@cpv::@NUM','goal@cpi::@NUM']


          return ['_slot_status','mediaName', 'adProductName',
            '_slot_imp_rate','_slot_grps_rate','_slot_clk_rate','_slot_view_rate', //'_slot_install_rate',
            '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpv','_slot_cpvc','_slot_cpi',
            '_slot_cpm2','_slot_cprp2',
            '_slot_cpc2','_slot_cpv2','_slot_cpi2',
          ]

        } else {
          this.head_sort = ['status','mediaName', 'adProductName',
            'progress@impRate::@NUM','progress@clickRate::@NUM','progress@viewRate::@NUM', //'progress@installRate',
            'actual@ctr::@NUM','actual@vtr::@NUM','actual@cpm::@NUM','actual@cpc::@NUM','actual@cpv::@NUM','actual@cpvc::@NUM','actual@cpi::@NUM',
            'goal@cpm::@NUM',
            'goal@cpc::@NUM','goal@cpv::@NUM','goal@cpi::@NUM']


          return ['_slot_status','mediaName', 'adProductName',
            '_slot_imp_rate','_slot_clk_rate','_slot_view_rate', //'_slot_install_rate',
            '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpv','_slot_cpvc','_slot_cpi',
            '_slot_cpm2',
            '_slot_cpc2','_slot_cpv2','_slot_cpi2']
        }

      } else {

        if(!this.dashboard) {
          this.head_sort = ['status','mediaName', 'adProductName',
            'progress@impRate::@NUM','progress@grpsRate::@NUM','progress@clickRate::@NUM','progress@viewRate::@NUM', //'progress@installRate',
            'kochava@installs::@NUM','kochava@completedRegistrations::@NUM','kochava@redeemedOffers::@NUM',
            'actual@ctr::@NUM','actual@vtr::@NUM','actual@cpm::@NUM','actual@cpc::@NUM','actual@cpv::@NUM','actual@cpvc::@NUM','actual@cpi::@NUM',
            'goal@cpm::@NUM','goal@cprp::@NUM',
            'goal@cpc::@NUM','goal@cpv::@NUM','goal@cpi::@NUM']

          return ['_slot_status','mediaName', 'adProductName',
            '_slot_imp_rate','_slot_grps_rate','_slot_clk_rate','_slot_view_rate', //'_slot_install_rate',
            '_slot_kochava_install','_slot_kochava_reg','_slot_kochava_offer',
            '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpv','_slot_cpvc','_slot_cpi',
            '_slot_cpm2','_slot_cprp2',
            '_slot_cpc2','_slot_cpv2','_slot_cpi2',
          ]
        } else {
          this.head_sort = ['status','mediaName', 'adProductName',
            'progress@impRate::@NUM','progress@clickRate::@NUM','progress@viewRate::@NUM', //'progress@installRate',
            'kochava@installs::@NUM','kochava@completedRegistrations::@NUM','kochava@redeemedOffers::@NUM',
            'actual@ctr::@NUM','actual@vtr::@NUM','actual@cpm::@NUM','actual@cpc::@NUM','actual@cpv::@NUM','actual@cpvc::@NUM','actual@cpi::@NUM',
            'goal@cpm::@NUM',
            'goal@cpc::@NUM','goal@cpv::@NUM','goal@cpi::@NUM']

          return ['_slot_status','mediaName', 'adProductName',
            '_slot_imp_rate','_slot_clk_rate','_slot_view_rate', //'_slot_install_rate',
            '_slot_kochava_install','_slot_kochava_reg','_slot_kochava_offer',
            '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpv','_slot_cpvc','_slot_cpi',
            '_slot_cpm2',
            '_slot_cpc2','_slot_cpv2','_slot_cpi2']
        }
      }
    },
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({
      user:'user',
      dashboard:'dashboard'
    }),
  },
  data () {
    return {
      header_colspan:null,
      head_sort:null,
      campaignId:null,
      detailData:null,
      mydata:null,
      flag:0,
      show_danger:false,
      show_app:false,
      campaignName:null,
      items:null,
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      },
      businessOptions: [],
    }
  },
  mounted() {
    this.header_colspan=[]
    this.header_colspan.push(this.getHeader2(0))
    this.header_colspan.push(this.getHeader2(1))
    this.header_colspan.push(this.getHeader2(2))
    this.header_colspan.push(this.getHeader2(3))
  },
  methods: {
    rowClick:function (item) {

      console.log(item)

      var o = {}
      o['mediaId'] = item['mediaId']
      o['adProductId'] = item['adProductId']
      o['campaignId'] = this.campaignId

      if(!o['adProductId'] || o['adProductId']=='null')
        o['adProductId'] = 0

      this.detailData = o

      var url = '/api/campaign/dashboard/detail-detail?'
      url += '&mediaId='+item['mediaId']
      url += '&campaignId='+this.campaignId
      url += '&adProductId='+o['adProductId']

      this.$http.get(url)
          .then(({ data }) => {
            this.$refs.detail.open(data)
          })
    },
    on_grp:function (v) {

      this.header_colspan=[]
      for(var i=0;i<v.length;i++) {
        this.header_colspan.push(v[i])
      }
    },
    getHeader2:function (idx) {

      if (idx == 0) {
        if (!this.dashboard) {
          return 3
        } else {
          return 3
        }
      }
      else if (idx == 1) {
        if (!this.dashboard) {
          //return 5
          return 4
        } else {
          //return 4
          return 3
        }
      } else if (idx == 2) {

        if (!this.show_app) {
          return 7
        } else {
          //return 10
          return 7  // => kochava
        }
      } else if (idx == 3) {
        if (!this.dashboard) {
          return 5
        } else {
          return 4
        }
      }

      return 999
    },
    download2:function () {

      this.$http({
        method: 'POST',
        url: '/api/campaign/download/dashboard/detail-detail?campaignId='+this.detailData['campaignId']+'&mediaId='+this.detailData['mediaId']+'&adProductId='+this.detailData['adProductId'],
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'present-detail-detail.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    download:function () {

      //http://ga-test.snadx.com:8091/api/campaign/dashboard/detail?campaignId=3
      this.$http({
        method: 'GET',
        url: '/api/campaign/download/dashboard/detail?campaignId='+this.campaignId,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'present-detail.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    mng_campaign:function () {
      this.$router.push({path:'/setting/presentDB',query:{campaignId:this.campaignId}})
    },
    change_status:function (item) {
      // 운영계정
      if(this.user.userinfo.roleId!=2) {
        return
      }

      item.campaignId = this.campaignId
      this.$refs.config.open(item)
    },
    alert_msg:function (msg) {
      this.$eventBus.$emit('error', msg)
    },

    my_number:function (v) {

      var fl = true
      if(v == parseInt(v)) {
        fl = false
      }

      if(fl)
        v = parseFloat(v).toFixed(2)

      try {
        v = this.numberWithCommas(v)
      } catch (e) {}

      if(!v) {
        return '-'
      }
      return v
    },
    init:function () {
    },
    load:function () {

      this.$http.get('api/campaign/dashboard/detail?campaignId='+this.campaignId)
          .then(({ data }) => {

            this.mydata = data
            this.items = JSON.parse(JSON.stringify(this.mydata))
            if(this.show_danger) {

              var a = this.items
              for(var i=0;i<a.length;i++) {
                var el = a[i]
                if(el.status.toString()!='2') {
                  a.splice(i,1)
                }
              }

            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    open:function (item) {

      var id = item.campaignId
      this.campaignId = id

      this.campaignName = item.campaignName
      this.reset()
      this.dlg_open = true

      this.load()
    },
    onSubmit() {

    },
    reset() {
    }
  },
  watch: {
    show_app:function () {
      this.flag++

      // colspan
    },
    show_danger:function () {
      this.flag++

      this.items = JSON.parse(JSON.stringify(this.mydata))
      if(this.show_danger) {

        var a = this.items
        for(var i=a.length-1;i>=0;i--) {
          var el = a[i]
          if(el.status.toString()!='2') {
            a.splice(i,1)
          }
        }
      }

    }
  }
}
</script>

<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog">
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              계정 정보 수정
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <table class="table table-hover text-left no-border" style="width: 100%">
              <tbody>
              <tr>
                <th class="th-width font-weight-bold border-top-0">
                  소속회사
                </th>
                <td class="td-width border-top-0">
                  {{ user.userinfo.companyName }}
                  <span v-if="user.userinfo.advertiserName">
                    / {{user.userinfo.advertiserName}}
                  </span>
                </td>
              </tr>
              <tr>
                <th class="th-width font-weight-bold">
                  이름(ID)
                </th>
                <td class="td-width">
                  {{ user.userinfo.userId }}
                </td>
              </tr>
              <tr>
                <th class="th-width font-weight-bold">
                  권한 유형
                </th>
                <td class="td-width">
                  {{ user.userinfo.roleName }}
                </td>
              </tr>
              <tr>
                <th class="th-width font-weight-bold">
                  비밀번호
                </th>
                <td class="td-width">
                  <input class="form-control" type="password" placeholder="비밀번호를 입력해주세요."
                         v-model="pass1"
                  >
                </td>
              </tr>
              <tr>
                <th class="th-width font-weight-bold">
                  비밀번호 확인
                </th>
                <td class="td-width">
                  <input class="form-control" type="password" placeholder="비밀번호를 다시 입력해주세요."
                         v-model="pass2"
                  >
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    class="btn btn-primary waves-effect waves-light"
                    @click="confirm"
            >
              확인
            </button>
          </div>
        </div><!-- /.modal-content -->

      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EDIT_ADVERTISER} from "@/pages/setting/system/mapi/constants";

export default {
  name: 'modifyAccountModal',
  computed: {
    ...mapGetters({
      user:'user',
    }),
  },
  props: {
    value: {
      default: null
    }
  },
  data () {
    return {
      dlg_open:false,
      modalTitle: '계정 정보 수정',
      apiUrl: 'xxx',
      pass1:'',
      pass2:'',
    }
  },
  mounted() {

  },
  methods: {
    confirm () {

      if(this.pass1) {

        if(this.pass1 != this.pass2) {
          this.$eventBus.$emit('error','비밀번호가 일치하지 않습니다.')
          return
        }
        if(this.pass1.length < 4) {
          this.$eventBus.$emit('error','비밀번호를 최소 4자이상 입력해주세요.')
          return
        }

        // 수정
        this.$http.put('/api/user/modify?password='+encodeURI(this.pass1))
            .then(({ data }) => {
              this.$eventBus.$emit('info','비밀번호가 수정되었습니다.')
              this.dlg_open = false
            }).catch(err=>{
          this.$eventBus.$emit('error','비밀번호 수정에 실패했습니다.')
        })
      }
    },
    open () {
      this.dlg_open = true

      //console.log(this.user.userinfo.roleId)
    },
    requestChange () {

    }
  },
}
</script>

<style>
.th-width {
  width: 150px;
  vertical-align: middle;
}
.td-width {
  padding:1em;
}
</style>
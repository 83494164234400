export const GET_MEDIA_LIST = '/api/media/list'
export const GET_MEDIA_DETAIL = '/api/media/detail'

export const ADD_MEDIA = '/api/media/add'
export const MEDIA_ACTIVATE = '/api/media/activate'
export const MEDIA_REJECT = '/api/media/reject'

export const MEDIA_MODIFY = '/api/media/modify'

// media

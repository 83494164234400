<template>
  <div class="page_parent">
    <gnb class="mbg" :hide_nav="true">
    </gnb>
    <div style="display:flex;height: 100%;width: 100%;overflow-x:auto">
      <div style="flex: 1;align-self: center">
        <div style="display: flex;padding: 0 20px;">
          <div style="flex: 1" class="row1">
            <div
                >
              <div class="card main1" style="overflow: unset">
                <div class="arrow2"
                     @click='clk_history'
                     style="cursor: pointer"
                >
                  <img src="/img/ico_arrow.svg">
                </div>
                <div class="ico_box"
                     @click='clk_history'
                     style="cursor: pointer"
                >
                  <img src="/img/ico_main1.svg"/>
                </div>

                <span
                    @click='clk_history'
                    style="cursor: pointer"
                >
                  HISTORY
                </span>

                <div class="sub1"
                     @click='clk_history'
                     style="cursor: pointer"

                >
                  캠페인 및 매체 별 광고 히스토리 조회
                </div>

                <div
                    style="padding-top: 15px;color: #666"
                >
                  <div>
                    <my-range-edit style="width: 100%" v-model="range"/>
                  </div>

                  <div style="margin-top: 8px;font-size: 12px">

                    <my-search-box
                        v-model="search_txt"
                        @focus="onfocus"
                        :options="options"
                        @selected="search_selected"
                        @onsearch2="onsearch2"
                        hint="검색어를 입력해주세요."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1" class="row1">
            <router-link :to="'/present'">
              <div class="card main1">
                <div class="arrow2">
                  <img src="/img/ico_arrow.svg">
                </div>

                <div class="ico_box">
                  <img src="/img/ico_main2.svg"/>
                </div>

                PRESENT
                <div class="sub1">
                  현재 집행 중인 캠페인의
                  <br/>
                  현황 데이터 조회
                </div>

              </div>
            </router-link>
          </div>

          <div style=";flex: 1" class="row1">
            <router-link :to="'/forecast'">
              <div class="card main1">
                <div class="arrow2">
                  <img src="/img/ico_arrow.svg">
                </div>

                <div class="ico_box">
                  <img src="/img/ico_main3.svg"/>
                </div>

                FORECAST
                <div class="sub1">
                  매체 및 상품 단위의
                  <br/>
                  예상 효율을 제시
                </div>

              </div>
            </router-link>
          </div>
          <div
              v-if="user.userinfo.roleId!=1"
              style=";flex: 1" class="row1">
            <router-link :to="'/setting'">
              <div class="card main1">
                <div class="arrow2">
                  <img src="/img/ico_arrow.svg">
                </div>

                <div class="ico_box">
                  <img src="/img/ico_main4.svg"/>
                </div>

                SETTING
                <div class="sub1">
                  실시간 운영 캠페인
                  <br/>
                  데이터 관리
                </div>

              </div>
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import gnb from "@/nav/gnb";
import {mapGetters} from "vuex";
import MyRangeEdit from "@/common/MyRangeEdit";
import moment from "moment";
import MySearchBox from "@/components/common/MySearchBox";
  export default {
    components:{
      gnb,
      MyRangeEdit,
      MySearchBox
    },
    methods:{
      clk_history:function () {
        var data = {}
        data['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
        data['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')

        this.$router.push({path:'/history',query:data})
      },
      getParams:function () {
        var data = {}
        //data['advertiserIds'] = advertiserIds
        data['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
        data['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')
        data['campaignName'] = this.search_txt

        return data
      },
      onfocus:function () {
        this.onsearch()
      },
      search_selected:function (v) {
        var param = {}
        param['type'] = 'campaignName'
        param['value'] = v
        param['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
        param['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')
        this.$router.push({path:'/history',query:param})
      },
      onsearch2:function (k, resolve) {

        this.search_txt = k
        var data = this.getParams()
        if(!data)
          return

        this.$http.post('/api/history/dashboard-new',data)
            .then(({ data }) => {

              var a = []
              data.forEach(el=>{
                a.push(el['campaignName'])
              })

              resolve(a)
              //this.options = a
            }).catch(err=>{
        })

      },
    },
    data() {
      return {
        options:{
          placeholder:'검색어를 입력해주세요.',
          inputClass:'form-control'
        },

        search_txt:null,
        range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      }
    }
    },
    name: 'Intro',
    computed: {
      ...mapGetters({
        user:'user',
      }),
    },
  }
</script>
<style>
.vs__dropdown-toggle {
  background-color: white;
}
</style>
<style scoped>
.row1 {
  padding: 12px;
  margin: 0;
}
.main1 {
  border-radius: 15px;
  background-color: #1B2E57;
  color: #fff;
  font-size: 28px;
  padding: 30px;
  padding-top: 40px;
  text-align: left;
  font-weight: bold;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  min-width: 230px;
  margin: 0 !important;
}
.sub1 {
  padding-top: 20px;
  font-size: 15px;
  font-weight: normal;
}
.ico_box {
  margin-bottom: 18px;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  display: flex;
  background-color: #F2F7FA;
  justify-content: center;
}
.ico_box img {
  width: 24px;
  align-self: center;
}
.arrow2 {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
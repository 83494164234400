<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 400px !important;max-width: 400px !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              데이터 다운로드
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="mb-4">
              <input type="text" class="form-control" placeholder="파일명"
                     v-model="fileName"
              >
            </div>

            <h5 class="mb-4">
              표 선택 옵션
            </h5>
            <div class="mb-2">
              <input
                  v-model="option"
                  value="2"
                  class="form-check-input" type="radio" name="formRadios" id="formRadios1">
              <label class="form-check-label pl-2" for="formRadios1">
                테이블 통합 다운로드
              </label>
            </div>
            <div class="mb-2">
              <input
                  v-model="option"
                  value="1"
                  class="form-check-input" type="radio" name="formRadios" id="formRadios2">
              <label class="form-check-label pl-2" for="formRadios2">
                캠페인 효율 다운로드
              </label>
            </div>
            <div class="mb-2">
              <input
                  v-model="option"
                  value="3"
                  class="form-check-input" type="radio" name="formRadios" id="formRadios3">
              <label class="form-check-label pl-2" for="formRadios3">
                롬스 양식 다운로드 (Digital)
              </label>
            </div>
          </div>


          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="ok"
                    class="btn btn-primary waves-effect waves-light"
            >
              다운로드
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

  </div>
</template>

<script>
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  components: {
  },
  props: {
  },
  computed: {
  },
  data () {
    return {
      option:1,
      fileName:'',
      dlg_open:false,
    }
  },
  methods: {
    ok:function () {

      if(!this.fileName) {
        this.$eventBus.$emit('error', '파일이름을 입력해주세요.')
        return
      }

      this.$emit('do_download', this.fileName, this.option)
      this.dlg_open = false
    },
    open:function () {
      this.dlg_open = true
    }
  }
}
</script>

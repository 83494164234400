<template>
    <div class="page_parent" v-if="media_products_info">
      <gnb class="mbg">
        <template  slot="desc">
          과거 집행 데이터를 기반으로 광고 효율을 예측해보고, 미디어믹스를 구성합니다.
        </template>
      </gnb>

      <div class="mcontent">

        <div class="card border border-primary" style="overflow: visible">
          <div class="card-header bg-transparent border-primary">
            <h4>
              기간 설정
            </h4>
          </div>
          <div class="card-body" style="padding-top: 0;position: relative">

            <table style="width: 100%">
              <tr>
                <td style="width: 110px">
                  기간
                </td>
                <td>

                  <div style="display: flex">
                    <my-range-edit style="width: 100%" v-model="range"/>

                    <div style="margin-left: 5px;width: 60px;" class="d-grid">
                      <button type="button"
                              @click="search"
                              class="btn btn-primary waves-effect waves-light"
                      >
                        검색
                      </button>
                    </div>
                  </div>

                </td>
              </tr>
            </table>

          </div>
        </div>

        <div class="d-flex" style="padding: 0 0 15px 0">
          <div style="flex-grow: 1">
            <button type="button"
                    v-if="user.userinfo.roleId!=1"
                    @click="load"
                    class="btn btn-primary waves-effect waves-light"
            >
              불러오기
            </button>
          </div>

          <button type="button"
                  style="margin-left: 10px"
                  class="btn btn-dark waves-effect waves-light"
                  @click="toggle_edit"
                  v-if="gridData!=null && gridData.length>0"
          >
            <span v-if="!edit">
              편집
              <i class="fas fa-edit"></i>
            </span>
            <span v-else>
              편집 완료
              <i class="fas fa-edit"></i>
            </span>
          </button>
          <button type="button"
                  style="margin-left: 10px"
                  class="btn btn-dark waves-effect waves-light"
                  @click="download"
          >
            다운로드
            <i class="fas fa-file-excel"></i>
          </button>
        </div>


        <div
            v-if="gridData!=null && gridData.length>0"
            class="card" style="padding: 20px">

          <div style="position: relative;width: 100%" class="table_wrapper table_wrapper2">
            <table class="mytable"
            >
              <thead class="table-light">
              <tr>
                <th v-for="(item,i2) in ['매체','상품','예산','예상 클릭율(%)'
            ,'예상 조회율(%)','예상 CPC(원)'
            ,'예상 CPM(원)','예상 CPV(원)'
            ]" :key="item" :style="getHeaderStyle(i2,item.length)">
                  {{item}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="2" style="background-color: black;color: white">
                  Total
                </td>
                <td colspan="77" style="background-color: black;color: white;text-align: right;padding-right: 50px">
                  <span v-if="total_budget != null && total_budget >= 0" v-html="total_budget.price('')"></span>
                </td>
              </tr>

              <template v-for="(item, rownum) in gridData">
                <tr :key="rownum" class="bg_mtable1">
                  <td style="min-width: 100px">
                    <my-select
                        @change="onchange_media"
                        v-model="item.mediaId"
                        :items="select_media"
                        label="매체"
                    />
                  </td>
                  <td style="min-width: 100px">
                    <my-select
                        v-model="item.adProductId"
                        :items="media_products[item.mediaId]"
                        label="상품"
                    />
                  </td>
                  <td>
                    <my-input label="예산"
                              number
                              mstyle="text-align:right"
                              v-model="item.totalBudget"
                              @change="chg_budget"/>
                  </td>

                  <td>
                    <div v-if="item.expectClicks2 != null && item.expectClicks2>0" style="text-align: right">
                      <span v-html="item.expectClicks3.price('')"></span>
                      <br/>
                      <span style="color: #ccc">( <span v-html="item.expectClicks.price('')"></span>)</span>
                    </div>
                    <div v-else style="text-align: right">
                      <span v-html="item.expectClicks.price('')"></span>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.expectView2 != null && item.expectView2>0" style="text-align: right">
                      <span v-html="item.expectView3.price('')"></span>
                      <br/>
                      <span style="color: #ccc">( <span v-html="item.expectView.price('')"></span>)</span>
                    </div>
                    <div v-else  style="text-align: right">
                      <span v-html="item.expectView.price('')"></span>
                    </div>
                  </td>

                  <td>
                    <div v-if="item.expectCpc2 != null && item.expectCpc2>0"  style="text-align: right">
                      <span v-html="item.expectCpc3.price('')"></span>
                      <br/>
                      <span style="color: #ccc">( <span v-html="item.expectCpc.price('')"></span>)</span>
                    </div>
                    <div v-else  style="text-align: right">
                      <span v-html="item.expectCpc.price('')"></span>
                    </div>
                  </td>
                  <td style="">
                    <div v-if="item.expectCpm2 != null && item.expectCpm2>0"  style="text-align: right">
                      <span v-html="item.expectCpm3.price('')"></span>
                      <br/>
                      <span style="color: #ccc">( <span v-html="item.expectCpm.price('')"></span>)</span>
                    </div>
                    <div v-else  style="text-align: right">
                      <span v-html="item.expectCpm.price('')"></span>
                    </div>
                  </td>
                  <td style="position: relative;padding-right: 50px;min-width: 160px">
                    <div v-if="item.expectCpv2 != null && item.expectCpv2>0"  style="text-align: right">
                      <span v-html="item.expectCpv3.price('')"></span>
                      <br/>
                      <span style="color: #ccc">( <span v-html="item.expectCpv.price('')"></span>)</span>
                    </div>
                    <div v-else  style="text-align: right">
                      <span v-html="item.expectCpv.price('')"></span>
                    </div>

                    <div style="position: absolute;top:3px;right: 8px;font-size: 16px;"
                         xxv-if="rolePriority!=1"
                    >
                      <i class="fas fa-minus-circle" style="cursor: pointer" @click="remove(item)"></i>
                    </div>

                    <div  style="position: absolute;top:23px;right:8px;font-size: 16px;"
                          xxv-if="rownum == gridData.length-1 && rolePriority!=1"
                          v-if="rownum == gridData.length-1"
                    >
                      <i class="fas fa-plus-circle" style="cursor: pointer" @click="add()"></i>
                    </div>

                  </td>
                </tr>
                <tr class="bg_mtable2"
                    v-if="edit"
                >
                  <td colspan="2" align="center">
                    Weight
                  </td>
                  <td></td>
                  <td>
                    <div style="display: flex">
                      <div style="margin: auto;">x</div>
                      <div style="flex: 1;padding-left: 3px;">
                        <my-input label="가중치"
                                  number
                                  v-model="item.expectClicks2"
                                  @change="chg_weight"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex">
                      <div style="margin: auto;">x</div>
                      <div style="flex: 1;padding-left: 3px;">
                        <my-input label="가중치"
                                  number
                                  v-model="item.expectView2"
                                  @change="chg_weight"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex">
                      <div style="margin: auto;">x</div>
                      <div style="flex: 1;padding-left: 3px;">
                        <my-input label="가중치"
                                  number
                                  v-model="item.expectCpc2"
                                  @change="chg_weight"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex">
                      <div style="margin: auto;">x</div>
                      <div style="flex: 1;padding-left: 3px;">
                        <my-input label="가중치"
                                  number
                                  v-model="item.expectCpm2"
                                  @change="chg_weight"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex">
                      <div style="margin: auto;">x</div>
                      <div style="flex: 1;padding-left: 3px;">
                        <my-input label="가중치"
                                  number
                                  v-model="item.expectCpv2"
                                  @change="chg_weight"/>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>


              </tbody>
            </table>
          </div>

          <div style="padding: 20px;display: flex;justify-content: center;"
               v-if="rolePriority!=1">
            <button type="button"
                    class="btn btn-primary waves-effect waves-light"
                    @click="save"
            >
              저장
            </button>
            <button type="button"
                    style="margin-left: 10px"
                    class="btn btn-primary waves-effect waves-light"
                    @click="add_campaign"
            >
              캠페인 등록
            </button>
          </div>
        </div>


      </div>
      <load-media-mix ref="mix"
                      @selected="selected"
      />
      <save-media-mix ref="save"
                      @saved="saved"
      />
      <register ref="register"/>
    </div>
</template>
<script>
import MyRangeEdit from "@/common/MyRangeEdit";
import register from "@/pages/setting/presentDB/register";
import saveMediaMix from "@/pages/forecast/saveMediaMix";
import MyInput from "@/components/common/MyInput";
import MySelect from "@/components/common/MySelect";
import loadMediaMix from "@/pages/forecast/loadMediaMix";
import moment from "moment";
import {mapGetters} from "vuex";
import Gnb from "@/nav/gnb";
import MyTable from "@/components/common/MyTable";


export default {
  computed: {
    ...mapGetters({
      user:'user',
      rolePriority:'rolePriority',
      media_products_info:'media_products_info'
    }),
  },
  mounted() {

    this.chk_media_products()
    //this.loadMedia()
  },
  components: {
    MyRangeEdit,
    register,
    saveMediaMix,
    MyInput,
    MySelect,
    Gnb,
    loadMediaMix
  },
  props: {},
  watch:{
    media_products_info:function () {
      this.chk_media_products()
    }
  },
  methods:{
    chk_media_products:function () {
      if(!this.media_products_info) {
        this.$store.commit('loading',  true)
      } else {
        this.select_media = this.media_products_info.select_media
        this.media_products = this.media_products_info.media_products
        this.media_list = this.media_products_info.media_list

        this.$store.commit('loading', false)
      }
    },
    getHeaderStyle:function (i2, len) {

      if(i2 == len-1) {
        return 'min-width: 100px;text-align:center;padding-right:50px'
      }
      //i2>=0?'min-width: 100px;text-align:center':'min-width: 100px'

      if(i2>=0)
        return 'min-width: 100px;text-align:center'
      else
        return 'min-width: 100px'

    },
    toggle_edit:function () {
      this.edit = !this.edit
    },
    download:function () {

      var data = JSON.parse(JSON.stringify(this.gridData))
      var o = {}
      o['mediaTargetList'] = data

      this.$http({
        method: 'POST',
        url: '/api/media/target/download',
        //params:o,
        data:o,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'forecast.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    add_campaign:function () {
      this.tmp_save()
      //this.$refs.register.open_mix(40)
    },
    save:function () {
      if(this.gridData == null || this.gridData.length == 0)
        return

      this.$refs.save.open()
    },
    tmp_save:function () {
      //console.log(name)
      {

        var startDate = moment(this.range.startDate).format('YYYY-MM-DD')
        var endDate = moment(this.range.endDate).format('YYYY-MM-DD')

        var data = JSON.parse(JSON.stringify(this.gridData))
        var o = {}
        o['mediaMixName'] = '자동저장_'+new Date().getTime()
        o['startDate'] = startDate
        o['endDate'] = endDate
        o['mediaTargetList'] = data

        this.$http.post('/api/media-mix/add', o)
            .then( ({data}) => {
              this.$eventBus.$emit('info', '저장이 완료되었습니다.')
              //console.log(data)

              var tmpid = data
              this.$refs.register.open_mix(tmpid)
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })
      }

    },
    saved:function (name) {
      //console.log(name)
      {

        var startDate = moment(this.range.startDate).format('YYYY-MM-DD')
        var endDate = moment(this.range.endDate).format('YYYY-MM-DD')

        var data = JSON.parse(JSON.stringify(this.gridData))
        var o = {}
        o['mediaMixName'] = name
        o['startDate'] = startDate
        o['endDate'] = endDate
        o['mediaTargetList'] = data

        this.$http.post('/api/media-mix/add', o)
            .then( ({data}) => {
              this.$eventBus.$emit('info', '저장이 완료되었습니다.')
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })
      }

    },
    add:function () {

      var o = {
        adProductId: 1,
        expectClicks: 0,
          expectCpc: 0,
          expectCpm: 0,
          expectCpv: 0,
          expectView: 0,
          mediaId: 0,
          mediaTargetId: 0,
          targetClicks: 0,
          targetCpc: 0,
          targetCpi: 0,
          targetCpm: 0,
          targetCprp: 0,
          targetCpv: 0,
          targetGrps: 0,
          targetImps: 0,
          targetInstall: 0,
          targetView: 0,
          totalBudget: 0
      }

      this.gridData.push(o)
    },
    search:function () {

      if(this.gridData == null || this.gridData.length==0) {
        this.add()
        return
      }

      var param = ''
      param += '&startDate='+moment(this.range.startDate).format('YYYY-MM-DD')
      param += '&endDate='+moment(this.range.endDate).format('YYYY-MM-DD')

      //http://localhost:8091/api/media-mix/values?startDate=2000-01-02&endDate=2022-02-20&mediaId=20&adProductId=1&budget=500000
      var url = '/api/media-mix/values?'
      url += param

      this.gridData.forEach(el=>{

        try {
          var url2 = url
          url2 += '&mediaId='+el.mediaId
          url2 += '&adProductId='+el.adProductId
          url2 += '&budget='+el.totalBudget

          this.$http.get(url2)
              .then(({ data }) => {

                if(!data) {
                  this.$eventBus.$emit('error', '검색된 데이터가 없습니다.')
                  return
                }

                //console.log(data)
                // 현재 아이템 교체
                for(var i=0;i<this.gridData.length;i++) {
                  var el2 = this.gridData[i]

                  if(el==el2) {

                    this.$set(this.gridData, i, data)
                    break
                  }
                }
              })
        } catch (e) {
        }
      })
    },
    remove:function (item) {
      for(var i=0;i<this.gridData.length;i++) {
        var el = this.gridData[i]
        if(el==item) {
          this.gridData.splice(i,1)
          break
        }
      }

    },
    chg_weight:function () {
      this.gridData.forEach(el=>{
        try {
          el.expectClicks3 = el.expectClicks2 * el.expectClicks
        } catch (e) {
        }
        try {
          el.expectView3 = el.expectView2 * el.expectView
        } catch (e) {
        }
        try {
          el.expectCpc3 = el.expectCpc2 * el.expectCpc
        } catch (e) {
        }
        try {
          el.expectCpm3 = el.expectCpm2 * el.expectCpm
        } catch (e) {
        }
        try {
          el.expectCpv3 = el.expectCpv2 * el.expectCpv
        } catch (e) {
        }
      })
    },
    chg_budget:function () {
      var v = 0
      this.gridData.forEach(el=>{
        try {
          el.totalBudget = parseInt(el.totalBudget)
          if(el.totalBudget < 0)
            el.totalBudget = 0
        } catch (e) {
          el.totalBudget = 0
        }

        v += el.totalBudget
      })

      this.total_budget = v
    },
    onchange_media:function (item) {
      //console.log(item)
    },
    selected:function (list) {

      this.gridData = list
      this.flag++
    },
    load:function () {
      this.$refs.mix.open()
    }
  },
  data() {
    return {
      edit:false,
      ready:false,

      select_media:null,
      media_products:{},
      media_list:null,
      api_media: [],
      range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },

      total_budget:0,
      flag:0,
      //
      gridData:[
        //{mediaId:1,adProductId:null},
        //{mediaId:1,adProductId:null},
        //{mediaId:1,adProductId:null}
      ],
      columnOptions : {
        resizable: true
        //frozenCount: 2,
        //frozenBorderWidth: 1,
      },
      rowHeaders : [
      ],
    }
  }
}
</script>
<style>
.vc-popover-content-wrapper {
  z-index: 1000 !important;
}
.bg_mtable1 td {
  background-color: white;
}
.bg_mtable2 td {
  background-color: #b9e6fc;
}
.vue-daterange-picker {
  width: 100%;
}
.mytable {
  width: 100%;
}
.mytable td, .mytable th {
  padding: 10px;
}
.table_wrapper2{
  background-color: white;
  white-space: nowrap;
}

</style>
<template>
  <div
      v-if="dlg_open && media_products"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 85% !important;max-width: 85% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              캠페인 등록
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div
                style="max-height: 70vh;overflow-y: scroll;padding:8px"
            >

              <div class="card border border-primary" style="overflow: visible;background-color: aliceblue">
                <div class="card-header bg-transparent border-primary">
                  <h4 style="padding-top: 10px;padding-bottom: 20px;font-weight: bold">Step1. 기본 정보</h4>
                  <table class="table" style="width: 100%">
                    <tbody>
                    <tr>
                      <th scope="row">광고주</th>
                      <td colspan="3">
                        <div class="d-flex">

                          <select class="form-select" aria-label="광고주"
                                  v-model="params.advertiserId"
                                  :disabled="params.campaignId?true:false"
                          >
                            <option :value="null">광고주 선택</option>
                            <option
                                v-for="item in list_advertisers"
                                :key="item.advertiserId"
                                :value="item.advertiserId">{{ item.advertiserName }}</option>
                          </select>

                          <div style="min-width: 100px;max-width:100px;margin-left: 5px;" class="d-grid"
                               v-if="!params.campaignId"
                          >
                            <button type="button" class="btn btn-secondary waves-effect waves-light"
                                    @click="add_advertiser"
                            >
                              신규등록
                            </button>
                          </div>

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style="">집행일자</th>
                      <td colspan="3">
                        <my-range-edit style="width: 100%" v-model="range"/>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" >캠페인명</th>
                      <td class="d-flex">
                        <input class="form-control" type="text" placeholder="캠페인명"
                               v-model="params.campaignName"
                               :disabled="params.campaignId"
                        >
                        <div v-if="!params.campaignId" style="min-width: 100px;max-width:100px;margin-left: 5px;" class="d-grid">
                          <button type="button" class="btn btn-secondary waves-effect waves-light"
                                  @click="checkDuplicate"
                          >
                            중복확인
                          </button>
                        </div>
                      </td>
                      <th scope="row"  style="padding-left: 20px">총 예산(원)</th>
                      <td>
                        <my-input number
                                  label="총 예산"
                                  v-model="params.totalBudget"
                        />
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card border border-primary" style="overflow: visible;background-color: aliceblue">
                <div class="card-header bg-transparent border-primary">

                  <div class="d-flex">
                    <div style="flex: 1">
                      <h4 style="padding-top: 10px;padding-bottom: 20px;font-weight: bold">Step2. API 연동 매체 설정</h4>
                    </div>
                    <div style="min-width: 140px;display: flex;">
                      <div style="width: 100%;padding:12px;text-align: right;align-self: center;">
                        <button type="button" class="btn btn-secondary waves-effect waves-light"
                                @click="report_open"
                        >
                          + 매체 추가
                        </button>
                      </div>
                    </div>
                  </div>

                  <my-table
                      :head="['매체','리포트 이름','리포팅 수준','캠페인 기간', '선택']"
                      :tableData="report_items"
                      :format="['displayName','apiName','_slot_report_type','_slot_date','_slot_select']"
                  >
                    <template slot="_slot_select" slot-scope="slotProps">
                      <button type="button" class="btn btn-danger waves-effect waves-light"
                              @click="del(slotProps.item)"
                      >
                        삭제
                      </button>
                    </template>
                    <template slot="_slot_report_type" slot-scope="slotProps">
                      <span v-html="report_type(slotProps.item)"/>
                    </template>
                    <template slot="_slot_date" slot-scope="slotProps">
                      {{slotProps.item.startDate}}
                      ~
                      {{slotProps.item.endDate}}
                    </template>
                  </my-table>


                </div>
              </div>

              <div class="card border border-primary" style="overflow: visible;background-color: aliceblue">
                <div class="card-header bg-transparent border-primary">

                  <div class="d-flex">
                    <div style="flex: 1">
                      <h4  style="padding-top: 10px;padding-bottom: 20px;font-weight: bold">Step3. 목표 설정</h4>
                    </div>
                    <div style="flex-grow: 1"></div>
                    <div style="min-width: 130px;;place-self: center">
                      <my-select
                          v-if="type_list"
                          v-model="params.mediaTypeId"
                          :items="type_list"
                          label="유형"
                          placeholder="유형"
                      ></my-select>
                    </div>
                    <div style="place-self: center;padding-left: 15px"
                         v-if="params.mediaTypeId=='digital'"
                    >
                      <button type="button" class="btn btn-outline-secondary waves-effect waves-light"
                              @click="$refs.load_media_mix.open()"
                      >
                        믹스안 불러오기
                      </button>
                    </div>
                  </div>

                  <div class="d-flex">

                    <div style="flex-grow: 1">

                    </div>
                    <div>
                      <button type="button" class="btn btn-secondary waves-effect waves-light"
                              @click="add_block"
                      >
                        + 미디어 카테고리 추가
                      </button>
                    </div>

                  </div>

                  <div>

                    <div v-for="line in target_blocks" style="display: flex;padding: 20px 0px 20px 0;position: relative">

                      <div style="min-width: 130px;padding-right: 12px">
                        <my-select
                            v-model="line['mediaTypeId']"
                            :items="type_list"
                            label="유형"
                            placeholder="유형"
                            :disabled="true"
                        ></my-select>

                      </div>

                      <div style="top: 10px;left: -10px;position: absolute;font-size: 13px;" @click="add_new_line(line)">
                        <i class="fas fa-plus-circle" style="cursor: pointer"></i>
                      </div>


                      <my-table
                          wrapper-style="padding: 0;position:relative;"
                          boxClass="card noshadow"
                          tbhead_class="tbhead"
                          layerStyle="padding:0"
                          :padding="0"
                          :paddingLeft="20"
                          :head="getDataHead(line.cols)"
                          :tableData="line.lines"
                          :format="getDataFormat(line.cols)"
                      >
                        <template slot="_slot_custom" slot-scope="slotProps">

                          <div v-if="slotProps.col==0" style="position: relative">
                            <div style="position: absolute;top:5px;left: -10px;font-size: 13px">
                              <i class="fas fa-minus-circle" style="cursor: pointer" @click="del_line(slotProps.item, line)"></i>
                            </div>
                          </div>


                          <div v-if="line.cols[slotProps.col].displayKey=='startDate' || line.cols[slotProps.col].displayKey=='endDate' ">
                            <my-date-edit v-model="slotProps.item[slotProps.col].value"
                                          style="width: 120px"
                            />
                          </div>
                          <div v-else-if="line.cols[slotProps.col].displayKey=='mediaId'">
                            <select class="form-select" aria-label="매체"
                                    style="margin-left:8px;width: 120px"
                                    v-model="slotProps.item[slotProps.col].value"
                            >
                              <option
                                  v-for="xselect in line.media_list"
                                  :key="xselect.mediaId"
                                  :value="xselect.mediaId">{{ xselect.channelNameKo }}</option>
                            </select>
                          </div>
                          <div v-else-if="line.cols[slotProps.col].displayKey=='adProductId'">
                            <select class="form-select" aria-label="상품"
                                    style="margin-left:8px;width: 120px"
                                    v-model="slotProps.item[slotProps.col].value"
                            >
                              <option
                                  v-for="xselect in media_products[slotProps.item[0].value]"
                                  :key="xselect.value"
                                  :value="xselect.value">{{ xselect.text }}</option>
                            </select>

                          </div>
                          <div v-else>

                            <my-input number
                                      label=""
                                      v-model="slotProps.item[slotProps.col].value"
                            />

                          </div>
                        </template>
                      </my-table>

                      <div style=";margin-left:5px;width: 80px;    text-align: right;">
                        <button type="button" class="btn btn-outline-secondary waves-effect waves-light"
                                @click="del_block(line)"
                        >
                          <i class="fas fa-trash-alt" style="cursor: pointer"></i>
                        </button>
                      </div>
                    </div>

                  </div>


                </div>
              </div>

            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              등록 및 수정
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

    <load-api-report ref="load_report" @selected="selected_report"/>
    <load-media-mix ref="load_media_mix" @selected="selected_mediamix"/>
  </div>
</template>

<script>
import MyInput from "@/components/common/MyInput";
import MySelect from "@/components/common/MySelect";
import MyDateEdit from "@/common/MyDateEdit";
import MyRangeEdit from "@/common/MyRangeEdit";
import loadApiReport from "@/pages/setting/presentDB/loadApiReport";
import loadMediaMix from "@/pages/forecast/loadMediaMix";
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
    MyInput,
    MySelect,
    MyDateEdit,
    MyRangeEdit,
    loadApiReport,
    MyTable,
    loadMediaMix
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({
      first_media_type:'first_media_type',
      main_media_type:'main_media_type',
      dashboard:'dashboard',
      media_products_info:'media_products_info'
    }),
  },
  mounted() {

    this.chk_media_products()

    //console.log(this.media_products_info)
    this.media_target_list = this.first_media_type.mediaTarget

    this.type_list = []

    this.main_media_type.forEach(el=> {

      if (this.dashboard) {
        if (el.toUpperCase() == 'DIGITAL') {
          this.type_list.push({
            text: el.toUpperCase(),
            value: el
          })
        }
      } else {
        this.type_list.push({
          text:el.toUpperCase(),
          value:el
        })
      }

    })

    this.main_media_type.forEach(k=>{
      var url = '/api/media/listType?mediaTypeTemplateId='
      var id = this.media_map[k.toLowerCase()]
      var url2 = url + id

      this.$http.get(url2)
          .then(({ data }) => {
            this.media_list_map[k] = data
          }).catch(error => {
            this.$eventBus.$emit('error', error.errorMessage)
      })
    })

    this.loadFilter()
  },
  data () {
    return {
      // media_list
      type_list : null,
      media_map : {
        tv:1,
        ooh:2,
        digital:3,
        radio:4,
        ppl:5
      },
      media_list_map:{},
      target_blocks:[],
      media_target_list:null,
      report_items:[],
      list_advertisers:null,
      api_media:[],
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        campaignId:null,
        mediaTypeId:null,
        advertiserId:'',
        campaignName:'',
      },


      select_media:null,
      media_products:{},
      media_list:null,
    }
  },
  methods:{
    chk_media_products:function () {
      if(!this.media_products_info) {
        this.$store.commit('loading',  true)
      } else {
        this.select_media = this.media_products_info.select_media
        this.media_products = this.media_products_info.media_products
        this.media_list = this.media_products_info.media_list

        this.$store.commit('loading', false)
      }
    },
    getDataHead:function (items) {
      var a = []
      items.forEach(el=>{

        if(el.displayKey == 'startDate' || el.displayKey == 'endDate' || el.displayKey == 'mediaId') {
          var name = el.displayName+'::@W_CENTER::130'
          a.push(name)
        } else
          a.push(el.displayName)
      })
      return a
    },
    getDataFormat:function (items) {
      var a = []
      items.forEach(el=>{
        a.push('_slot_custom')
      })
      return a
    },
    ymd:function (item) {
      return moment(item).format('YYYY-MM-DD')
    },
    del_line:function (items, line) {

      var u = line.lines
      for (var i = 0; i < u.length; i++) {
        var el = u[i]
        if (el == items) {
          u.splice(i, 1)
          break
        }
      }

    },
    parse_target_line:function(lines) {

      var blocks = []
      if(lines == null || lines.length==0)
        return []

      var last_block_id = lines[0].mediaTypeTemplateId

      var media_keys = Object.keys(this.media_map)

      //media_list

      var that = this
      var get_new_block=function (id) {

        var key_name = null
        media_keys.forEach(k=>{
          if(that.media_map[k] == id) {
            key_name = k
          }
        })

        var last_block = {}
        var list = that.media_target_list[key_name]

        last_block['cols'] = JSON.parse(JSON.stringify(list))
        last_block['lines'] = []
        last_block['media_list'] = that.media_list_map[key_name]

        return last_block
      }

      var last_block = get_new_block(last_block_id)
      blocks.push(last_block)

      lines.forEach(el=>{
        var id = el.mediaTypeTemplateId

        if(id != last_block_id) {

          // 새로 생성
          last_block = get_new_block(id)
          blocks.push(last_block)
          last_block_id = id
        }

        // key => value 컨버팅
        var values = []
        last_block.cols.forEach(col=>{
          var k = col.displayKey

          if(k == 'startDate' || k == 'endDate') {

            try {
              var o = moment(el[k]).toDate()
              if(o=='Invalid Date') {
                values.push({value:{
                    startDate: new Date(),
                    endDate: new Date(),
                  }})
              } else {
                values.push({value:{
                    startDate: o,
                    endDate: o
                  }})
              }
            } catch (e) {
            }
          } else
            values.push({value:el[k]})
        })

        last_block.lines.push(values)
      })

      return blocks
    },
    del_block:function (item) {
      for(var i=0;i<this.target_blocks.length;i++) {
        var el = this.target_blocks[i]
        if(el==item) {
          this.target_blocks.splice(i,1)
          break
        }
      }

    },
    add_block:function () {

      var id = this.params.mediaTypeId
      var list = this.media_target_list[id]
      if(!list) {
        this.$eventBus.$emit('error', '유형을 선택해주세요.')
        return
      }

      var block = {}
      block['mediaTypeId'] = id
      block['cols'] = JSON.parse(JSON.stringify(list))
      block['lines'] = []
      block['media_list'] = this.media_list_map[id]
      this.add_new_line(block)

      this.target_blocks.push(block)
    },
    add_new_line:function (block) {
      var col = block['cols']
      var a = block['lines']

      var o = []
      col.forEach(el=>{
        if(el.displayKey=='startDate'|| el.displayKey=='endDate') {
          o.push({value:new Date()})
        }
        else
          o.push({value:''})
      })
      a.push(o)
    },
    selected_mediamix:function (item) {

      // digital 설정
      item.forEach(el=>{
        el.mediaTypeTemplateId = 3
      })

      var a = this.parse_target_line(item)
      //console.log(a)
      //mediaTypeId
      if(a.length > 0) {
        a[0].mediaTypeId = "digital"
        this.target_blocks.push(a[0])
      }
    },
    del:function (item) {
      for(var i=0;i<this.report_items.length;i++) {
        var el = this.report_items[i]
        if(el==item) {
          this.report_items.splice(i,1)
          break
        }
      }
    },
    report_type:function (item) {

      var m = ''
      this.first_media_type.adReportType.forEach(el=>{
        if(item.adReportTypeTemplateId == el.templateId) {
          m = el.displayName
          return
        }
      })

      return m
    },
    selected_report:function (item) {

      var ex = false
      this.report_items.forEach(el=>{
        if(el.apiId == item.apiId) {
          ex = true
          return
        }
      })
      if(!ex)
        this.report_items.push(item)
    },
    open_mix:function (id) {
      this.open()

      var url = '/api/media-mix/detail?mediaMixId='+id
      this.$http.get(url)
          .then(({ data }) => {

            var res = data.mediaTargetList
            this.selected_mediamix(res)
          })
          .catch(error => {
            console.log(error)
          })
    },
    report_open:function () {

      if(!this.params.advertiserId) {
        this.$eventBus.$emit('error', '광고주를 선택해주세요.')
        return
      }

      this.$refs.load_report.open(this.params.advertiserId, this.params.advertiserName)
    },
    checkDuplicate:function () {

      var url = '/api/campaign/validate?campaignName='+this.params.campaignName
      this.$http.get(url)
          .then(({ data }) => {
            this.$eventBus.$emit('info', '사용가능합니다.')
          }).catch(error => {
        this.$eventBus.$emit('error', error.errorMessage)
      })
    },
    searchApiName:function () {

    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {
            this.list_advertisers = data
          })
    },
    add_advertiser:function () {
      this.$router.push({path:'/setting/system/advertiser'})
    },
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

      // load
      this.init()
    },
    init:function () {
    },
    open_mod:function (item) {
      var id = item.campaignId
      this.reset()

      this.params.campaignId = id
      var url = '/api/campaign/detail?campaignId='+id
      this.$http.get(url)
          .then(({ data }) => {
            this.params = data

            //params.advertiserId
            //this.params.advertiserId = null

            this.dlg_open = true

            this.range = {
              startDate:moment(this.params.startDate),
              endDate:moment(this.params.endDate)
            }

            try {
              var a = data.mediaTargetList
              a = this.parse_target_line(a)
              this.target_blocks = a

            } catch (e) {
              console.log(e)
            }

            this.report_items = data.apiList

            //console.log(this.params)

            //console.log(this.params)
          }).catch(error => {
            this.$eventBus.$emit('error', error.errorMessage)
      })
    },
    open:function () {
      this.reset()
      this.dlg_open = true
      this.init()
    },
    onSubmit() {

      //console.log(this.params)

      this.params['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
      this.params['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')

      //report_items

      this.params['apiList'] = []
      var a = this.params['apiList']
      this.report_items.forEach(el=>{
        a.push({
          apiId:el.apiId
        })
      })

      //this.params['mediaTargetList']

      var arr = []
      // media_list 관리
      this.target_blocks.forEach(el=>{

        var col = el.cols
        var lines = el.lines
        lines.forEach(line=>{

          var o = {}
          for(var i in line) {

            var line_value = line[i]
            var k = col[i].displayKey
            if(k=='startDate' || k=='endDate') {
              o[k] = moment(line_value.value.startDate).format('YYYY-MM-DD')
            } else {
              o[k] = line_value.value
            }
          }
          arr.push(o)
        })
      })
      this.params['mediaTargetList'] = arr

      //Post http://localhost:8091/api/campaign/add
      if(this.params.campaignId) {
        this.$http.put('/api/campaign/modify', this.params)
            .then( ({data}) => {
              this.$eventBus.$emit('info', '등록완료 되었습니다.')
              this.$emit('updated')
              this.dlg_open = false
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })

      } else {
        this.$http.post('/api/campaign/add', this.params)
            .then( ({data}) => {
              this.$eventBus.$emit('info', '등록완료 되었습니다.')
              this.$emit('updated')
              this.dlg_open = false
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })
      }
    },
    reset() {
      this.report_items = []
      this.target_blocks = []
      this.params = {
        campaignId:null,
        mediaTypeId:null,
        advertiserId:null,
        campaignName:'',
      }
    }
  },
  watch: {
    media_products_info:function () {
      this.chk_media_products()
    },
    'params.advertiserId': function () {
      var l = this.list_advertisers
      l.forEach(el => {
        if(this.params.advertiserId == el.advertiserId) {
          this.params.advertiserName = el.advertiserName
          return
        }
      })
    }
  }
}
</script>
<style>
.tbhead {
  background-color: #D5DDE4;
}
.tbhead > tr > th {
  color: #66696EFF !important;
  font-size: 13px !important;
}
.noshadow {
  box-shadow: none !important;
}
</style>
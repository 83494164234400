<template>
  <div>
    <gnb class="mbg">
      <template  slot="desc">
        신규 거래처(광고주)를 등록합니다.

        <div class="pt-4">
          <button type="button" class="btn btn-primary waves-effect waves-light"
                  @click="$refs.modal.open()"
          >
            거래처 등록
          </button>
        </div>
      </template>
    </gnb>

    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary">
          <div class="d-flex" >

            <my-search-box
                style="flex:1"
                v-model="query"
                @focus="onfocus"
                :options="options"
                @selected="search_selected"
                @onsearch2="onsearch2"
                hint="광고주명을 입력해주세요"
                @keydown.enter="search"
            />
            <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
              <button
                  @click="search"
                  type="button" class="btn btn-secondary waves-effect waves-light">
                <i class='fal fa-search' style="margin-right: 3px"></i>
                검색
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">

        <my-table
            @onpage="onpage"
            @rowClick="rowClick"
            :clickable="true"
            :head="['광고주명','사업자등록번호','업태/종목']"
            :tableData="tableData"
            :format="['advertiserName','advertiserNumber','businessName']"
            :pageParam="pageParam"
        />

      </div>
    </div>

    <register-modal ref="modal" @updated="load"/>
  </div>
</template>

<script>
import MySearchBox from "@/components/common/MySearchBox";
import MyTable from "@/components/common/MyTable";
import registerModal from './register'

import {
  GET_ADVERTISER_LIST,
  TABLE_FIELDS
} from './constants.js'
import Gnb from "@/nav/gnb";
export default {
  components: {
    MySearchBox,
    Gnb,
    MyTable,
    registerModal
  },
  data() {
    return {
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      tableData:null,
      pageParam:null,
      page:0,
      query:null,
      apiUrl: GET_ADVERTISER_LIST,
      fields: TABLE_FIELDS,
      advertiserId: null
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    onfocus:function () {
      this.onsearch()
    },
    search_selected:function (v) {
      this.query = v
      this.search()
    },
    onsearch2:function (k, resolve) {
      this.query = k

      var url = this.apiUrl+'?page='+this.page
      if(this.query) {
        url += '&advertiserName='+encodeURIComponent(this.query)
      }

      this.$http.get(url)
          .then(({ data }) => {

            var arr = data.content

            var a = []
            arr.forEach(el=>{
              a.push(el.advertiserName)
            })
            resolve(a)
          }).catch(err=>{
      })
    },
    rowClick:function (item) {
      this.$refs.modal.load(item.advertiserId)
    },
    search:function () {
      this.page=0
      this.load()
    },
    onpage:function (p) {
      this.page = p
      this.load()
    },
    load:function () {
      var url = this.apiUrl+'?page='+this.page
      if(this.query) {
        url += '&advertiserName='+encodeURIComponent(this.query)
      }
      this.$http.get(url, this.params)
          .then(({ data }) => {

            this.tableData = data.content
            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages

          })
          .catch(error => {
          })
    },
  }
}
</script>
<template>

  <div>

    <gnb class="mbg">
      <template  slot="desc">
        캠페인을 등록하고 관리합니다.
        <div class="pt-4">
          <button type="button" class="btn btn-primary waves-effect waves-light"
                  @click="$refs.register.open()"
          >
            캠페인 등록
          </button>
        </div>
      </template>
    </gnb>


    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary" style="padding: 10px">

          <div class="d-flex" >
            <div
                style="min-width: 150px;margin-right: 5px;"
            >
              <select class="form-select" aria-label="광고주 선택"
                      v-model="advertiserId"
              >
                <option :value="null" selected>광고주 선택</option>
                <option
                    v-for="item in list_advertisers"
                    :key="item.advertiserId"
                    :value="item.advertiserId">{{ item.advertiserName }}</option>
              </select>

            </div>

            <my-search-box
                style="flex:1"
                v-model="query"
                @focus="onfocus"
                :options="options"
                @selected="search_selected"
                @onsearch2="onsearch2"
                hint="검색어를 입력해주세요."
            />

            <input v-if="false" class="form-control" type="text"
                   v-model="query"
                   @keydown.enter="search"
                   placeholder="캠페인명을 입력해주세요.">


            <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
              <button type="button"
                      @click="search"
                      class="btn btn-secondary waves-effect waves-light">
                <i class='fal fa-search' style="margin-right: 3px"></i>
                검색
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="mt-4">

        <my-table
            @onpage="onpage"
            :head="['광고주명::@CENTER','캠페인명::@CENTER','등록일::@CENTER','등록 계정::@CENTER','상세보기::@CENTER','캠페인 현황 보기::@CENTER']"
            :tableData="tableData"
            :format="['_slot_advertiserName','_slot_campaignName','_slot_date','_slot_userId','_slot1','_slot_status']"
            :pageParam="pageParam"
        >
          <template slot="__slot_header">
            <div style="padding: 20px">

              <div style="margin-bottom: 10px">
                검색결과 : {{totalElements}}건
              </div>
              <h4>
                진행 중 캠페인 관리
              </h4>
            </div>
          </template>
          <template slot="_slot_userId" slot-scope="slotProps">
            <div  @click="rowClick(slotProps.item)" style="cursor: pointer">
              {{slotProps.item.userId}}
            </div>
          </template>

          <template slot="_slot_advertiserName" slot-scope="slotProps">
            <div  @click="rowClick(slotProps.item)"  style="cursor: pointer">
              {{slotProps.item.advertiserName}}
            </div>
          </template>
          <template slot="_slot_campaignName" slot-scope="slotProps">
            <div  @click="rowClick(slotProps.item)"  style="cursor: pointer">
              {{slotProps.item.campaignName}}
            </div>
          </template>
          <template slot="_slot_date" slot-scope="slotProps">
            <div  @click="rowClick(slotProps.item)"  style="cursor: pointer">
              {{slotProps.item.startDate}} ~ {{slotProps.item.endDate}}
            </div>
          </template>
          <template slot="_slot1" slot-scope="slotProps">
            <i class="fas fa-search align-self-center"
               style="cursor: pointer"
               @click.stop="clkView(slotProps.item)"></i>
          </template>
          <template slot="_slot_status" slot-scope="slotProps">
            <i class="fas fa-external-link-alt align-self-center"
               style="cursor: pointer"
               @click.stop="campaignStatus(slotProps.item)"></i>
          </template>
        </my-table>

        <register ref="register" @updated="updated"/>
        <detail ref="detail" @updated="updated" @request_mod="request_mod"/>

      </div>
    </div>

  </div>

</template>

<script>
import MySearchBox from "@/components/common/MySearchBox";
import axios from 'axios'

import {mapGetters} from "vuex";
import MyTable from "@/components/common/MyTable";
import detail from "@/pages/setting/presentDB/detail";
import register from "@/pages/setting/presentDB/register";
import moment from "moment";
import {GET_MEDIA_LIST} from "@/pages/setting/system/media/constants";
import Gnb from "@/nav/gnb";
export default {
  computed: {
    ...mapGetters({
      STATUS: 'STATUS',
      first_media_type: 'first_media_type',
    }),
  },
  components: {
    MySearchBox,
    Gnb,
    MyTable,
    register,
    detail
  },
  data() {
    return {
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      query:null,
      totalElements:0,
      tableData:null,
      list_advertisers:null,
      pageParam:null,
      page:0,
      params: {
        campaignName: ''
      },
      advertiserId: null
    }
  },
  mounted() {
    this.loadFilter()
    this.load()
  },
  methods: {
    onfocus:function () {
      this.onsearch()
    },

    onsearch:function (k, loading) {

      this.query = k

      if(loading)
        loading(true)

      var url = '/api/campaign/list'+'?'
      if(this.query) {
        url += '&campaignName='+encodeURIComponent(this.query)
      }
      if(this.advertiserId) {
        url += '&advertiserId='+this.advertiserId
      }


      this.$http.get(url)
          .then(({ data }) => {

            var arr = data.content

            var a = []
            arr.forEach(el=>{
              a.push(el.campaignName)
            })
            this.options = a

            if(loading)
              loading(false)
          }).catch(err=>{
        if(loading)
          loading(false)
      })

    },


    search_selected:function (v) {
      this.query = v
      this.search()
    },
    onsearch2:function (k, resolve) {
      this.query = k

      var url = '/api/campaign/list'+'?'
      if(this.query) {
        url += '&campaignName='+encodeURIComponent(this.query)
      }
      if(this.advertiserId) {
        url += '&advertiserId='+this.advertiserId
      }

      this.$http.get(url)
          .then(({ data }) => {

            var arr = data.content

            var a = []
            arr.forEach(el=>{
              a.push(el.campaignName)
            })

            resolve(a)
          }).catch(err=>{
      })
    },
    request_mod:function (item) {
      this.$refs.register.open_mod(item)
    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {
            this.list_advertisers = data
          })
    },
    updated:function () {
      this.load()
    },
    search:function () {
      this.load()
    },
    get_mediaTypeTemplateName:function (id) {

      for(var i in this.first_media_type.mediaType) {
        var el = this.first_media_type.mediaType[i]
        if(el.id == id) {
          return el.displayName
        }
      }
    },
    info_status:function (item) {
      //item.statusMessage
      this.$eventBus.$emit('dlg_alert','반려 사유',item.statusMessage)
    },
    getDate:function (item) {
      return moment(item.createdAt).format('YYYY-MM-DD')
    },
    campaignStatus:function (item) {
      //campaignStatus
      this.$router.push({path:'/present',query:{campaignId:item.campaignId,campaignName:item.campaignName}})
    },
    rowClick:function (item) {
      this.$refs.register.open_mod(item)
    },
    clkView:function (item) {
      this.$refs.detail.open(item.campaignId)
    },
    onpage:function (p) {
      this.page = p
      this.load()
    },
    load:function () {
      var url = '/api/campaign/list'+'?page='+this.page
      if(this.query) {
        url += '&campaignName='+encodeURIComponent(this.query)
      }
      if(this.advertiserId) {
        url += '&advertiserId='+this.advertiserId
      }

      this.$http.get(url)
          .then(({ data }) => {

            this.totalElements = data.totalElements
            this.tableData = data.content

            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages

          })
          .catch(error => {
          })
    },
  }
}

</script>
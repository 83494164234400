<template>
  <div
      v-if="items && items.length>0"
  >

    <div class="mb-2 d-flex"
    >
      <div style="flex-grow:1;padding: 0 12px;align-self: self-end;text-align: right">
        * 총 {{items.length}} 건
      </div>
    </div>

    <table class="table100 ver2" v-if="items && items.length>0"
    >
      <thead class="table-light">
      <tr
          style="background-color: rgb(214 215 223);"
      >
        <th
            style="color: black;font-size: 12px"
            scope="row">상품명</th>
        <th
            style="color: black;font-size: 12px"
            scope="row">디바이스 (Device)</th>
        <th
            style="color: black;font-size: 12px"
            scope="row">상품 유형 (Media Type)</th>
        <th
            style="color: black;font-size: 12px"
            scope="row">광고 형식 (Format)</th>
        <th
            style="color: black;font-size: 12px"
            scope="row">과금 방식 (Buying Type)</th>
        <th
            scope="row" style="color:black;font-size: 12px;min-width: 80px;text-align: center">기능</th>
      </tr>
      </thead>
      <tbody>
      <tr
          style="background-color: white;border-bottom: 0.5px solid;border-color: rgb(214 215 223)"
          v-for="(item,i) in items"
          :key="i"
      >
        <td>
          {{item.placement}}
        </td>
        <td>
          <span v-text="getDeviceName(item)"/>
        </td>
        <td>
          <span v-text="getAdProductMediaName(item)"/>
        </td>
        <td>
          <span v-text="getAdFormatName(item)"/>
        </td>
        <td>
          <span v-text="getBuyingTypeName(item)"/>
        </td>
        <td style="text-align: center">
          <button type="button" class="btn btn-danger waves-effect"
                  @click="del_item(item)"
          >
            삭제
          </button>
        </td>
      </tr>
      </tbody>
    </table>


  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters({
      first_media_type: 'first_media_type',
    }),
    device:function () {
      return this.first_media_type.device[this.my_media_type]
    },
    adProductMediaType:function () {
      return this.first_media_type.adProductMediaType[this.my_media_type]
    },
    format:function () {
      return this.first_media_type.format[this.my_media_type]
    },
    buyingType:function () {
      return this.first_media_type.buyingType[this.my_media_type]
    },
  },
  components: {
  },
  props: {
    items2:{
      type:Array,
      default: null
    },
    my_media_type: {
      type:String,
      default: null
    },
    adProductList:{
      type:Array,
      default:null
    }
  },
  mounted() {
    if(this.adProductList)
      this.items = this.adProductList
  },
  data () {
    return {
      items:[],
    }
  },
  methods: {
    /*
          <select class="form-select" aria-label="광고 형식 (Format)"
                  v-model="item.formatTemplateId"
          >
            <option
                v-for="xitem in format"
                :key="xitem.templateId"
                :value="xitem.templateId">{{ xitem.displayName }}</option>
          </select>
        </td>
        <td>
          <span v-text="getBuyingTypeName(item)"/>
          <select class="form-select" aria-label="과금 방식 (Buying Type)"
                  v-model="item.buyingTypeTemplateId"
          >
            <option
                v-for="xitem in buyingType"
                :key="xitem.templateId"
                :value="xitem.templateId">{{ xitem.displayName }}</option>
          </select>
        </td>
     */

    getAdFormatName:function (v) {
      var id = v.formatTemplateId
      for(var i in this.format) {
        var el = this.format[i]
        if(el.templateId == id)
          return el.displayName
      }
    },
    getBuyingTypeName:function (v) {
      var id = v.buyingTypeTemplateId
      for(var i in this.buyingType) {
        var el = this.buyingType[i]
        if(el.templateId == id)
          return el.displayName
      }
    },
    getAdProductMediaName:function(v) {
      var id = v.adProductMediaTypeTemplateId
      for(var i in this.adProductMediaType) {
        var el = this.adProductMediaType[i]
        if(el.templateId == id)
          return el.displayName
      }
    },
    getDeviceName:function (v) {
      var id = v.deviceTemplateId
      for(var i in this.device) {
        var el = this.device[i]
        if(el.templateId == id)
          return el.displayName
      }
    },
    get:function () {
      return this.items
    },
    validate:function () {

      var error = {
        placement:false,
        deviceTemplateId:false,
        adProductMediaTypeTemplateId:false,
        formatTemplateId:false,
        buyingTypeTemplateId:false,
      }

      for(var i in this.items) {
        var item = this.items[i]
        if(item) {

          if(!item.placement) {
            error.placement = true
          }
          if(!item.deviceTemplateId) {
            error.deviceTemplateId = true
          }
          if(!item.adProductMediaTypeTemplateId) {
            error.adProductMediaTypeTemplateId = true
          }
          if(!item.formatTemplateId) {
            error.formatTemplateId = true
          }
          if(!item.buyingTypeTemplateId) {
            error.buyingTypeTemplateId = true
          }
        }
      }

      var msg = ''
      if(error.placement) {
        msg += '상품명'
      }

      /*
      if(error.deviceTemplateId) {
        if(msg!='')
          msg += ', '
        msg += '디바이스'
      }

      if(error.adProductMediaTypeTemplateId) {
        if(msg!='')
          msg += ', '
        msg += '상품유형'
      }

      if(error.formatTemplateId) {
        if(msg!='')
          msg += ', '
          msg += '광고형식'
      }

      if(error.buyingTypeTemplateId) {
        if(msg!='')
          msg += ', '
          msg += '과금방식'
      }
      */

      if(msg != '') {
        this.$eventBus.$emit('error', '추가한 상품의 ' + msg + ' 항목을 입력해주세요.')
        return false
      }

      return true
    },
    del_item:function (item) {

      for(var i=0;i<this.items.length;i++) {
        var el = this.items[i]
        if(el==item) {
          this.items.splice(i,1)
          break
        }
      }
    },
    add_item:function (item, no_validation) {

      if(no_validation) {

      } else {
        if(this.items.length>=4) {
          this.$eventBus.$emit('error', '5개 이상의 상품은 템플릿을 통해 등록해주세요.')
          return
        }
      }
      this.items.push(item)
    }
  },
  watch: {
  }
}
</script>

<template>
  <div>
    <div class="gnb">
      <div style="padding: 0 40px">
        <img :src="user.logo" style="height: 40px">
      </div>
      <div class="d-flex w-100">
        <div class="flex-grow-1"></div>
        <div class="user-box" style="padding: 0 20px">
          <div class="form-inline">

            <button
                v-if="user.userinfo.roleId!=1"
                style="margin-right: 15px"
                @click="$refs.inviteAccount.open()"
                type="button" class="btn btn-light waves-effect">
              <i class="fas fa-user-plus"></i> 초대
            </button>
            <button
                style="margin-right: 15px"
                @click="logout"
                type="button" class="btn btn-light waves-effect">
              로그아웃
            </button>

            <button
                @click="$refs.modifyAccount.open()"
                type="button" class="btn waves-effect white">
              <i style="font-size: 22px" class="fas fa-user-circle"></i>
            </button>

          </div>
        </div>
      </div>
    </div>
    <div class="nav_block" v-if="mynav && !hide_nav">
      <div class="txtnav">
        <span v-for="(item,idx) in mynav" :key="idx">
          <span v-if="idx+1!=mynav.length">
                      <router-link
                          style="color: #8d9bad"
                          :to="item.path"
                      >
                        {{item.name}}
                      </router-link>
           >
          </span>
          <span v-else class="txtnav_active">
            {{item.name}}
          </span>
        </span>
      </div>

      <div style="padding-top: 10px;color: white">
        <template>
          <slot name="desc" v-bind:item="item">
          </slot>
        </template>
      </div>
    </div>

    <modify-account-modal ref="modifyAccount" />
    <invite-account-modal ref="inviteAccount"/>
  </div>
</template>

<script>
import inviteAccountModal from '@/components/modal/inviteAccountModal'
import modifyAccountModal from '@/components/modal/modifyAccountModal'
import { MENU_LIST } from '@/constants'
import {mapGetters} from "vuex";


export default {
  props:{
    hide_nav:{
      default:false,
      type:Boolean
    }
  },
  computed: {
    ...mapGetters({
      mynav:'mynav',
      user:'user'
    }),
  },
  name: 'gnb',
  components: {
    modifyAccountModal,
    inviteAccountModal
  },
  data() {
    return {
      menuList: MENU_LIST,
      currentPath: '/intro'
    }
  },
  methods: {
    logout:function () {
      location.href='/api/user/logout'
    },
    onClickTab(path) {
      this.$router.push({path:path})
    }
  },
  mounted() {
    //console.log(this.user)
    this.currentPath = this.$route.meta.parent || this.$route.path

    //console.log('p : ' + this.currentPath)
  },
  watch: {
    '$route' (to) {
      this.currentPath = to.meta.parent || to.path

      //console.log('p2 : ' + this.currentPath)
    }
  }
}
</script>

<style scoped>
.logobox {
  min-width: 180px;
  max-width: 180px;
  text-align: center;
}
.user-box {
  min-width: 120px;
}
.gnb {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
}
.nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-wrap ul {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height:100%;
  padding: 0;
  margin: 0;
}
.nav-wrap ul li {
  display: inline;
  list-style: none;
  box-sizing: border-box;
}
.nav-wrap ul li:hover {
  color: #007bff;
  cursor: pointer;
}
.nav-active {
  font-size: 20px;
  color: #007bff;
  border-bottom: 3px solid #007bff;
}
.nav-item {
  padding: 15px 25px;
  font-size: 20px;
}
i.far {
  font-size: 1.4rem;
}

.nav_block {
  padding: 10px 0 30px 100px;
  color: #8d9bad;
}
.nav_block > .txtnav {
  font-size: 18px;
}
.txtnav_active {
  font-size: 18px;
  color: #fff;
}
</style>
<template>
<div>
  <div style="font-size: 14px;padding:4px;cursor: pointer" @click="open=true">
    <i class="fas fa-cog align-self-center"></i>
  </div>

  <div
      v-if="open"
      class="modal fade show"
      style="display: block;background-color:#00000055;"
  >

    <div class="modal-dialog"
         style=";min-width: 300px !important;"
    >
      <div class="modal-content"
      >
        <div class="modal-header">
          <h5 class="modal-title mt-0">
            데이터 칼럼 설정
          </h5>
          <button type="button" class="btn-close"
                  @click="open=false"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body" style="max-height: 70vh !important;overflow-y: auto">

          <draggable v-model="myvalue">
            <transition-group>
              <div v-for="element in myvalue" :key="element.id">
                <div style="display: flex;margin-bottom: 3px" class="myarea">
                  <div style="width: 30px">
                    <input class="form-check-input"
                           v-model="element.view"
                           :value="true" type="checkbox" name="option">
                  </div>
                  <div style="flex: 1">
                    {{element.name}}
                  </div>
                  <div style="width: 30px;text-align: right;cursor: pointer">
                    <i class="fas fa-sort"></i>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>

        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-light waves-effect"
                  @click="open=false"
          >
            취소
          </button>
          <button type="button"
                  @click="ok"
                  class="btn btn-primary waves-effect waves-light"
          >
            확인
          </button>

        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>
import MyInput from "@/components/common/MyInput";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
export default {
  props:{
    header: {
      default:null,
      type:Array
    },
    mykey:{
      default:'myset',
      type:String
    }
  },
  mounted() {
    this.rawdata = this.header
    this.load()
  },
  computed: {
  },
  components: {
  },
  watch: {
  },
  methods: {
    ok:function () {
      //console.log(this.myvalue)
      this.save()
      this.open = false
    },
    save:function () {
      var k = this.prefix + this.mykey

      var data = JSON.stringify(this.myvalue)
      localStorage.setItem(k, data)

      this.$eventBus.$emit('info', '설정이 저장되었습니다.')

      this.$emit('init', this.myvalue)
    },
    load:function () {
      var k = this.prefix + this.mykey
      var data = localStorage.getItem(k)
      var ja = JSON.parse(data)

      // 넘어온 raw data와 비교함
      if(ja) {
        //
        for(var i in this.rawdata) {
          var el = this.rawdata[i]
          var found = false
          for(var i2 in ja) {
            var el2 = ja[i2]
            if(el.id == el2.id) {
              found = true
              break
            }
          }
          //
          if(!found) {
            ja.push(el)
          }
        }

        // myvalue, rawdata
        // myvalue 에서 현재 rawdata 에 존재하지 않는 애들을 삭제한다.
        for(var i=ja.length-1;i>=0;i--) {
          var el = ja[i]

          var found = false
          for(var i2 in this.rawdata) {
            var el2 = this.rawdata[i2]
            if(el.id == el2.id) {
              found = true
              break
            }
          }
          if(!found) {
            ja.splice(i,1)
          }
        }

        this.myvalue = ja
      } else {
        this.myvalue = this.rawdata
        this.myvalue.forEach(el=>{
          el.view = true
        })
      }

      this.myvalue.forEach(el=>{
        var n = el.name.indexOf('::')
        if(n>0) {
          el.name = el.name.substring(0,n)
        }
      })

      this.$emit('init', this.myvalue)
    },
  },
  data() {
    return {
      prefix:'msev2_',
      open:false,
      rawdata:[
        {
          id:'a121',
          name:'aaa121'
        },
        {
          id:'b1',
          name:'aaabbb'
        },
        {
          id:'cb1',
          name:'cc2'
        }
      ],
      myvalue:[
        {
          id:'a1',
          name:'aaa'
        },
        {
          id:'b1',
          name:'aaabbb'
        },
        {
          id:'cb1',
          name:'cc2'
        }
      ],
    }
  },
}
</script>
<style>
.myarea {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>
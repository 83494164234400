<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 700px !important;max-width: 700px !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              매체/상품 등록 요청
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body"
               style="max-height: 400px;overflow-y: scroll;letter-spacing: -1px"
          >
            <div style="padding: 8px 0">
              <div style="font-weight: bold;color: #4d545e;padding-bottom: 8px">
                매체 기본 정보 입력
              </div>

              <div style="display: flex">
                <div style="flex: 1;display: flex">
                  <div style="align-self: center;font-weight: bold">
                    매체 유형
                  </div>
                  <div style="flex: 1;padding-left: 8px">
                    <select class="form-select" aria-label="디바이스 (Device)"
                            v-if="type_list"
                            v-model="params.mediaTypeTemplateId"
                            @change="set_my_media_type"
                            :disabled="params.mediaTypeTemplateId"
                    >
                      <option :value="null">매체 유형</option>
                      <option
                          v-for="item in type_list"
                          :key="item.templateId"
                          :value="item.templateId">{{ item.displayName }}</option>
                    </select>
                  </div>
                </div>
                <div style="flex: 1;display: flex">
                  <div style="align-self: center;font-weight: bold;padding-left: 18px">
                    매체명
                  </div>
                  <div style="flex: 1;padding-left: 8px">
                    <input class="form-control" type="text" placeholder="매체명"
                           v-model="params.channelNameKo"
                    >
                  </div>
                </div>
                <div style="flex: 1;display: flex">
                  <div style="align-self: center;font-weight: bold;padding-left: 18px">
                    매체명(영문)
                  </div>
                  <div style="flex: 1;padding-left: 8px">
                    <input class="form-control" type="text" placeholder="매체명(영문)"
                           v-model="params.channelNameEn"
                    >
                  </div>
                </div>
              </div>
            </div>

            <hr/>

            <div
                v-if="params.mediaTypeTemplateId"
                style="padding: 8px 0">
              <div style="font-weight: bold;color: #4d545e;padding-bottom: 8px">
                광고 상품 단일 등록
              </div>
              <media-management-input
                  v-if="my_media_type"
                  @input="input"
                  :my_media_type="my_media_type" :key="my_media_type"
              />

              <media-management
                  :my_media_type="my_media_type" key="m_my_media_type" ref="ad_product"/>

            </div>

            <div
                v-if="params.mediaTypeTemplateId"
                style="padding: 8px 0">
              <div style="font-weight: bold;color: #4d545e;padding-bottom: 8px">
                광고 상품 대량 등록
              </div>

              <div style="padding: 12px;background-color:white;border-radius: 8px">
                <div class="d-grid mb-2">
                  <button type="button" class="btn btn-light waves-effect"
                          @click="download"
                  >
                    대량 등록 템플릿 다운로드
                    <i style="padding-left: 5px" class="fas fa-file-download"></i>
                  </button>
                </div>

                <div class="d-grid">
                  <div class="input-group">
                    <input type="file" class="form-control" id="customFile"
                           ref="file"
                           @change="handleFileUpload"
                    >
                  </div>
                </div>
              </div>


            </div>


          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import mediaManagementInput from "@/pages/setting/system/media/mediaManagementInput";
import mediaManagement from "@/pages/setting/system/media/mediaManagement";
import myError from "@/common/myError"
import {
  ADD_MEDIA
} from './constants.js'
import {mapGetters} from "vuex";
export default {
  mounted() {

    this.type_list = []
    this.first_media_type.mediaType.forEach(el=>{
      if(this.dashboard) {
        if(el.displayName.toUpperCase() == 'DIGITAL') {
          this.type_list.push(el)
        }
      } else {
        this.type_list.push(el)
      }
    })

  },
  computed: mapGetters({
    first_media_type: 'first_media_type',
    dashboard:'dashboard'
  }),
  mixins:[myError],
  name: 'registerModal',
  components: {
    mediaManagementInput,
    mediaManagement
  },
  props: {
    advertiserId: {
      default: null
    }
  },
  data () {
    return {
      type_list:null,
      file:null,
      ad_product:-1,
      my_media_type:'',
      dlg_open:false,
      params: {
        channelNameEn:null,
        channelNameKo:null,
        mediaTypeTemplateId:null,
        "adProductList": [
        ],
      },
    }
  },
  methods: {
    input:function (v) {
      this.$refs.ad_product.add_item(v)
    },
    handleFileUpload:function () {
      this.file = this.$refs.file.files[0];
    },
    add_from_file:function () {

      var url = '/api/media/add-with-template?'
      url += '&mediaTypeTemplateId='+this.params.mediaTypeTemplateId
      url += '&channelNameEn='+encodeURIComponent(this.params.channelNameEn)
      url += '&channelNameKo='+encodeURIComponent(this.params.channelNameKo)

      this.$eventBus.$emit('loading', true)

      let formData = new FormData();
      formData.append('file', this.file);

      this.$http.post(url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {

            this.$eventBus.$emit('info', '등록완료 되었습니다.')
            this.dlg_open = false

            this.$eventBus.$emit('loading', false)

            this.$emit('updated')
          })
          .catch(error => {
            //this.toastr('warning', error.response.data.errorMessage)
            this.$eventBus.$emit('error',error.responseMessage)

            this.$eventBus.$emit('loading', false)
          })
    },
    download:function () {
      this.$http({
        method: 'GET',
        url: '/api/media/download/ad-product-template',
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    set_my_media_type:function () {
      //this.params.mediaTypeTemplateId

      this.first_media_type.mediaType.forEach(el=>{
        if(el.templateId==this.params.mediaTypeTemplateId) {
          this.my_media_type = el.displayName.toLowerCase()
        }
      })
    },
    open:function () {
      this.reset()
      this.dlg_open = true
    },
    checkValidation:function (resolve, reject) {

      var that = this
      this.resetMyError()

      return new Promise(function(resolve, reject) {

        if(!that.params.mediaTypeTemplateId) {
          that.setMyError('매체명을 입력해주세요.')
          throw 'e'
        }

        if(!that.params.channelNameKo) {
          that.setMyError('매체명을 입력해주세요.')
          throw 'e'
        }

        if(!that.params.channelNameEn) {
          that.setMyError('매체명을 입력해주세요.')
          throw 'e'
        }

        if(!that.$refs.ad_product.validate())
          throw 'e'

        resolve()
      }).catch(()=>{
        reject()
      })
    },
    add_manual() {

      var v = this.$refs.ad_product.get()
      this.params['adProductList'] = v

      this.$http.post(ADD_MEDIA, this.params)
          .then(() => {

            this.$eventBus.$emit('info', '등록완료 되었습니다.')
            this.dlg_open = false

            this.$emit('updated')
          })
          .catch(error => {
            //this.toastr('warning', error.response.data.errorMessage)
            this.$eventBus.$emit('error',error.responseMessage)
          })
    },
    onSubmit() {

      // validation
      var that = this
      this.checkValidation()
        .then(()=>{
          if(!this.file)
            that.add_manual()
          else {
            that.add_from_file()
          }
        })
        .catch(()=>{
          this.whenErrorFalseAndAlert()
        })
    },
    reset() {
      this.params = {
        channelNameEn:null,
        channelNameKo:null,
        mediaTypeTemplateId:null,
        "adProductList": [
        ],
      }
    }
  },
  watch: {
  }
}
</script>

<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >
        <div class="modal-content"
             style="max-height: 85vh;overflow-y: auto"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              코차바 리포트 연결
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div>

              <table class="table" style="width: 100%">
                <tbody>
                <tr
                    class="no-border"
                >
                  <th scope="row">트래커 ID</th>
                  <td>
                    <div style="display: flex">
                      <div style="flex: 1">
                        <input class="form-control" type="text" placeholder="트래커 ID"
                               v-model="params.trackerId"
                        >
                      </div>
                      <div style="padding-left: 3px">
                        <button type="button" class="btn btn-primary waves-effect"
                                @click="search2"
                        >
                          조회
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="list_kochava && list_kochava.length>0">
                  <th scope="row">매체</th>
                  <td>
                    <table>
                      <tr v-for="xitem in list_kochava">
                        <td>
                          <input class="form-check-input" type="checkbox" v-model="params.kochavaSelectedList" :value="xitem.kochavaId">
                          {{xitem.kochavaMediaName}}
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>
                </tbody>
              </table>

            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              연결
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MySelect from "@/components/common/MySelect";
import myError from "@/common/myError"
import {
  GET_BUSINESS_LIST,
  CHECK_BUSINESS_NUMBER_DUPLICATE,
  ADD_ADVERTISER,
  EDIT_ADVERTISER,
  GET_ADVERTISER_INFO
} from './constants.js'
import moment from "moment";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
  mixins:[myError],
  components: {
  },
  props: {
  },
  computed: {
    ...mapGetters({
        first_media_type:'first_media_type'
    }),
  },
  mounted() {
    //this.loadFilter()
  },
  data () {
    return {
      list_kochava:null,
      available:false,
      data_campaign:null,
      data_adgrp:null,
      auth_btn:false,
      auth_url:'',
      pause_flag:false,
      /*
"(카카오 인증이 안되면, http://ga-test.snadx.com/:8091로그인 상태에서 브라우저에 http://ga-test.snadx.com:8091/api/manage/kakao/auth 를 입력한다, 카카오 로긴 정보 - 계정ID: rnd.dmc3@gmail.com 계정PW: epdlxjakzpxld@ )
카카오 인증 -  캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=282779&apiTypeTemplateId=49&adReportTypeTemplateId=54
카카오 인증 -  광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=282779&apiTypeTemplateId=49&adReportTypeTemplateId=55&campaignId=727158
네이버 인증 - 캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?apiTypeTemplateId=50&adReportTypeTemplateId=54
네이버 인증 - 광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?apiTypeTemplateId=50&adReportTypeTemplateId=55&campaignId=1372873
코차바 인증: Get http://ga-test.snadx.com:8091/api/manage/validate/kochava?trackerId=13034078158
구글 인증 - 캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=1566004847&apiTypeTemplateId=50&adReportTypeTemplateId=54
구글인증- 광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=1566004847&apiTypeTemplateId=50&adReportTypeTemplateId=55&campaignId=1372032
(구글 이증이 안되면, http://ga-test.snadx.com/:8091로그인 상태에서 브라우저에 http://ga-test.snadx.com:8091/api/manage/google/auth 를 입력한다, 구글 로긴 정보 - 계정ID: rnd.dmc3@gmail.com 계정PW: epdlxjakzpxld! )"
*/
      c:false,
      api_map:{
        '52':{
          // 코차바
          url:'/api/manage/validate/kochava?',
              //'trackerId=13034078158',
          sub_list:true
        },
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        kochavaSelectedList:[],
        trackerId:null,
      },
    }
  },
  methods: {
    search2:function () {
      // 조회 주소 생성
      var v = '52'
      var o = this.api_map[v]
      var url = o.url

      //this.params.trackerId='13034078158'
      if(!this.params.trackerId) {
        this.$eventBus.$emit('error', '트래커 ID를 입력해주세요.')
        return
      }

      url += 'trackerId=' + this.params.trackerId

      this.params.kochavaSelectedList = []
      this.list_kochava = null
      this.$http.get(url)
          .then(({ data }) => {

            this.list_kochava = data
          })
    },
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

      // load
      this.init()
    },
    init:function () {
    },
    open_mod:function (id) {
      this.reset()
      this.params.apiId = id
      this.dlg_open = true
    },
    checkValidation:function (modification) {

      var that = this
      this.resetMyError()

      return new Promise(function(resolve, reject) {
        resolve()
      })
    },
    onSubmit_kochava() {

      // validation
        this.params.kochavaList = []
        this.params.kochavaSelectedList.forEach(el=>{
          this.params.kochavaList.push({kochavaId:el})
        })

      var api = '/api/manage/connect/Kochava'

      this.$http.post(api, this.params)
          .then( ({data}) => {

            this.$eventBus.$emit('info', '설정이 완료되었습니다.')
            this.dlg_open = false

            this.$emit('updated')
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.errorMessage)
          })


      this.dlg_open = false

    },
    onSubmit() {
      // kochava
      this.onSubmit_kochava()
    },
    reset() {
      this.params = {
        advertiserId: this.advertiserId,
        advertiserName: '',
        kochavaSelectedList:[]
      }
      this.list_kochava = null
    }
  },
  watch: {
    'params.apiTypeTemplateId' : function () {

      // api_map
      if(!this.params.apiTypeTemplateId)
        return

      var v = this.params.apiTypeTemplateId.toString()
      var o = this.api_map[v]
      this.sub_list = o.sub_list

      if(this.api_map[v].show_btn) {
        this.auth_btn = true
        this.auth_url = this.api_map[v].auth_url
      } else {
        this.auth_btn = false
      }

      if(this.api_map[v].accountId)
        this.params.accountId = this.api_map[v].accountId
      else
        this.params.accountId = ''
    }
  }
}
</script>

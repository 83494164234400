<template>
  <suggestions
      v-model="val"
      @search:focus="focus"
      :placeholder="hint"
      @option:selected="selected"
      label="search"
      :filterable="false"
      :options="options"
      :onInputChange="onSearch"
      :onItemSelected="onSearchItemSelected"
      >
    <template slot="no-options">
      {{ hint }}
    </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.name }}
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ option.name }}
      </div>
    </template>
  </suggestions>
</template>
<script>

import Suggestions from 'v-suggestions'
import 'v-suggestions/dist/v-suggestions.css' // you can import the stylesheets also (optional)

//import 'vue-select/dist/vue-select.css';
//import vSelect from 'vue-select'

export default {
  watch:{
    val:function (newValue) {
      this.$emit('input', newValue)
      this.$emit('change', newValue)

      //console.log(newValue)
    },
    value:function () {
      this.val = this.value
    },
    options:function () {
    }
  },
  components:{
    'suggestions':Suggestions
  },
  props: {
    options: {
      default:[],
      type:Array
    },
    hint:{
      default:null,
      type:String
    },
    mstyle: {
      default:null,
      type:String
    },
    value: {
      default:null,
      type:String
    },
    label: {
      default: '',
      type: String
    },
  },
  mounted() {
    if(this.value)
      this.val = this.value
  },
  methods:{
    onSearchItemSelected:function (v) {
      this.$emit('selected', v)
      //console.log(v)
    },
    onenter:function () {
      console.log('aaa')
    },
    selected:function () {
      this.$emit('selected', this.val)
    },
    focus:function () {
      this.$emit('focus')
    },
    load:function () {
      //var data = this.getParams()
      var data = null
      this.$http.post('/api/history/dashboard-new',data)
          .then(({ data }) => {
            //this.loadData(data)
            console.log(data)
          })
    },
    onSearch(query) {

      if (query.trim().length === 0) {
        return null
      }

      return new Promise(resolve => {
        this.$emit('onsearch2', query, resolve)
        return
      })
    },
  },
  data() {
    return {
      val:null
    }
  }
}
</script>
<style>
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect__tags {
  min-height: 0px !important;
  padding: 2px 40px 0 2px !important;
}
.vs__actions svg {
  /*display: nonexxxx;
   */
}
.vs__actions button {
  padding-bottom: 3px;
}
.vs__dropdown-toggle {
  padding-top: 0px;
}
.vs__selected-options input {
  min-height: 21.5px;
}
</style>
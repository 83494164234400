<template>
  <div class="page_parent">
    <gnb class="mbg">
      <template  slot="desc">
        <div style="display: flex">
          <div style="flex: 1">
            실시간으로 집행 중인 캠페인의 진행 현황을 확인합니다.
          </div>
          <div style="text-align: right;font-size: 11px;padding-right: 40px">
            ※ API 송출 주기 네이버 : 1시간 | 카카오모먼트 : 1시간 | 구글 : 1시간
          </div>
        </div>
      </template>
    </gnb>

    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary">

          <div class="d-flex" >
            <div v-if="user.userinfo.roleId!=1 && list_advertisers" style="flex:2;margin-right: 8px">
              <my-multi-select
                  v-model="selected_advertiser"
                  :list="list_advertisers"
                  k="advertiserId"
                  v="advertiserName"
                  label="광고주를 선택해주세요. (중복선택가능)"
              />
            </div>

            <div
                style="flex:1;margin-right: 8px"
            >
              <my-range-edit style="width: 100%" v-model="range"/>
            </div>
            <div style="flex: 2">
              <my-search-box
                  v-model="search_txt"
                  @focus="onfocus"
                  :options="options"
                  @selected="search_selected"
                  @onsearch2="onsearch2"
                  hint="검색어를 입력해주세요."
              />

              <input
                  v-if="false"
                  class="form-control" type="text"
                     v-model="search_txt"
                     @keydown.enter="search"
                     placeholder="캠페인명을 입력해주세요.">

            </div>
            <div style=";margin-left: 5px;" class="d-grid">
              <button type="button"
                      @click="search"
                      style="height: 32px"
                      class="btn btn-primary waves-effect waves-light"
              >
                검색
              </button>
            </div>

          </div>


        </div>
      </div>

      <my-table
          clickable
          @rowClick="rowClick"
          :head="myheader"
          :tableData="tableData"
          :format="myformat"

          :key="'tb_present'"
          :head-id="'tb_present'"
          :head-sort="mysort"
      >
        <template slot="__slot_header">
          <div class="d-flex" style="padding: 15px 0 15px 0">
            <div style="align-self: center;padding-left: 20px;">
              <div class="d-flex">
                <img src="/img/bulb_icon.png" style="width: 16.5px;height: 16.5px"/>
                <div style="padding-left: 8px">
                  주의
                </div>
              </div>
            </div>
            <div style="align-self: center;padding-left: 15px;">
              <div class="d-flex">
                <div class="safe"></div>
                <div style="padding-left: 8px">
                  안전
                </div>
              </div>
            </div>
            <div style="flex-grow: 1">

            </div>

            <button type="button"
                    style="margin-left: 30px;margin-right: 10px"
                    class="btn btn-dark waves-effect waves-light"
                    @click="download"
            >
              다운로드
              <i class="fas fa-file-excel"></i>
            </button>
          </div>

        </template>

        <template slot="_slot_campaignName" slot-scope="slotProps">
          <div style="min-width: 140px">
            {{slotProps.item.campaignName}}
          </div>
        </template>

        <template slot="_slot_spentMoney" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.spentMoney)"></span>
        </template>
        <template slot="_slot_budgetRate" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.budgetRate, true)"></span>
        </template>
        <template slot="_slot_impRate" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.impRate, true)"></span>
        </template>

        <template slot="_slot_grpsRate" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.grpsRate, true)"></span>
        </template>

        <template slot="_slot_clickRate" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.clickRate, true)"></span>
        </template>
        <template slot="_slot_viewRate" slot-scope="slotProps">
          <span v-html="my_number(slotProps.item.viewRate, true)"></span>
        </template>
        <template slot="_slot_status" slot-scope="slotProps">
          <span v-if="slotProps.item.status==1" class="safe"/>
          <div v-else>
            <img src="/img/bulb_icon.png" style="width: 16.5px;cursor: pointer"/>
            <i v-if="slotProps.item.statusMessage" class="fas fa-exclamation-circle align-self-center"
               style="vertical-align:middle;padding-left:16px;font-size: 15px" @click.stop="alert_msg(slotProps.item.statusMessage)"></i>
          </div>

        </template>
        <template slot="_slot_change" slot-scope="slotProps">
            <i class="fas fa-cog align-self-center" @click.stop="change_status(slotProps.item)"></i>
        </template>
        <template slot="_slot_process" slot-scope="slotProps">
          <div style="display: flex;flex-direction: column">
            <div>
              {{ slotProps.item.startDate }} ~ {{ slotProps.item.endDate }}
            </div>
            <div style="display: flex">
              <div class="progress animated-progess"
                   style="min-width: 120px;margin-top: 6px;flex: 1"
              >
                <div class="progress-bar bg-success" role="progressbar" :style="'width: '+get_percent(slotProps.item.startDate,slotProps.item.endDate)+'%'" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div style="text-align: right;min-width: 30px;padding-left: 10px;font-size: 10px">
                <span v-text="get_percent(slotProps.item.startDate,slotProps.item.endDate)"/>%
              </div>
            </div>
          </div>
        </template>
      </my-table>

      <detail ref="detail" @updated="search"/>
      <config ref="config" @updated="search"/>

    </div>
  </div>
</template>
<script>
import MyRangeEdit from "@/common/MyRangeEdit";
import MyMultiSelect from "@/components/common/MyMultiSelect";
import config from "@/pages/present/config";
import detail from "@/pages/present/detail";
import MyTable from "@/components/common/MyTable";
import moment from "moment";
import {mapGetters} from "vuex";
import Gnb from "@/nav/gnb";
import MySearchBox from "@/components/common/MySearchBox";
export default {
  components: {
    MySearchBox,
    MyRangeEdit,
    MyMultiSelect,
    MyTable,
    detail,
    Gnb,
    config
  },
  props: {},
  mounted() {

    // 2 운영
    // 3 관리

    this.loadFilter()
    this.search()

    if(this.user.userinfo.roleId==1) {
      this.selected_advertiser = [this.user.userinfo.advertiserId]
    }

    // 파라메터 확인 (해당캠페인 바로 오픈)
    if(this.$route.query.campaignId) {
      var o = {
        campaignId:this.$route.query.campaignId,
        campaignName:this.$route.query.campaignName
      }

      this.rowClick(o)
    }
    //this.$refs.detail.open(item)
  },
  methods:{
    onfocus:function () {
      this.onsearch()
    },

    search_selected:function (v) {
      this.search_txt = v
      this.search()
    },
    onsearch2:function (k, resolve) {

      this.search_txt = k
      var data = this.getParams()
      if(!data)
        return

      var param = this.getParams()
      this.$http.post('/api/campaign/dashboard', param)
          .then(({ data }) => {

            var a = []
            data.forEach(el=>{
              a.push(el['campaignName'])
            })

            resolve(a)
            //this.options = a
          }).catch(err=>{
      })

    },
    download:function () {

      var param = this.getParams()

      /*
      "{
    ""advertiserIds"": [
        1,
        2,
        3
    ],
    ""startDate"": """",
    ""endDate"": """",
    ""campaignName"": """"
}"
       */


      var o = param

      this.$http({
        method: 'POST',
        url: '/api/campaign/download/dashboard',
        //params:o,
        data:o,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'present.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    alert_msg:function (msg) {
      this.$eventBus.$emit('error', msg)
    },
    change_status:function (item) {
      // 운영계정
      if(this.user.userinfo.roleId!=2) {
        return
      }
      this.$refs.config.open(item)
    },
    get_percent:function (a1,a2) {

      var d1 = moment(a1)
      var d2 = moment(a2)
      var now = moment(new Date())

      if(d1 > now)
        return 0
      if(d2 < now)
        return 100

      var diff = d2.diff(d1, 'days')+1
      var diff2 = d2.diff(now, 'days')+1

      var per = 100 - parseInt(diff2 / diff * 100)

      // 전체 기간 : diff
      // 남은일 : diff2

      return per
    },
    my_number:function (v, fl) {
      if(fl)
        v = parseFloat(v).toFixed(2)

      try {
        v = this.numberWithCommas(v)
      } catch (e) {}

      return v
    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {
            this.list_advertisers = data
          })
    },
    rowClick:function (item) {
      //console.log(item)
      this.$refs.detail.open(item)
    },
    getParams:function () {

      var advs = []
      try {
        // param
        var v = this.selected_advertiser
        v.forEach(el=>{
          advs.push(el)
        })
      } catch (e){}

      var d1 = moment(this.range.startDate).format('YYYY-MM-DD')
      var d2 = moment(this.range.endDate).format('YYYY-MM-DD')

      var param = {}
      param['advertiserIds'] = advs
      param['startDate'] = d1
      param['endDate'] = d2
      param['campaignName'] = this.search_txt

      // type
      //private int option; // 1: normal, 2: weekly, 3: month
      //private int type; // 1:캠페인 효율, 2: 테이블 통합, 3: 롬스 양식(Digital)

      return param
    },
    search:function () {
      var param = this.getParams()
      this.$http.post('/api/campaign/dashboard', param)
          .then(({ data }) => {
            this.tableData = data
          })
    }
  },
  computed: {
    ...mapGetters({
      user:'user',
      first_media_type: 'first_media_type',
    }),
    myheader:function () {

      // 상태 변경
      if(this.user.userinfo.roleId==2) {
        if(this.user.userinfo.dashboard==2) {
          this.mysort = ['status', 'campaignName', 'endDate', 'impRate::@NUM', 'grpsRate::@NUM', 'clickRate::@NUM', 'viewRate::@NUM', false]
          return ['상태::@CENTER', '캠페인명::@CENTER', '집행기간::@CENTER', '노출 달성율(%)::@NUM', 'GRPs 달성율(%)::@NUM', '클릭 달성율(%)::@NUM', '조회 달성율(%)::@NUM', '상태 변경::@CENTER']
        }

        this.mysort = ['status', 'campaignName', 'endDate', 'impRate::@NUM', 'clickRate::@NUM', 'viewRate::@NUM', false]
        return ['상태::@CENTER','캠페인명::@CENTER','집행기간::@CENTER','노출 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM','상태 변경::@CENTER']

      } else {
        if(this.user.userinfo.dashboard==2) {
          this.mysort = ['status', 'campaignName', 'endDate', 'impRate::@NUM', 'grpsRate::@NUM', 'clickRate::@NUM', 'viewRate::@NUM']
          return ['상태::@CENTER','캠페인명::@CENTER','집행기간::@CENTER','노출 달성율(%)::@NUM','GRPs 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM']
        }

        this.mysort = ['status', 'campaignName', 'endDate', 'impRate::@NUM', 'clickRate::@NUM', 'viewRate::@NUM']
        return ['상태::@CENTER','캠페인명::@CENTER','집행기간::@CENTER','노출 달성율(%)::@NUM','클릭 달성율(%)::@NUM','조회 달성율(%)::@NUM']
      }
    },
    myformat:function () {
      if(this.user.userinfo.roleId==2) {
        if(this.user.userinfo.dashboard==2)
          return ['_slot_status','_slot_campaignName','_slot_process','_slot_impRate','_slot_grpsRate','_slot_clickRate','_slot_viewRate','_slot_change']

        return ['_slot_status','_slot_campaignName','_slot_process','_slot_impRate','_slot_clickRate','_slot_viewRate','_slot_change']
      } else {
        if(this.user.userinfo.dashboard==2)
          return ['_slot_status','_slot_campaignName','_slot_process','_slot_impRate','_slot_grpsRate','_slot_clickRate','_slot_viewRate']

        return ['_slot_status','_slot_campaignName','_slot_process','_slot_impRate','_slot_clickRate','_slot_viewRate']
      }
    },
  },
  data() {
    return {
      search_txt:'',
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      mysort:null,
      pageParam:null,
      tableData:null,
      selected_advertiser:null,
      list_advertisers:null,
      api_media: [],
      range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    }
  }
}
</script>
<style>
.vc-popover-content-wrapper {
  z-index: 1000 !important;
}
.safe {
  width: 16px;height:16px;background-color: #1cbb8c;border-radius: 100%;
  display: inline-block;
}
.danger {
  width: 16px;height:16px;background-color: #ff1717;border-radius: 100%;
}
</style>
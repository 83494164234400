<template>
  <div id="app" style="width: 100vw;max-width: 100vw;" class="vh-100" v-if="$route.path=='/login' || first_media_type">

    <div v-if="mymenu"
         @click="close"
         style="position: absolute;left: 0;top:0;width: 100%;height: 100%;background-color: #0b0b0b;opacity: 0.7;z-index: 1900"
    ></div>

    <div v-if="isNav" class="w-100 vh-100">
        <div class="d-flex w-100" style="min-height: 100%">
          <snb2 @onmenu="onmenu"/>
          <div class="gbg" style="flex:1;max-width: 95vw">
            <div style="display: flex;flex-direction: column;height: 100%;width:100%">
              <router-view style="flex:1;width: 100%"/>
              <div
                  v-if="$route.path.indexOf('/intro')<0"
                  style="height: 28px;text-align: center;background-color:#e2e5e7;color: #828EA6;font-size: 11px;">
                Copyright. All rights reserved.
                <span style="color: rgb(226, 229, 231)">20220824</span>
              </div>
            </div>

          </div>
        </div>

        <snb v-if="mymenu"
             @close="close"
             style="position:absolute;left:0;top:0;height:100%;min-height:100%;width:300px;z-index: 3000;overflow-y: auto" />
    </div>

    <div v-else class="h-100">
      <div class="contents-wrapper h-100">
        <router-view class="main-width" />
      </div>
    </div>

    <alert ref="dlg_alert"/>
    <prompt ref="dlg_prompt" @ok="prompt_ok"/>
    <prompt ref="dlg_confirm" @ok="confirm_ok" no_data/>

    <div v-if="isLoading" style="position: absolute;width: 100%;height: 100%;left: 0;top: 0">
      <div style="display: flex;justify-content: center;width: 100%;height: 100%;background-color: #2B333F99">
        <pulse-loader
            style="align-self: center"
            :loading="true" color="#ff1b81" size="18px"></pulse-loader>
      </div>
    </div>



    <loading/>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


import gnb from '@/nav/gnb'
import snb from '@/nav/snb'
import snb2 from '@/nav/snb2'

import alert from "@/common/alert";
import prompt from "@/common/prompt";
import { mapState, mapActions, mapGetters } from 'vuex'
import { MENU_LIST } from '@/constants'
import store from "@/store";
export default {
  computed: {
    ...mapGetters({
      user:'user',
      axios_error_status: 'axios_error_status',
      first_media_type:'first_media_type'
    }),
  },
  name: 'App',
  components: {
    Loading,
    'PulseLoader': PulseLoader,
    gnb, snb, alert, prompt, snb2
  },
  data () {
    return {
      isLoading:false,
      currentPath:null,
      menuList: MENU_LIST,
      rootList: null,
      mymenu:false,
      isNav: true,
      prompt_callback:null,
      confirm_callback:null,
      load_once:false,
    }
  },
  methods: {
    myloading:function (v) {
      this.isLoading = v
    },
    resetTree : function (tree) {
      for(var el of tree) {
        el.parent = null
        if(el.subMenu) {
          this.resetTree(el.subMenu)
        }
      }
    },
    initMenu:function (tmpList) {

      var list = this.menuList

      var redrawing = false
      if(tmpList != null && tmpList != undefined) {
        list = tmpList
        redrawing = true
      }

      var resetTree = function (tree) {
        for(var el of tree) {
          el.selected = false
          if(el.subMenu) {
            resetTree(el.subMenu)
          }
        }
      }

      if(!redrawing)
        resetTree(list)

      // 트리를 통해 메뉴를 찾는다.
      var findTree = function (tree, path, parent) {

        for(var el of tree) {

          if(el.path==path) {

            // 찾았다 (나의부모)
            el['parent'] = parent
            return el
          } else if(el.path_alt) {

            var a = el.path_alt.find(element => element == path)
            if(a) {
              el['parent'] = parent
              return el
            }
          }

          if(el.subMenu) {
            el.parent = parent
            var o = findTree(el.subMenu, path, el)
            if(o) {
              // 찾았다.
              return o
            }
          }
        }

        return null
      }

      this.rootList = this.menuList
      var o = findTree(this.menuList, this.currentPath, null)

      if(o) {
        // rootList
        // 부모찾아
        var findRoot = function (node) {
          node.selected = true

          if(!node.parent) {
            return node
          }
          node.parent.selected = true
          return findRoot(node.parent)
        }
        findRoot(o)
      }
    },
    close:function () {
      $('body').css('overflow','auto')
      this.mymenu = false
    },
    onmenu:function () {

      // select
      ///api/media/status-count?status=1
      this.$http.get('/api/media/status-count?status=1')
          .then(({ data }) => {

            this.$store.commit('mediacnt',data)
            //console.log(data)
          })
          .catch(error => {
          })

      $('body').css('overflow','hidden')
      this.mymenu=true
    },
    loadMedia:function () {

      var media_list = null
      var select_media = []
      var media_products = {}


      var url = '/api/media/listType?mediaTypeTemplateId=3'
      this.$http.get(url)
          .then(({ data }) => {

            var that = this
            media_list = data
            media_list.forEach(o => {
              select_media.push({
                value: o.mediaId,
                text: o.channelNameKo
              })
            })

            // all products
            var url = '/api/media/adProduct?mediaTypeTemplateId=3'
            this.$http.get(url)
                .then(({data}) => {
                  var a = data

                  select_media.forEach(el => {
                    var id = el.value
                    for (let idx in a) {
                      var el2 = a[idx]
                      if (el2['mediaId'] == id) {

                        var new_data = []
                        var arr = el2.adProductList
                        for (let idx2 in arr) {
                          var el3 = arr[idx2]
                          new_data.push({
                            value: el3.adProductId,
                            text: el3.placement
                          })
                        }
                        media_products[id] = new_data
                        break
                      }
                    }
                  })

                  var media_products_info = {
                    media_list: media_list,
                    select_media: select_media,
                    media_products: media_products
                  }
                  that.$store.commit('media_products_info', media_products_info)

                })
          })
    },
    prompt_ok:function (v) {
      if(this.prompt_callback)
        this.prompt_callback(v)
    },
    confirm_ok:function (v) {
      if(this.confirm_callback)
        this.confirm_callback(v)
    },
    dlg_prompt:function (title, msg, callback) {
      this.prompt_callback = callback
      this.$refs.dlg_prompt.open(title, msg)
    },
    dlg_confirm:function (title, msg, callback) {
      this.confirm_callback = callback
      this.$refs.dlg_confirm.open(title, msg)
    },
    dlg_alert:function (title, msg) {
      this.$refs.dlg_alert.open(title, msg)
    },
    init:function () {
      // 로그인 상태일때만
      //if(this.user)
      {
        this.getMediaType()
      }
    },
    my_info:function (title, msg) {
      try {
        var a = window.toastr
        a.options = {
          closeButton: true,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          showMethod: 'slideDown',
        };
        a.success(msg, title)

      } catch (e) {
        console.log(e)
      }
    },
    my_error:function (title, msg) {
      try {
        var a = window.toastr
        a.options = {
          closeButton: true,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          showMethod: 'slideDown',
        };
        a.error(msg, title)

      } catch (e) {
        console.log(e)
      }
    },
    getMediaType() {
      if(this.load_once)
        return

      this.load_once = true

      this.$http.get('/api/template/list')
          .then(({ data }) => {

            var a = []
            data.mediaType.forEach(el=>{
              var k = el.displayName.toLowerCase()
              a.push(k)
            })

            // digital??
            this.$store.commit('main_media_type', a)
            this.$store.commit('first_media_type', data)

            // 매체 전부 로드
            this.loadMedia()
          })
          .catch(error => {
          })
    },
  },
  created() {
  },
  mounted() {

    this.init()

    this.isNav = this.$route.path ===  '/login' ? false : true

    this.$eventBus.$on('loading', this.myloading)

    this.$eventBus.$on('info', this.my_info)
    this.$eventBus.$on('error', this.my_error)

    this.$eventBus.$on('dlg_alert', this.dlg_alert)
    this.$eventBus.$on('dlg_prompt', this.dlg_prompt)
    this.$eventBus.$on('dlg_confirm', this.dlg_confirm)

  },
  watch: {

    user:function () {
      this.init()
    },
    axios_error_status:function () {
      this.$eventBus.$emit('error', this.axios_error_status)

      this.$router.push({path:'/login'})
      return;
    },
    '$route' (to) {
      this.isNav = to.path === '/login' ? false : true

      this.currentPath = this.$route.path

      // 현재 nav
      this.initMenu()
      //this.resetTree(this.rootList)

      var nav = []
      var findNav = function (list) {
        for(var el of list) {

          if(el.selected) {
            nav.push(el)
            if(el.subMenu) {
              findNav(el.subMenu)
            }
          }
        }
      }
      findNav(this.rootList)
      this.$store.commit('mynav', nav)
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width:100%;
  height:100%;
  /* margin-top: 60px; */
}
.main-width {
  width: 100%;
  height: 100%;
  padding: 18px;
}
</style>
<style>
.modal {
  display: flex !important;
  justify-content: center;
}
.modal-dialog {
  align-self: center;
  min-width: 70vh;
  max-height: 90vh;
}
.table_wrapper {
  max-height: 50vh !important;
}
</style>
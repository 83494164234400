<template>
  <select
      v-model="val"
      class="form-select" :aria-label="label"
      v-if="items2!=null"
      :disabled="disabled"
  >
    <option v-if="placeholder && items2"
            :value="null"
            >{{placeholder}}</option>
    <option
        v-for="x in items2"
        :key="x.value"
        :value="x.value">
      {{x.text}}
    </option>
  </select>
</template>
<script>
export default {
  watch:{
    val:function () {
      this.$emit('input', this.val)
      this.$emit('change', this.val)
    },
    value:function () {
      this.val = this.value
    },
    items:function () {
      this.init()
    }
  },
  methods:{
    init:function () {
      if(this.items) {
        this.items2 = []
        this.items.forEach(el=>{
          if(this.hide_key) {
            if(this.hide_key != el.value)
              this.items2.push(el)
          } else
            this.items2.push(el)
        })
      }
    }
  },
  props: {
    disabled: {
      default:false,
      type:Boolean
    },
    hide_key: {
      default:null,
      type:String
    },
    placeholder: {
      default:null,
      type:String
    },
    value: {
      default:null,
      type:String
    },
    label: {
      default: '',
      type: String
    },
    items: {
      default: null,
      type: Array
    }
  },
  mounted() {
    this.val = this.value

    this.init()
  },
  data() {
    return {
      items2:null,
      val:null
    }
  }
}
</script>
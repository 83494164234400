<template>
  <div class="d-flex justify-content-center w-100 mbg">
    <div class="align-self-center">
      <div style="display: flex;justify-content: center;flex-direction: column;min-width: 450px">
        <div style="align-self: center;font-size: 32px">
          <b class="white">Media Starium</b>
        </div>
        <div style=";font-size: 16px">

          <div style="display: flex;padding: 25px 0">
            <div>
              Dashboard Type
            </div>
            <div style="align-self: center;width: 70px;text-align: center">
              |
            </div>
            <div class="d-flex" style="flex: 1">
              <div class="form-check white" style="flex: 1;text-align: left">
                <input
                    v-model="params.dashboard"
                    value="1"
                    class="form-check-input" type="radio" name="ormRadios" id="formRadios1">
                <label class="form-check-label" for="formRadios1" style="padding-left: 30px">
                  Digital
                </label>
              </div>
              <div class="form-check white" style="flex: 1;text-align: left">
                <input
                    v-model="params.dashboard"
                    value="2"
                    class="form-check-input" type="radio" name="formRadios" id="formRadios2">
                <label class="form-check-label" for="formRadios2"  style="padding-left: 30px">
                  All Media
                </label>
              </div>
            </div>
          </div>
          <div style="position: relative;">
            <input class="form-control"
                   style="padding: 14px;padding-left: 80px"
                   type="text" placeholder="아이디를 입력해주세요."
                   v-model="params.userId"
            >
            <div style="position: absolute;left: 30px;top:12px;color: #0b0b0b;z-index: 2;font-weight: bold">
              ID
            </div>
          </div>
          <div style="position: relative;margin-top: 18px">
            <input class="form-control"
                   style="padding: 14px;padding-left: 80px"
                   type="password" placeholder="비밀번호를 입력해주세요."
                   @keydown.enter="onSubmit"
                   v-model="params.password"
            >
            <div style="position: absolute;left: 30px;top:12px;color: #0b0b0b;z-index: 2;font-weight: bold">
              PW
            </div>
          </div>

          <div class="d-grid" style=";margin-top: 17px;justify-content: center">
            <button type="button" class="btn btn-primary waves-effect waves-light"
                    style="padding: 8px;border-radius: 25px;width: 300px;align-self: center;font-weight: bold;font-size: 16px"
                    @click="onSubmit"
            >
              LOGIN</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      params: {
        roleId: '',
        dashboard: 1,
        userId: '',
        password: ''
      },
      // roleTypeOptions: [],
      dashboardOptions: [
        { label: 'Digital', value: 1 },
        { label: 'All Media', value: 2 },
      ]
    }
  },
  methods: {
    // getRoleTypeOptions() {
    //     axios.get('/api/role/userRoles')
    //     .then(({data}) => {
    //         data.map(v => this.roleTypeOptions.push({ label: v.roleName, value: v.roleId}))
    //     })
    // },
    onSubmit() {

      this.$http.post('/api/user/login', this.params)
          .then( ({data}) => {

            // 로그인 성공
            //this.getSession()
            this.$cookies.set("DDB_SID", data);

            this.$router.push({path:'/'})
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.errorMessage)
          })
    }
  },
  mounted() {
    // this.getRoleTypeOptions()
  }
}
</script>
<style scoped>

.form-check .form-check-input {
float: left;
}

.th-width {
  font-size: 1.1em;
  font-weight: bold;
}
</style>
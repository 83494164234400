<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              {{ title }}
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body"
          >
            <div class="mb-2" v-html="msg">
            </div>
            <textarea
                v-if="!no_data"
                ref="msg"
                v-model="data" class="form-control" rows="3" :placeholder="''"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button"
                    @click="ok"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

            <button type="button"
                    @click="dlg_open=false"
                    class="btn btn-light waves-effect waves-light"
            >
              취소
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  components: {
  },
  props: {
    no_data:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      dlg_open:false,
      title:'',
      msg:'',
      data:null,
    }
  },
  methods: {
    ok:function () {
      this.$emit('ok', this.data)
      this.dlg_open = false
    },
    open:function (title, msg) {
      this.dlg_open = true

      this.title = title
      this.msg = msg
    },
  },
  watch: {
    dlg_open:function () {
      // focus
      this.$nextTick(() => {
        if(!this.no_data)
          this.$refs.msg.focus()
      })

    }
  }
}
</script>

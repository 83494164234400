<template>
<div style="display: flex">
  <div style="position: relative;width: 100%">
    <my-input label="날짜 입력 (YYYY-MM-DD)"
              v-model="mydate"
              mstyle="padding-left: 30px"
    />
    <div style="position:absolute; left: 8px;top:6px;cursor: pointer"
         @click="showCalendar"
    >
      <i style="font-size: 14px" class="fas fa-calendar-alt"></i>
    </div>

  </div>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 500px !important;max-width: 500px !important;"
      >
        <div class="modal-header">
          <h5 class="modal-title mt-0">
            날짜 선택
          </h5>
        </div>
        <div class="modal-content"
        >
          <div class="modal-body"
          >

            <date-range-picker
                opens="inline"
                :autoApply="true"
                @update="updateValues"
                style="flex-grow: 1;align-self: center"
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd',
                        applyLabel: '적용',
                        cancelLabel: '취소',
                      }"
                :singleDatePicker="'single'"
                :showDropdowns="true"
                :alwaysShowCalendars="true"
                v-model="date"
            >
              <template v-slot:input="picker">
              </template>
            </date-range-picker>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
          </div>
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>

</div>
</template>
<script>
import MyInput from "@/components/common/MyInput";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
export default {
  props:{
    value:{
      type:Object,
      default:null
    }
  },
  mounted() {
    if(this.value)
      this.date = this.value

    this.initRange()
  },
  computed: {
  },
  components: {
    MyInput,
    DateRangePicker,
  },
  watch: {
    mydate:function () {
      //console.log('a1')
      this.initRange2()
      //console.log('a22')
    },
    date:function () {
      this.$emit('input', this.date)
    }
  },
  methods: {
    getDate:function () {
      this.initRange()
      return this.date
    },
    initRange2:function () {

      var data = null
      try {
        var range = this.mydate
        data = moment(range).toDate()

        if(data == 'Invalid Date')
          throw 'e'

      } catch (e) {
        this.date = {
          startDate: new Date(),
          endDate: new Date(),
        }

        return
      }
      this.date = {
        startDate:data,
        endDate:data
      }

      //console.log('---->' + this.mydate)
      //console.log(this.date)
    },
    initRange:function () {
      var data = null

      try {
        data = moment(this.date.startDate).format('YYYY-MM-DD')
      } catch (e) {
        //console.log(e)
      }
      if(data) {
        this.mydate = data
        //console.log('aaa - ' + this.mydate)
      }
    },
    showCalendar:function () {

      //
      var data = null
      try {
        data = moment(this.mydate).toDate()

        if(data == 'Invalid Date')
          throw 'e'

      } catch (e) {
        this.date = {
          startDate:new Date(),
          endDate:new Date(),
        }
        this.initRange()
        this.dlg_open = true
        return
      }

      this.date = {
        startDate:data,
        endDate:data
      }
      this.dlg_open=true
    },
    updateValues:function (v) {
      this.dlg_open = false

      // changed
      this.initRange()
    },
    ok:function () {
    }
  },
  data() {
    return {
      mydate:null,
      dlg_open:false,
      date:{
        startDate:new Date(),
        endDate:new Date(),
      }
    }
  }
}
</script>
<style>
.daterangepicker td.start-date.end-date {
  color: white;
}
.vue-daterange-picker {
  width: 100%;
}
.calendars {
  flex-wrap: nowrap;
}
</style>
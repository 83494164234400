<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="min-width: 85vw !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">

            <h5 class="modal-title mt-0">
              캠페인 상세
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div
                style="max-height: 70vh;overflow-y: scroll;padding:0"
            >
              <div class="card border border-primary" style="overflow: visible">
                <div class="card-header bg-transparent border-primary">
                  
                  <div style="display:flex ">
                    <h4 style="padding-top: 10px;padding-bottom: 20px;font-weight: bold">1. 기본 정보</h4>
                    <div style="flex-grow: 1"/>
                    <div>
                      <button type="button" class="btn btn-secondary waves-effect waves-light"
                              @click="mod"
                      >
                        정보 수정
                      </button>
                    </div>                    
                  </div>
                  
                  <table class="table">
                    <tbody>
                    <tr>
                      <th style="width: 120px" scope="row">광고주</th>
                      <td  style="height: 40px">
                            {{this.params.advertiserName}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">캠페인명</th>
                      <td style="height: 40px">
                        {{this.params.campaignName}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">연동 매체</th>
                      <td  style="height: 40px">
                        <span v-for="item in params.apiList"  style="margin-right: 8px;overflow: hidden">
                          <i class="fas fa-file-alt"></i> {{item.apiName}}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div class="card border border-primary" style="overflow: visible">
                <div class="card-header bg-transparent border-primary">

                  <div style="display:flex ">
                    <h4 style="padding-top: 10px;padding-bottom: 20px;font-weight: bold">2. 일별 데이터 기입</h4>
                  </div>

                  <table style="width: 100%">
                    <tbody>
                    <tr>
                      <th style="width: 140px" scope="row">
                        리포트 유형</th>
                      <td>
                        <my-select
                            v-if="type_list"
                            @change="chg_type"
                            v-model="params.mediaTypeTemplateId"
                            :items="type_list"
                            label="리포트 유형"
                            placeholder="리포트 유형"
                        ></my-select>
                      </td>
                    </tr>
                    <tr v-if="params.mediaTypeTemplateId">
                      <th style="width: 140px" scope="row">데이터 입력</th>
                      <td>
                        <div style="display: flex;font-size: 12px;padding:10px;">
                          <div class="form-check">
                            <input
                                v-model="input_style"
                                value="1"
                                class="form-check-input" type="radio" name="formRadios" id="formRadios1">
                            <label class="form-check-label" for="formRadios1">
                              파일로 입력
                            </label>
                          </div>
                          <div class="form-check" style="padding-left: 12px">
                            <input
                                v-model="input_style"
                                value="2"
                                class="form-check-input" type="radio" name="formRadios" id="formRadios2">
                            <label class="form-check-label" for="formRadios2">
                              직접 입력
                            </label>
                          </div>
                        </div>
                        <div v-if="input_style=='1'">
                          <div >
                            <div class="d-grid">
                              <div class="input-group mb-3">
                                <input type="file" ref="file" class="form-control" id="customFile"
                                       @change="handleFileUpload"
                                >
                              </div>

                              <div style="display: flex;width: 100%">

                                <button type="button"
                                        @click="download"
                                        class="btn btn-secondary waves-effect waves-light"
                                        style="margin-right: 8px;flex: 1"
                                >
                                  템플릿 다운로드
                                </button>

                                <button type="button"
                                        style="flex: 1"
                                        @click="upload"
                                        class="btn btn-primary waves-effect waves-light"
                                >
                                  파일로 입력
                                </button>

                              </div>

                            </div>
                          </div>


                        </div>
                      </td>
                    </tr>
                    <tr v-if="params.mediaTypeTemplateId && input_style != '1'">
                      <th style="width: 140px" scope="row">지난 데이터 불러오기</th>
                      <td style="padding-top: 5px">
                        <div style="display: flex">
                          <my-range-edit style="flex:1" v-model="range"/>
                          <div style="margin-left: 5px;width: 70px">
                            <button type="button" class="btn btn-primary waves-effect waves-light"
                                    @click="search_range"
                            >
                              불러오기
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div style="position: relative;"
                   v-if="input_style!='1'||report_data!=null&&report_data.length>0"
              >

                <my-table
                    :padding="20"
                    :head="getDataHead"
                    :tableData="report_data"
                    :format="getDataFormat"
                >
                  <template slot="_slot_custom" slot-scope="slotProps">

                    <div v-if="slotProps.col==0" style="position: relative">
                      <div
                          v-if="!slotProps.item[slotProps.item.length-1].lock"
                          style="position: absolute;top:7px;left:-23px;font-size: 13px">
                        <i class="fas fa-minus-circle" style="cursor: pointer" @click="remove(slotProps.item)"></i>
                      </div>
                    </div>

                    <div v-if="cols[slotProps.col].displayKey=='date'">
                      <span v-if="slotProps.item[slotProps.item.length-1].lock">
                        <span v-text="ymd(slotProps.item[slotProps.col].value.startDate)"/>
                      </span>
                      <my-date-edit
                          v-else
                          v-model="slotProps.item[slotProps.col].value"/>
                    </div>
                    <div v-else-if="cols[slotProps.col].displayKey=='mediaId'">
                        <span
                            v-if="slotProps.item[slotProps.col].value"
                            v-text="getMediaName(slotProps.item[slotProps.col].value)">
                        </span>
                      <select
                          v-else
                          class="form-select" aria-label="매체"
                          v-model="slotProps.item[slotProps.col].value"
                      >
                        <option
                            v-for="xselect in media_select_list"
                            :key="xselect.mediaId"
                            :value="xselect.mediaId">{{ xselect.channelNameKo }}</option>
                      </select>
                    </div>
                    <div v-else-if="cols[slotProps.col].displayKey=='adProductId'">
                        <span
                            v-if="slotProps.item[slotProps.col].value"
                            v-text="getAdProductName(slotProps.item[2].value, slotProps.item[slotProps.col].value)">
                        </span>
                      <select
                          v-else
                          class="form-select" aria-label="상품"
                          v-model="slotProps.item[slotProps.col].value"
                      >
                        <option
                            v-for="xselect in media_products[slotProps.item[2].value]"
                            :key="xselect.value"
                            :value="xselect.value">{{ xselect.text }}</option>
                      </select>
                    </div>

                    <div v-else-if="cols[slotProps.col].displayKey=='mediaType'">
                      {{slotProps.item[slotProps.col].value.toUpperCase()}}
                    </div>
                    <div v-else>
                      <my-input
                        number
                        :disabled="slotProps.item[slotProps.item.length-1].lock"
                        class="form-control" type="text" placeholder="" v-model="slotProps.item[slotProps.col].value"/>
                    </div>

                  </template>
                </my-table>

                <div
                    v-if="report_data"
                    style="position: absolute;top: 12px;left: 4px;font-size: 13px;">
                  <i class="fas fa-plus-circle" style="cursor: pointer" @click="add()"></i>
                </div>


                <div style="display: flex;padding: 10px" v-if="report_data != null && report_data.length>0">
                  <button type="button"
                          style="flex: 1"
                          @click="save"
                          class="btn btn-primary waves-effect waves-light block"
                  >
                    저장
                  </button>

                </div>
              </div>

            </div>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MyInput from "@/components/common/MyInput";
import MySelect from "@/components/common/MySelect";
import MyDateEdit from "@/common/MyDateEdit";
import MyTable from "@/components/common/MyTable";
import MyRangeEdit from "@/common/MyRangeEdit";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  components: {
    MyInput,
    MySelect,
    MyDateEdit,
    MyTable,
    MyRangeEdit
  },
  props: {
  },
  computed: {
    getDataFormat:function () {
      var a = []
      this.cols.forEach(el=>{
        a.push('_slot_custom')
      })
      return a
    },
    getDataHead:function () {
      var a = []
      this.cols.forEach(el=>{
        if(el.displayKey == 'date') {
          var name = el.displayName+'::@W::160'
          //console.log(name)
          a.push(name)
          //a.push(el.displayName)
        } else
          a.push(el.displayName)
      })
      return a
    },
    ...mapGetters({
      first_media_type:'first_media_type',
      main_media_type:'main_media_type',
      dashboard:'dashboard',
      media_products_info:'media_products_info'
    }),
  },
  mounted() {

    this.chk_media_products()

    this.type_list = []
    this.main_media_type.forEach(el=>{
      if(this.dashboard) {
        if(el.toUpperCase()=='DIGITAL') {
          this.type_list.push({
            text:el.toUpperCase(),
            value:el
          })
        }
      } else {
        this.type_list.push({
          text:el.toUpperCase(),
          value:el
        })
      }
    })

    // 파라메터 확인 (해당캠페인 바로 오픈)
    if(this.$route.query.campaignId) {
      this.open(this.$route.query.campaignId)
    }


    this.main_media_type.forEach(k=>{
      var url = '/api/media/listType/v2?mediaTypeTemplateId='
      var id = this.media_map[k.toLowerCase()]
      var url2 = url + id

      this.$http.get(url2)
          .then(({ data }) => {
            this.media_list_map[k] = data
          }).catch(error => {
        this.$eventBus.$emit('error', error.errorMessage)
      })
    })
    //console.log(this.first_media_type)
  },
  data () {
    return {
      select_media:null,
      media_products:{},
      media_list:null,

      cols:null,
      media_list_map:{},
      media_select_list:[],
      //report_data:null,
      type_list:null,
      input_style:1,
      report_data:[],
      masks: {
        input: 'YYYY-MM-DD',
      },
      media_map : {
        tv:1,
        ooh:2,
        digital:3,
        radio:4,
        ppl:5
      },
      tableData:null,
      page:0,
      pageParam:null,
      dlg_open:false,
      params: {
        campaignId: null,
        mediaTypeTemplateId:null
      },
      range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      },
    }
  },
  methods: {
    chk_media_products:function () {
      if(!this.media_products_info) {
        this.$store.commit('loading',  true)
      } else {
        this.select_media = this.media_products_info.select_media
        this.media_products = this.media_products_info.media_products
        this.media_list = this.media_products_info.media_list

        this.$store.commit('loading', false)
      }
    },
    chg_type:function () {

      if(!this.params.mediaTypeTemplateId) {
        return
      }

      this.input_style = '2'

      this.cols = null
      this.report_data = null

      if(this.input_style == '1') {
        // reset;;
        //this.report_data = []
      }
      else if(this.input_style == '2') {
        this.report_data = []
        this.cols = this.first_media_type.mediaActual[this.params.mediaTypeTemplateId]
        this.media_select_list = this.media_list_map[this.params.mediaTypeTemplateId]
      }
    },
    myValue:function (prop) {
      //{{myValue(slotProps)}}
      try {
        return prop.item[prop.col].value
      } catch (e) {}
      return ''
    },
    getMediaName:function(v) {
      var l = this.media_select_list
      for(var i in l) {
        var el = l[i]
        if(el.mediaId == v) {
          return el.channelNameKo
        }
      }
      return 'not registered'
    },
    getAdProductName:function(v1,v2) {

      var a = this.media_products[v1]
      var name = ''
      a.forEach(el=>{
        if(el.value == v2) {
          name = el.text
        }
      })
      return name
    },
    ymd:function (item) {
      return moment(item).format('YYYY-MM-DD')
    },
    remove:function (item) {
      var u = this.report_data
      for (var i = 0; i < u.length; i++) {
        var el = u[i]
        if (el == item) {
          u.splice(i, 1)
          break
        }
      }
    },
    add:function () {
      //this.report_data = []
      var row = []
      this.cols.forEach(el=>{
        if(el.displayKey=="mediaType") {
          row.push({value:this.params.mediaTypeTemplateId})
        }
        else if(el.displayKey=="date") {
          row.push({value: {
              startDate:new Date(),
              endDate:new Date()
            }
          })
        }
        else
          row.push(
            {value:null}
          )
      })
      this.report_data.push(row)
    },
    save:function () {
      if(this.report_data == null || this.report_data.length == 0) {
        this.$eventBus.$emit('error', '저장에 실패하였습니다.')
      }

      this.params['mediaDailyData'] = []
      this.report_data.forEach(line=>{

        var o = {}
        for(var i in line) {
          var line_value = line[i]

          var data = this.cols[i]
          if(!data)
            continue

          //console.log(this.cols[i])
          var k = this.cols[i].displayKey

          if(k == 'date') {
            o[k] = moment(line_value.value.startDate).format('YYYY-MM-DD')
          } else {
            o[k] = line_value.value
          }
        }
        this.params['mediaDailyData'].push(o)
      })

      this.$http.post('/api/campaign/uploadData', this.params)
          .then(({ data }) => {
            this.$eventBus.$emit('info', '데이터가 저장되었습니다.')
            this.dlg_open = false
          })
          .catch(error => {
            this.$eventBus.$emit('error', error.errorMessage)
          })
    },
    download:function () {
      if(!this.params.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '유형을 선택해주세요.')
        return
      }

      if(this.params.mediaTypeTemplateId=='digital') {
        window.open('/download/Digital_Present.xlsx')
      }
      else if(this.params.mediaTypeTemplateId=='ooh') {
        window.open('/download/OOH Present.xlsx')
      }
      else if(this.params.mediaTypeTemplateId=='radio') {
        window.open('/download/Radio Present.xlsx')
      }
      else if(this.params.mediaTypeTemplateId=='ppl') {
        window.open('/download/PPL Present.xlsx')
      }
      else if(this.params.mediaTypeTemplateId=='tv') {
        window.open('/download/TV Present.xlsx')
      }

    },
    parse_data:function (data) {

      this.cols = this.first_media_type.mediaActual[this.params.mediaTypeTemplateId]
      this.report_data = []

      this.media_select_list = this.media_list_map[this.params.mediaTypeTemplateId]

      // key => value 컨버팅
      data.forEach(el=>{
        var values = []
        this.cols.forEach(col=>{
          var k = col.displayKey
          if(k=='mediaId') {

            // 미디어를 목록에서 찾는다.
            //k = 'mediaName'
          } else if(k=='date') {
            //data['endDate'] = moment(this.range.end).format('YYYY-MM-DD')

            var o = moment(el[k]).toDate()
            //console.log(o)

            if(o=='Invalid Date') {
              values.push({value:{
                  startDate: new Date(),
                  endDate: new Date(),
                }})
            } else {
              values.push({value:{
                  startDate: o,
                  endDate: o
                }})
            }
            return
          }
          values.push({value:el[k]})
        })
        values.push({lock:true})
        this.report_data.push(values)
      })

    },
    search_range:function () {
      var ymd1 = moment(this.range.startDate).format('YYYY-MM-DD')
      var ymd2 = moment(this.range.endDate).format('YYYY-MM-DD')

      if(!this.params.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '유형을 선택해주세요.')
        return
      }

      var param = '&startDate='+ymd1
      param += '&endDate='+ymd2
      param += '&campaignId='+this.params.campaignId
      param += '&mediaTypeTemplateId='+this.media_map[this.params.mediaTypeTemplateId]
      var url = '/api/campaign/getData?'
      this.$http.get(url+param)
          .then(({ data }) => {

            var a = data.mediaDailyData

            if(a.length==0) {
              this.$eventBus.$emit('error', '불러온 데이터가 없습니다.')
              return
            }
            this.parse_data(a)
          })
          .catch(error => {
            console.log(error)
          })

    },
    handleFileUpload:function () {
      this.file = this.$refs.file.files[0];
    },
    upload:function () {
      if(!this.params.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '유형을 선택해주세요.')
        return
      }

      var param = ''
      //param += '&campaignId='+this.params.campaignId
      //param += '&mediaTypeTemplateId='+this.media_map[this.params.mediaTypeTemplateId]
      var url = '/api/campaign/uploadFile'

      this.$eventBus.$emit('loading', true)

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('campaignId', this.params.campaignId);
      formData.append('mediaTypeTemplateId', this.media_map[this.params.mediaTypeTemplateId]);

      this.$http.post(url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(({ data }) => {
            this.$eventBus.$emit('info', '파일을 불러왔습니다.')
            this.parse_data(data)

            this.$eventBus.$emit('loading', false)
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.errorMessage)

            this.$eventBus.$emit('loading', false)
          })

    },
    mod:function () {
      this.$emit('request_mod', this.params)
      this.dlg_open = false
    },
    select:function (item) {
      //this.dlg_open = false
      this.$emit('selected', item)
      this.$eventBus.$emit('info', '리포트가 추가되었습니다.')
    },
    report_type:function (item) {

      var m = ''
      this.first_media_type.adReportType.forEach(el=>{

        if(item.adReportTypeTemplateId == el.templateId) {
          m = el.displayName
          return
        }
      })

      return m

      //{{first_media_type.apiType[slotProps.item.adReportTypeTemplateId]}}
    },
    onpage:function (p) {
      this.page = p
      this.search()
    },
    search:function () {
      //http://localhost:8080/api/manage/list?advertiserId=1&apiName=%EA%B5%AC%EA%B8%80
      var url = '/api/manage/list?advertiserId='+this.params.advertiserId+'&apiName='+encodeURIComponent(this.params.apiName)
      url += '&page='+this.page

      this.$http.get(url)
          .then(({ data }) => {

            this.tableData = data.content
            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages

          })
          .catch(error => {
            console.log(error)
          })
    },
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

    },
    open:function (campaignId) {

      this.reset()
      this.params.campaignId = campaignId

      var url = '/api/campaign/detail?campaignId='+this.params.campaignId
      this.$http.get(url)
          .then(({ data }) => {
            this.params = data

            //console.log(data)
            this.params.mediaTypeTemplateId = null
            this.dlg_open = true

          }).catch(error => {
        this.$eventBus.$emit('error', error.errorMessage)
      })


      // load

    },
    onSubmit() {

    },
    reset() {
      this.report_data = null
      this.tableData=null
      this.params = {
        mediaTypeTemplateId:null,
        advertiserId: null,
        apiName: '',
      }
    }
  },
  watch: {
    media_products_info:function () {
      this.chk_media_products()
    },
    input_style:function () {

      if(!this.params.mediaTypeTemplateId) {
        this.$eventBus.$emit('error', '유형을 선택해주세요.')
        return
      }

      this.report_data = null
      if(this.input_style == '2') {
        this.cols = this.first_media_type.mediaActual[this.params.mediaTypeTemplateId]
        this.media_select_list = this.media_list_map[this.params.mediaTypeTemplateId]
        this.report_data = []
      }
    }
  }
}
</script>

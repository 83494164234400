<template>
  <div v-if="rootList" :key="flag" class="mbg" style="position: relative">
    <div style="position: absolute;right: 0;top:0;padding-top: 20px;padding-right: 20px">
      <img src="/img/x.svg" style="width: 16px" class="cursor-pointer" @click="close">
    </div>
    <div style="font-size: 30px;letter-spacing: -1px;font-weight: bold;line-height: 99%;padding: 36px 0 20px 36px;">
      Media<br/>
      Starium
    </div>
    <div v-for="item in rootList" :key="item.name" style="padding: 0 20px;">
      <div :class="item.selected?'sub-title white mselected':'sub-title white'"
           v-on:mouseover="focusin_menu(item)"
      >
        <div
            v-on:mouseup="clk(item)"
        >
          <router-link
              style="color: white"
              :to="item.path"
          >
        <span :style="item.bold?'font-weight:bold':''"
        >
          <img v-if="item.ico && !item.hide" :src="item.ico" style="width: 20px;margin-right: 11px;padding-bottom: 5px;"/>
          {{item.name}}
        </span>
          </router-link>
        </div>
      </div>
      <sub-menu
          @focusin_menu="focusin_menu"
          @close="close"
          :level="0"
          v-if="item.selected && item.subMenu && item.subMenu.length>0" style="padding-top: 10px" :list="item.subMenu" />
    </div>
  </div>
</template>
<script>
import subMenu from "@/nav/subMenu";
import { MENU_LIST } from '@/constants'
import {mapGetters} from "vuex";

export default {
  name: 'snb',
  components:{
    subMenu
  },
  computed: {
    ...mapGetters({
      user:'user',
      mediacnt:'mediacnt'
    }),
  },
  data() {
    return {
      menuList: MENU_LIST,
      rootList: null,
      currentRootList: MENU_LIST,
      currentPath: '',
      flag:0
    }
  },
  methods: {
    // 순환 참조를 없앤다.
    resetTree : function (tree) {
      for(var el of tree) {
        el.parent = null
        if(el.subMenu) {
          this.resetTree(el.subMenu)
        }
      }
    },
    clk:function (item) {
      this.$router.push({path:item.path})
      this.$emit('close')
    },
    close:function () {
      this.$emit('close')
    },
    initMenu:function (tmpList) {

      //console.log(this.user.userinfo.roleId)
      var list = this.menuList

      if(this.user.userinfo.roleId==1) {

        list.forEach(el=>{
          if(el.code == 'SETTING') {
            list.splice(list.length-1, 1)
          }
        })
      }

      var redrawing = false
      if(tmpList != null && tmpList != undefined) {
        list = tmpList
        redrawing = true
      }

      var resetTree = function (tree) {
        for(var el of tree) {
          el.selected = false
          if(el.subMenu) {
            resetTree(el.subMenu)
          }
        }
      }

      if(!redrawing)
        resetTree(list)

      // 트리를 통해 메뉴를 찾는다.
      var findTree = function (tree, path, parent) {

        for(var el of tree) {

          if(el.path==path) {

            // 찾았다 (나의부모)
            el['parent'] = parent
            return el
          } else if(el.path_alt) {

            var a = el.path_alt.find(element => element == path)
            if(a) {
              el['parent'] = parent
              return el
            }
          }

          if(el.subMenu) {
            el.parent = parent
            var o = findTree(el.subMenu, path, el)
            if(o) {
              // 찾았다.
              return o
            }
          }
        }

        return null
      }

      this.rootList = this.menuList
      var o = findTree(this.menuList, this.currentPath, null)

      if(o) {
        // rootList
        // 부모찾아
        var findRoot = function (node) {
          node.selected = true

          if(!node.parent) {
            return node
          }
          node.parent.selected = true
          return findRoot(node.parent)
        }
        findRoot(o)
      }
    },
    focusin_menu:function (item) {
      //console.log(this.currentRootList)

      return
      /*
      this.currentPath = item.path
      var a = JSON.stringify(this.currentRootList)
      this.flag++
      */
    }
  },
  mounted() {

    this.$eventBus.$on('menu_close', this.close)

    this.currentPath = this.$route.path
    this.initMenu()

    this.resetTree(this.rootList)
    this.currentRootList = this.$deepCopy(this.rootList)

    this.flag++
  },
  watch: {
    '$route' (to) {

      this.currentPath = to.path
      this.initMenu()

      this.resetTree(this.rootList)
      this.currentRootList = this.$deepCopy(this.rootList)

      this.flag++
    }
  }
}
</script>

<style scoped>
.mselected {
  background-color: #4f6c96;
  border-radius: 12px;
}
.sub-title {
  display: flex;
  justify-content: flex-start;
  padding: 17px 20px;
  color: #333;
  font-size: 19px;
}
.snb ul {
  list-style: none;
}
.snb ul li {
  margin: 5px 0;
  padding: 0 8px;
}
.snb ul li:hover {
  color: #007bff;
  cursor: pointer;
}
.nav-item a {
  padding-left: 12px;
  display: block;
  color: #333;
  font-size: 18px;
  border-left: 3px solid #fff;
}
.nav-item a:hover {
  color: #007bff;
  text-decoration: none;
}
.nav-active a {
  color: #007bff;
  border-left: 3px solid #007bff;
}
</style>
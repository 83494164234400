import Vue from 'vue'
import VueRouter from 'vue-router'
import { sessionManagement } from './sessionManagement'
Vue.use(VueRouter)

import Login from '@/pages/login/login'
import Intro from '@/pages/intro/intro'
import History from '@/pages/history/history'
import Present from "@/pages/present/present";
import Forecast from '@/pages/forecast/forecast'
import PresentDB from '@/pages/setting/presentDB/list'
import HistoryDB from '@/pages/setting/historyDB/upload'
import Advertiser from '@/pages/setting/system/advertiser/list'
import MediaProduct from '@/pages/setting/system/media/list'
import ApiList from '@/pages/setting/system/mapi/list'
import ApiRegister from '@/pages/setting/system/mapi/register_page'


const index = [
    {
        // /search/screens -> /search?q=screens
        path: '/',
        redirect: to => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/intro' }
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/intro',
        name: 'intro',
        component: Intro
    },
    {
        path: '/history',
        name: 'history',
        component: History
    },
    {
        path: '/present',
        name: 'present',
        component: Present
    },
    {
        path: '/forecast',
        name: 'forecast',
        component: Forecast
    },
    {
        path: '/setting/presentDB',
        name: 'presentDB',
        component: PresentDB
    },
    {
        path: '/setting/historyDB',
        name: 'historyDB',
        component: HistoryDB,
        meta: { parent: '/setting/presentDB' }
    },
    {
        path: '/setting/system/advertiser',
        name: 'advertiser',
        component: Advertiser
    },
    {
        path: '/setting/system/mapi',
        name: 'api_list',
        component: ApiList
    },
    {
        path: '/setting/system/mapi/register',
        name: 'api_register',
        component: ApiRegister
    },
    {
        path: '/setting/system/mediaProduct',
        name: 'mediaProduct',
        component: MediaProduct
    },
]

const router = new VueRouter({
    mode: 'history',
    routes: index
})

router.beforeEach(sessionManagement)

export default router
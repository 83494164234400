<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              {{ title }}
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body"
          >
            {{ msg }}
          </div>

          <div class="modal-footer">
            <button type="button"
                    @click="dlg_open=false"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  components: {
  },
  props: {
  },
  data () {
    return {
      dlg_open:false,
      title:'',
      msg:''
    }
  },
  methods: {
    open:function (title, msg) {
      this.dlg_open = true

      this.title = title
      this.msg = msg
    },
  },
  watch: {
  }
}
</script>

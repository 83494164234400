<template>
  <div>
    <gnb class="mbg">
      <template  slot="desc">
        API 연동 가능한 매체를 등록하고 관리합니다.
      </template>
    </gnb>

    <div class="mcontent" style="padding:50px">

      <table class="table" style="width: 100%">
        <tbody>
        <tr v-if="list_advertisers">
          <th scope="row" style="width: 100px">광고주명</th>
          <td>
            <select class="form-select" aria-label="광고주"
                    v-model="params.advertiserId"
            >
              <option :value="null">광고주 선택</option>
              <option
                  v-for="item in list_advertisers"
                  :key="item.advertiserId"
                  :value="item.advertiserId">{{ item.advertiserName }}</option>
            </select>
          </td>
        </tr>

        <tr>
          <th scope="row">매체명</th>
          <td>
            <select class="form-select" aria-label="매체 종류"
                    v-model="params.apiTypeTemplateId"
            >
              <option :value="null">연동 매체 선택</option>
              <option
                  v-for="item in first_media_type.apiType"
                  :key="item.templateId"
                  :value="item.templateId">{{ item.displayName }}</option>
            </select>

          </td>
        </tr>

        <tr>
          <th scope="row">리포트 명칭</th>
          <td>
            <input class="form-control" type="text" placeholder="리포트 명칭을 입력하세요"
                   v-model="params.apiName"
            >
          </td>
        </tr>
        <tr>
          <th scope="row">집행기간</th>
          <td>
            <my-range-edit style="width: 100%" v-model="range"/>
          </td>
        </tr>
        <tr v-if="list_adReportType && !sub_list">
          <th scope="row">리포팅 수준
          </th>
          <td>
            <my-select
                placeholder="연동 매체 선택"
                :key="params.apiTypeTemplateId"
                label="종류"
                :hide_key="50==params.apiTypeTemplateId?53:null"
                @change="chg_adReportType"
                :items="list_adReportType"
                v-model="params.adReportTypeTemplateId"
            >
            </my-select>

          </td>
        </tr>

        <tr
            v-if="!sub_list"
            class="no-border"
        >
          <th scope="row">
            계정 ID
          </th>
          <td>
            <div style="display: flex">
              <div style="flex: 1">
                <input class="form-control" type="text" placeholder="계정 입력"
                       v-model="params.accountId"
                >
              </div>
              <div style="padding-left: 3px">
                <button type="button" class="btn btn-primary waves-effect"
                        @click="search"
                >
                  조회
                </button>
              </div>
              <div style="padding-left: 3px" v-if="auth_btn">
                <button type="button" class="btn btn-primary waves-effect"
                        @click="clk_auth"
                >
                  인증
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr
            v-if="sub_list"
            class="no-border"
        >
          <th scope="row">트래커 ID</th>
          <td>
            <div style="display: flex">
              <div style="flex: 1">
                <input class="form-control" type="text" placeholder="트래커 ID"
                       v-model="params.trackerId"
                >
              </div>
              <div style="padding-left: 3px">
                <button type="button" class="btn btn-primary waves-effect"
                        @click="search2"
                >
                  조회
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="list_kochava && list_kochava.length>0">
          <th scope="row">매체</th>
          <td>
            <table>
              <tr v-for="xitem in list_kochava">
                <td>
                  <input class="form-check-input" type="checkbox" v-model="params.kochavaSelectedList" :value="xitem.kochavaId">
                  {{xitem.kochavaMediaName}}
                </td>
              </tr>
            </table>

          </td>
        </tr>

        <tr v-if="!sub_list && data_campaign != null && data_campaign.length>0">
          <th
              style="min-width: 120px"
              scope="row">캠페인</th>
          <td>
            <my-select class="form-select" label="캠페인"
                       @change="chg_campaign"
                       :items="data_campaign"
                       v-model="params.adCampaignId"
            >
            </my-select>

          </td>
        </tr>
        <tr v-if="!sub_list && data_adgrp != null && data_adgrp.length>0">
          <th scope="row">광고 그룹
            <i class="fas fa-info-circle" v-title="'복수의 광고 그룹을 선택하면, 합산 값을 리포팅함(최대 10개 그룹)'"></i>
          </th>
          <td>

            <my-multi-select
                v-model="selected_ad_grp"
                :list="data_adgrp"
                v="text"
                k="value"
                label="광고주를 선택해주세요. (중복선택가능)"
            />

          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-light waves-effect"
              @click="cancel"
      >
        취소
      </button>
      <button type="button"
              @click="onSubmit"
              class="btn btn-primary waves-effect waves-light"
      >
        등록 및 수정
      </button>

    </div>

  </div>
</template>

<script>
import MyMultiSelect from "@/components/common/MyMultiSelect";
import MyRangeEdit from "@/common/MyRangeEdit";
import MySelect from "@/components/common/MySelect";
import myError from "@/common/myError"
import moment from "moment";
import {mapGetters} from "vuex";
import Gnb from "@/nav/gnb";
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
    Gnb,
    MySelect,
    MyRangeEdit,
    MyMultiSelect
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({
        first_media_type:'first_media_type'
    }),
  },
  mounted() {
    this.loadFilter()


    this.list_adReportType = []
    this.first_media_type.adReportType.forEach(el=>{
      var o = JSON.parse(JSON.stringify(el))
      o.text = o['displayName']
      o.value = o['templateId']
      this.list_adReportType.push(o)
    })
  },
  data () {
    return {
      sub_list: null,
      selected_ad_grp:null,
      list_kochava:null,
      list_adReportType:null,
      list_advertisers:null,
      available:false,
      data_campaign:null,
      data_adgrp:null,
      auth_btn:false,
      auth_url:'',
      pause_flag:false,
      /*
"(카카오 인증이 안되면, http://ga-test.snadx.com/:8091로그인 상태에서 브라우저에 http://ga-test.snadx.com:8091/api/manage/kakao/auth 를 입력한다, 카카오 로긴 정보 - 계정ID: rnd.dmc3@gmail.com 계정PW: epdlxjakzpxld@ )
카카오 인증 -  캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=282779&apiTypeTemplateId=49&adReportTypeTemplateId=54
카카오 인증 -  광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=282779&apiTypeTemplateId=49&adReportTypeTemplateId=55&campaignId=727158
네이버 인증 - 캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?apiTypeTemplateId=50&adReportTypeTemplateId=54
네이버 인증 - 광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?apiTypeTemplateId=50&adReportTypeTemplateId=55&campaignId=1372873
코차바 인증: Get http://ga-test.snadx.com:8091/api/manage/validate/kochava?trackerId=13034078158
구글 인증 - 캠페인: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=1566004847&apiTypeTemplateId=50&adReportTypeTemplateId=54
구글인증- 광고그룹: Get http://ga-test.snadx.com:8091/api/manage/validate?accountId=1566004847&apiTypeTemplateId=50&adReportTypeTemplateId=55&campaignId=1372032
(구글 이증이 안되면, http://ga-test.snadx.com/:8091로그인 상태에서 브라우저에 http://ga-test.snadx.com:8091/api/manage/google/auth 를 입력한다, 구글 로긴 정보 - 계정ID: rnd.dmc3@gmail.com 계정PW: epdlxjakzpxld! )"
*/
      c:false,
      api_map:{
        '49':{
          // kakako 비즈보드
          show_btn:true,
          auth_url:'/api/manage/kakao/auth',
          //accountId:'282779'
        },
        '50':{
          // "네이버(NOSP)"
        },
        '51':{
          // 구글
          show_btn:true,
          auth_url:'/api/manage/google/auth',
          //accountId:'1566004847'
        },
        '52':{
          // 코차바
          url:'/api/manage/validate/kochava?',
              //'trackerId=13034078158',
          sub_list:true
        },
        '56':{
          // 카카오 모먼트
          show_btn:true,
          auth_url:'/api/manage/kakao/auth',
          //accountId:'282779'
        },
        '57':{
          // 구글 uac
          show_btn:true,
          auth_url:'/api/manage/google/auth',
          //accountId:'1566004847'
        },
        '58':{
          // 구글 트루뷰
          show_btn:true,
          auth_url:'/api/manage/google/auth',
          //accountId:'1566004847'
        },
        '59':{
          // 구글 범퍼
          show_btn:true,
          auth_url:'/api/manage/google/auth',
          //accountId:'1566004847'
        },
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        kochavaSelectedList:[],
        trackerId:null,
        apiTypeTemplateId:null,
        adCampaignId:null,
        adGroupIdList:null,
        adReportTypeTemplateId:null,
        advertiserId: null,
      },
      businessOptions: [],
    }
  },
  methods: {
    cancel:function () {
      history.back()
    },
    chg_adReportType:function () {

      if(this.pause_flag) {
        this.pause_flag = false
        return
      }

      this.data_campaign=null
      this.data_adgrp=null
      this.list_kochava=null

      this.params.adCampaignId = null
      this.params.adGroupIdList = null
    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {
            this.list_advertisers = data
          })
    },
    get_search_url:function () {
      // 조회 주소 생성
      var v = this.params.apiTypeTemplateId.toString()
      var o = this.api_map[v]
      var url = ''

      if(!this.params.adReportTypeTemplateId) {
        this.$eventBus.$emit('error', '리포팅 수준을 선택해주세요.')
        return
      }

      if(o.url)
        url = o.url
      else {
        url = '/api/manage/validate?'

        if(this.params.accountId) {
          url += '&accountId='+this.params.accountId
        }
        url += '&apiTypeTemplateId='+v
      }

      return url
    },
    chg_campaign:function () {

      if(this.params.adReportTypeTemplateId.toString() == '54')
        return

      var url = this.get_search_url()
      url += '&adReportTypeTemplateId=55'

      if(this.params.adCampaignId) {
        url += '&adCampaignId='+this.params.adCampaignId
      }

      this.data_adgrp=null

      this.$http.get(url)
          .then(({ data }) => {

            if(this.params.adReportTypeTemplateId.toString() == '54' || this.params.adReportTypeTemplateId.toString() == '55') {
              this.data_adgrp = data['adList']
              this.data_adgrp.forEach(el=>{
                el.value = el.id
                el.text = el.name
              })
            }
          }).catch(err=>{
            this.$eventBus.$emit('error',err.errorMessage)
          })
    },
    search2:function () {
      // 조회 주소 생성
      var v = this.params.apiTypeTemplateId.toString()
      var o = this.api_map[v]
      var url = o.url

      if(!this.params.trackerId) {
        this.$eventBus.$emit('error', '트래커 ID를 입력해주세요.')
        return
      }

      url += 'trackerId=' + this.params.trackerId

      this.params.kochavaSelectedList = []
      this.list_kochava = null
      this.$http.get(url)
          .then(({ data }) => {

            this.list_kochava = data
          }).catch(err=>{
        this.$eventBus.$emit('error',err.errorMessage)
      })
    },
    search:function () {

      var url = this.get_search_url()
      if(this.params.adReportTypeTemplateId.toString() == '53' || this.params.adReportTypeTemplateId.toString() == '54')
        url += '&adReportTypeTemplateId='+this.params.adReportTypeTemplateId
      else
        url += '&adReportTypeTemplateId=54'

      this.data_campaign=null
      this.data_adgrp=null

      this.params.adCampaignId = null
      this.params.adGroupIdList = null

      this.$http.get(url)
          .then(({ data }) => {
            if(this.params.adReportTypeTemplateId.toString() == '54' || this.params.adReportTypeTemplateId.toString() == '55') {
              this.data_campaign = data['adList']
              this.data_campaign.forEach(el=>{
                el.value = el.id
                el.text = el.name
              })
            }
          }).catch(err=>{
        this.$eventBus.$emit('error',err.errorMessage)
      })
    },
    clk_auth:function () {
      window.open(this.auth_url)
    },
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

      // load
      this.init()
    },
    init:function () {
    },
    open_mod:function (id) {
      this.pause_flag = true
      this.reset()
      this.params.apiId = id
      this.$http.get('/api/manage/detail?apiId='+id)
          .then(({ data }) => {
            this.params = data

            var o = {
              startDate:new Date(),
              endDate:new Date(),
            }

            try {
              var a = moment(this.params.startDate).toDate()
              if(a=='Invalid date')
                throw 'e'
              o["startDate"] = a
            } catch (e) {}
            try {
              var a = moment(this.params.endDate).toDate()
              if(a=='Invalid date')
                throw 'e'
              o["endDate"] = a
            } catch (e) {}

            this.range = o

            /*
                  var startDate = moment(this.range.startDate).format('YYYY-MM-DD')
      var endDate = moment(this.range.endDate).format('YYYY-MM-DD')
      this.params.startDate = startDate
      this.params.endDate = endDate
             */


            // 리스트 가져올때까지 텀을 둔다.
            var that = this
            setTimeout(function (){
              that.dlg_open = true
            },2000)


            // 코차바
            if(this.params.trackerId) {

              var v = this.params.apiTypeTemplateId.toString()
              var o = this.api_map[v]
              var url = o.url
              url += 'trackerId=' + this.params.trackerId

              this.params.kochavaSelectedList = []

              this.params.kochavaList.forEach(el=>{
                this.params.kochavaSelectedList.push(el.kochavaId)
              })

              this.list_kochava = null
              this.$http.get(url)
                  .then(({ data }) => {
                    this.list_kochava = data
                  })
              return
            }


            if (this.params.adCampaignId) {
              // 캠페인 정보가 선택된 상태인가?
              var url = this.get_search_url()
              url += '&adReportTypeTemplateId=54'

              this.$http.get(url)
                  .then(({data}) => {
                      this.data_campaign = data['adList']
                      this.data_campaign.forEach(el => {
                        el.value = el.id
                        el.text = el.name
                      })
                  })
            }

            if (this.params.adGroupIdList) {

              var url = this.get_search_url()
              url += '&adReportTypeTemplateId=55'

              if(this.params.adCampaignId) {
                url += '&adCampaignId='+this.params.adCampaignId
              }

              this.$http.get(url)
                  .then(({data}) => {
                    this.data_adgrp = data['adList']
                    this.data_adgrp.forEach(el=>{
                      el.value = el.id
                      el.text = el.name
                    })

                    // 현재 선택된 그룹 적용
                    this.$nextTick(() => {
                      this.$nextTick(() => {
                        var b = this.params.adGroupIdList.split(',')
                        var a = []
                        b.forEach(el=>{
                          try {
                            if(el == 'undefined' || el == 'null' || el == undefined || el == null) {
                              return
                            }
                            var v = parseInt(el)
                            if(v == NaN || v == undefined || v == null)
                              return

                            if(!a.includes(v)) {
                              a.push(v)
                            }
                          } catch (e) {}
                        })

                        // Select2
                        this.selected_ad_grp = a
                        console.log(a)
                      })

                    })

                    //console.log(this.params.adGroupIdList)
                    //$('#mySelect2').val(['1', '2']);
                  })
            }
          })
    },
    open:function () {
      this.params.apiId = null
      this.reset()
      this.dlg_open = true
    },
    checkValidation:function (modification) {

      var that = this
      this.resetMyError()

      return new Promise(function(resolve, reject) {

        if(!that.params.apiName) {
          that.setMyError('이름을 입력해주세요.')
          throw 'e'
        }

        resolve()

      })
    },
    onSubmit_kochava() {

      var startDate = moment(this.range.startDate).format('YYYY-MM-DD')
      var endDate = moment(this.range.endDate).format('YYYY-MM-DD')

      this.params.startDate = startDate
      this.params.endDate = endDate

      var modification = false
      if (this.params.apiId)
        modification = true

      // validation
      this.checkValidation(modification)
          .then(()=>{

            this.params.kochavaList = []
            this.params.kochavaSelectedList.forEach(el=>{
              this.params.kochavaList.push({kochavaId:el})
            })


            if (!this.params.apiId) {

              this.$http.post('/api/manage/add', this.params)
                  .then(() => {

                    this.$eventBus.$emit('info', '등록완료 되었습니다.')
                    this.dlg_open = false

                    this.$emit('updated')
                  })
                  .catch(error => {
                    //this.toastr('warning', error.response.data.errorMessage)
                    this.$eventBus.$emit('error',error.errorMessage)
                  })

            } else {

              this.$http.put('/api/manage/modify', this.params)
                  .then(() => {

                    this.$eventBus.$emit('info', '수정완료 되었습니다.')
                    this.dlg_open = false

                    this.$emit('updated')
                  })
                  .catch(error => {
                    //this.toastr('warning', error.response.data.errorMessage)
                    this.$eventBus.$emit('error',error.errorMessage)
                  })

            }

          })
          .catch(()=>{
            this.whenErrorFalseAndAlert()
          })
    },
    onSubmit() {

      // kochava
      if(this.sub_list) {
        this.onSubmit_kochava()
        return
      }

      var startDate = moment(this.range.startDate).format('YYYY-MM-DD')
      var endDate = moment(this.range.endDate).format('YYYY-MM-DD')
      this.params.startDate = startDate
      this.params.endDate = endDate


      try {

        var advs = []
        try {
          // param
          var v = this.selected_ad_grp
          v.forEach(el=>{
            advs.push(el)
          })
        } catch (e){}


        var cnt = 0
        var ad_grp = ''
        advs.forEach(el=>{
          if(ad_grp != '')
            ad_grp += ','
          ad_grp += el
          cnt++
        })

        this.params.adGroupIdList = ad_grp
        if(cnt > 10) {
          this.$eventBus.$emit('error', '광고 그룹은 10개까지만 추가 가능합니다.')
          return
        }
      } catch (e) {}



      this.available = false

      // ok
      if(this.params.adReportTypeTemplateId.toString() == '53') {
        this.available = true
      }
      else if(this.params.adReportTypeTemplateId.toString() == '54') {
        if(!this.params.adCampaignId) {
          // fail
        } else {
          this.available = true
        }
      }
      else if(this.params.adReportTypeTemplateId.toString() == '55') {
        if(!this.params.adGroupIdList) {
          // fail
        } else {
          this.available = true
        }
      }

      if(!this.available) {
        this.$eventBus.$emit('error', '캠페인 속성이 선택되지 않았습니다.')
        return
      } else {
        // ok
        //return
      }

      var modification = false
      if (this.params.apiId)
        modification = true

      // validation
      this.checkValidation(modification)
        .then(()=>{

          if (!this.params.apiId) {

            this.$http.post('/api/manage/add', this.params)
                .then(() => {

                  this.$eventBus.$emit('info', '등록완료 되었습니다.')
                  this.dlg_open = false

                  this.$emit('updated')
                })
                .catch(error => {
                  //this.toastr('warning', error.response.data.errorMessage)
                  this.$eventBus.$emit('error',error.errorMessage)
                })

          } else {

            this.$http.put('/api/manage/modify', this.params)
                .then(() => {

                  this.$eventBus.$emit('info', '수정완료 되었습니다.')
                  this.dlg_open = false

                  this.$emit('updated')
                })
                .catch(error => {
                  //this.toastr('warning', error.response.data.errorMessage)
                  this.$eventBus.$emit('error',error.errorMessage)
                })

          }

        })
        .catch(()=>{
          this.whenErrorFalseAndAlert()
        })
    },
    reset() {
      this.params = {
        advertiserId: this.advertiserId,
        advertiserName: '',
        kochavaSelectedList:[],
        list_kochava:null
      }
      this.selected_ad_grp = null
      this.sub_list = null
      this.list_kochava = null
    }
  },
  watch: {
    'params.apiTypeTemplateId' : function () {

      // api_map
      if(!this.params.apiTypeTemplateId)
        return

      var v = this.params.apiTypeTemplateId.toString()
      var o = this.api_map[v]
      this.sub_list = o.sub_list

      if(this.api_map[v].show_btn) {
        this.auth_btn = true
        this.auth_url = this.api_map[v].auth_url
      } else {
        this.auth_btn = false
      }

      if(this.api_map[v].accountId)
        this.params.accountId = this.api_map[v].accountId

      //else
        //this.params.accountId = ''
    }
  }
}
</script>

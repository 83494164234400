<template>
<div style="display: flex">

  <date-range-picker
      ref="picker"
      :showDropdowns="true"
      opens="right"
      :localeData="{
    direction: 'ltr',
    format: 'yyyy-mm-dd',
    separator: ' - ',
    applyLabel: '적용',
    cancelLabel: '취소',
    weekLabel: 'W',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월' ],
    firstDay: 0
}"
      :toggle="dlg_open"
      v-model="range"
      :singleDatePicker="false"
      :autoApply="false"
      :linkedCalendars="false"
  >
    <template #input="picker"`>

      <div style="position: relative;width: 100%">
        <input type="text" label="기간 입력 (YYYY-MM-DD ~ YYYY-MM-DD)"
                  v-model="myrange"
                  style="padding-left: 30px;border: 0;width: 100%"
               @keydown.enter="cal_updated"
               @focusout="cal_updated"
               class="mcal"
        />
        <div style="position:absolute; left: 2px;top:2px;cursor: pointer"
        >
          <i style="font-size: 14px" class="fas fa-calendar-alt"></i>
        </div>

      </div>

    </template>
  </date-range-picker>

</div>
</template>
<script>
import MyInput from "@/components/common/MyInput";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
export default {
  props:{
    value:{
      type:Object,
      default:null
    }
  },
  mounted() {
    if(this.value)
      this.range = this.value

    this.initRange()
  },
  computed: {
  },
  components: {
    DateRangePicker,
  },
  watch: {
    myrange:function () {
      //this.initRange2()
    },
    range:function () {
      this.initRange()
      this.$emit('input', this.range)
    }
  },
  methods: {
    cal_updated:function () {
      this.initRange2()
    },
    getRange:function () {
      this.initRange()
      return this.range
    },
    initRange:function () {
      var data = {}
      try {
        data['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
        data['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')
      } catch (e) {
      }
      if(data['startDate'] && data['endDate'])
        this.myrange = data['startDate'] + ' ~ ' + data['endDate']
    },
    initRange2:function () {

      var data = {}
      try {
        var range = this.myrange.split('~')
        data['startDate'] = moment(range[0]).toDate()
        data['endDate'] = moment(range[1]).toDate()

        if(data['starDate'] == 'Invalid Date' || data['endDate'] == 'Invalid Date')
          throw 'e'

      } catch (e) {
        this.range = {
          startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
          endDate: new Date(),
        }
        return
      }
      this.range = {
        startDate:data['startDate'],
        endDate:data['endDate']
      }
    },
    showCalendar:function () {

      //
      var data = {}
      try {
        var range = this.myrange.split('~')
        data['startDate'] = moment(range[0]).toDate()
        data['endDate'] = moment(range[1]).toDate()

        if(data['starDate'] == 'Invalid Date' || data['endDate'] == 'Invalid Date')
          throw 'e'

      } catch (e) {
        this.range = {
          startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
          endDate: new Date(),
        }
        this.initRange()
        this.dlg_open = true
        return
      }
      this.range = {
        startDate:data['startDate'],
        endDate:data['endDate']
      }
      this.dlg_open=true
    },
    updateValues:function (v) {
      this.dlg_open = false

      // changed
      this.initRange()
    },
    ok:function () {
    }
  },
  data() {
    return {
      myrange:'',
      dlg_open:false,
      range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      },
    }
  }
}
</script>
<style scoped>
.mcal:focus {
  outline: none;
}
</style>
<template>
<div>
  <div style="font-size: 14px;padding:4px;cursor: pointer" @click="open=true">
    <i class="fas fa-cog align-self-center"></i>
  </div>

  <div
      v-if="open"
      class="modal fade show"
      style="display: block;background-color:#00000055;"
  >

    <div class="modal-dialog"
         style=";min-width: 500px !important;"
    >
      <div class="modal-content"
      >
        <div class="modal-header">
          <h5 class="modal-title mt-0">
            데이터 그룹 칼럼 설정
          </h5>
          <button type="button" class="btn-close"
                  @click="open=false"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body" style="max-height: 70vh !important;overflow-y: auto;padding: 20px;">

          <div style="font-size: 1em;padding-bottom: 10px">각 그룹을 선택해서 세부 설정을 진행해주세요.</div>

          <div style="width: 100%;display: flex">
            <div style="display: flex;flex-direction: column;flex: 1;"
                 v-for="(item,idx) in headSplit"
            >
              <button
                  :style="';color:white;font-weight:bold;background-color: '+item.clr"
                  type="button" class="btn btn-light waves-effect">
                {{item.name}}
              </button>
              <my-sort-edit
                  v-show="myheader"
                  :mykey="mykey + '_'+idx"
                  :header="myheader[idx]"
                  @init="on_init"
                  style="align-self: center"></my-sort-edit>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
</template>
<script>
import MySortEdit from "@/common/MySortEdit";
export default {
  props:{
    headSplit:{
      type:Array,
      default:null
    },
    header: {
      default:null,
      type:Array
    },
    mykey:{
      default:'myset',
      type:String
    }
  },
  mounted() {
    this.init()
  },
  computed: {
  },
  components: {
    MySortEdit
  },
  watch: {
  },
  methods: {
    init:function () {

      this.myheader = []
      let idx = 0
      this.headSplit.forEach(el=>{
        let cnt = el.cnt
        let a = []
        for(let i=0;i<cnt;i++) {
          let o = this.header[idx++]
          a.push(o)
        }
        this.myheader.push(a)
      })

      let len = this.headSplit.length
      let a = []
      for(let i = 0;i<len;i++) {
        let b = this.load(i, this.myheader)
        b.forEach(el=>{
          a.push(el)
        })
      }

      idx = 0
      let grp = []
      for(let i=0;i<this.headSplit.length;i++) {
        let o = this.headSplit[i]
        //var _g = []
        let cnt = 0
        for(let j=0;j<o.cnt;j++) {
          let o2 = a[idx++]
          if(o2.view)
            cnt++
        }
        grp.push(cnt)
      }

      this.$emit('grp', grp)

      // 임시
      this.$emit('init', a)
    },
    load:function (idx, rawdata2) {
      var k = this.prefix + this.mykey+'_'+idx

      var data = localStorage.getItem(k)
      var ja = JSON.parse(data)

      var rawdata = rawdata2[idx]
      var myvalue = null

      // 넘어온 raw data와 비교함
      if(ja) {
        //
        for(let i in rawdata) {
          let el = rawdata[i]
          let found = false
          for(let i2 in ja) {
            let el2 = ja[i2]
            if(el.id == el2.id) {
              found = true
              break
            }
          }
          //
          if(!found) {
            ja.push(el)
          }
        }

        // myvalue, rawdata
        // myvalue 에서 현재 rawdata 에 존재하지 않는 애들을 삭제한다.
        for(let i=ja.length-1;i>=0;i--) {
          let el = ja[i]

          let found = false
          for(let i2 in rawdata) {
            let el2 = rawdata[i2]
            if(el.id == el2.id) {
              found = true
              break
            }
          }
          if(!found) {
            ja.splice(i,1)
          }
        }

        myvalue = ja
      } else {
        myvalue = rawdata
        myvalue.forEach(el=>{
          el.view = true
        })
      }

      myvalue.forEach(el=>{
        var n = el.name.indexOf('::')
        if(n>0) {
          el.name = el.name.substring(0,n)
        }
      })

      //this.$emit('init', myvalue)
      return myvalue
    },
    on_init:function () {
      this.init()
    }
  },
  data() {
    return {
      prefix:'msev2_',
      myheader:null,
      open:false,
    }
  },
}
</script>
<style>
.myarea {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>
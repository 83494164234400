<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              미디어 믹스 저장
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding: 20px">

            <div class="d-flex">
              <input class="form-control" type="text" placeholder="믹스명을 입력해주세요."
                     v-model="params.name"
              >
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
import store from "@/store";
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({

    }),
  },  data () {
    return {
      mix_data:null,
      dlg_open:false,
      params: {
        name: '',
      },
    }
  },
  methods: {
    open:function () {
      this.reset()
      this.dlg_open = true

      this.init()
    },
    onSubmit() {

      if( !this.params.name ) {
        this.$eventBus.$emit('error', '이름을 입력해주세요.')
        return
      }

      this.$emit('saved', this.params.name)
      this.dlg_open = false

    },
    reset() {
      this.params = {
        advertiserId: this.advertiserId,
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      }
    },
    init:function () {
      this.$http.get('/api/media-mix/list')
          .then(({ data }) => {
            this.mix_data = data.content

            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  watch: {
  }
}
</script>

<template>
  <div class="d-flex justify-content-center">
    <div class="form-check radio-column" v-for="(item,idx) in list" :key="idx">
      <input
          :value="item.id"
          v-model="selected"
          class="form-check-input" type="radio" name="formRadios" :id="id+idx">
      <label class="form-check-label" :for="id+idx">
        {{item.name}}
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    cols:{
      type:String,
      default:'column'
    },
  },
  methods: {

  },
  created() {
    var generateUUID = function() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
    this.id = 'radio'+generateUUID()

    this.selected = this.value
  },
  mounted() {
  },
  watch:{
    selected:function () {
      this.$emit('input', this.selected)
    }
  },
  data() {
    return {
      id:'',
      selected: 0,
    }
  }
}
</script>
<style scoped>
.radio-column:not(:first-child) {
  margin-left: 20px;
}
</style>
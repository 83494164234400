<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 900px !important;max-width: 900px !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              Api 리포트 불러오기
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div
                style="max-height: 400px;overflow-y: scroll;padding:0"
            >

              <div class="card border border-primary" style="overflow: visible">
                <div class="card-header bg-transparent border-primary">

                  <table class="table" style="width: 100%">
                    <tbody>
                    <tr>
                      <td class="d-flex">
                        <div style="padding-left: 3px;">
                          <select class="form-select" aria-label=""
                          >
                            <option :value="null">{{ params.advertiserName }}</option>
                          </select>
                        </div>
                        <div style="padding-left: 3px;">
                          <select class="form-select" aria-label="매체 종류"
                                  v-model="params.apiTypeTemplateId"
                          >
                            <option :value="null">매체 종류 선택</option>
                            <option
                                v-for="item in first_media_type.apiType"
                                :key="item.templateId"
                                :value="item.templateId">{{ item.displayName }}</option>
                          </select>
                        </div>
                        <div style="flex-grow: 1;padding-left: 3px;">
                          <input class="form-control" type="text" placeholder="이름을 입력해주세요."
                                 @keydown.enter="search"
                                 v-model="params.apiName"
                          >
                        </div>
                        <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
                          <button type="button" class="btn btn-secondary waves-effect waves-light"
                                  @click="search"
                          >
                            검색
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <my-table
                  @onpage="onpage"
                  :head="['매체','리포트 이름','리포팅 수준','캠페인 기간','선택']"
                  :tableData="tableData"
                  :format="['displayName','apiName','_slot_report_type','_slot_date','_slot_select']"
                  :pageParam="pageParam"
              >
                <template slot="_slot_select" slot-scope="slotProps">
                  <div style="min-width: 70px">
                    <button type="button" class="btn btn-danger waves-effect waves-light"
                            @click="select(slotProps.item)"
                    >
                      선택
                    </button>
                  </div>
                </template>

                <template slot="_slot_report_type" slot-scope="slotProps">
                  <span v-html="report_type(slotProps.item)"/>
                </template>
                <template slot="_slot_date" slot-scope="slotProps">
                  <div style="min-width:100px">
                  {{slotProps.item.startDate}}
                  ~
                    <br/>
                  {{slotProps.item.endDate}}
                  </div>
                </template>
              </my-table>

            </div>

          </div>

          <div class="modal-footer">
            <button type="button"
                    @click="dlg_open=false"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
import store from "@/store";
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
    MyTable
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({
      first_media_type:'first_media_type'
    }),
  },
  data () {
    return {
      tableData:null,
      page:0,
      pageParam:null,
      dlg_open:false,
      params: {
        apiTypeTemplateId:null,
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      },
      businessOptions: [],
    }
  },
  mounted() {

  },
  methods: {
    select:function (item) {
      //this.dlg_open = false
      this.$emit('selected', item)
      this.$eventBus.$emit('info', '리포트가 추가되었습니다.')
    },
    report_type:function (item) {

      var m = ''
      this.first_media_type.adReportType.forEach(el=>{

        if(item.adReportTypeTemplateId == el.templateId) {
          m = el.displayName
          return
        }
      })

      return m

      //{{first_media_type.apiType[slotProps.item.adReportTypeTemplateId]}}
    },
    onpage:function (p) {
      this.page = p
      this.search()
    },
    search:function () {
      //http://localhost:8080/api/manage/list?advertiserId=1&apiName=%EA%B5%AC%EA%B8%80

      var url = '/api/manage/list'+'?page='+this.page
      url += '&apiName='+encodeURIComponent(this.params.apiName)
      url += '&advertiserId='+this.params.advertiserId
      if(this.params.apiTypeTemplateId)
        url += '&apiTypeTemplateId='+this.params.apiTypeTemplateId


      this.$http.get(url)
          .then(({ data }) => {

            this.tableData = data.content
            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages

          })
          .catch(error => {
            console.log(error)
          })
    },
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

    },
    open:function (advertiserId, advertiserName) {

      this.reset()
      this.params.advertiserId = advertiserId
      this.params.advertiserName = advertiserName
      this.dlg_open = true

      this.search()

    },
    onSubmit() {

    },
    reset() {
      this.tableData=null
      this.params = {
        apiTypeTemplateId:null,
        advertiserId: null,
        apiName: '',
      }
    }
  },
  watch: {
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    user: null,

    mediacnt:0,

    mynav:null,
    dashboard:false,
    rolePriority:null,

    // media_product
    first_media_type:null,
    advertisers:null,
    axios_error_status:null,

    main_media_type:null,
    media_products_info:null,

    loading:null,

    STATUS:{
        1: '승인대기', 2: '승인완료', 3: '반려', 4: '사용중'
    },
}

const getters = {

    loading: state => state.loading,

    mediacnt: state => state.mediacnt,
    mynav: state => state.mynav,
    main_media_type: state => state.main_media_type,
    advertisers: state => state.advertisers,
    STATUS: state => state.STATUS,
    first_media_type: state => state.first_media_type,
    axios_error_status: state => state.axios_error_status,
    user: state => state.user,
    rolePriority: state => state.rolePriority,
    dashboard: state => state.dashboard,

    media_products_info: state => state.media_products_info,

}

const mutations = {
    media_products_info(state, value) {
        state.media_products_info = value
    },
    loading(state, value) {
        state.loading = value
    },

    mediacnt(state, value) {
        state.mediacnt = value
    },

    dashboard(state, value) {
        state.dashboard = value
    },
    mynav(state, value) {
        state.mynav = value
    },
    advertisers(state, value) {
        state.advertisers = value
    },
    main_media_type(state, value) {
        state.main_media_type = value
    },

    first_media_type(state, value) {
        state.first_media_type = value
    },
    axios_error_status(state, value) {
        state.axios_error_status = value
    },
    user(state, value) {
        state.user = value
    },
    rolePriority(state, value) {
        state.rolePriority = value
    },
}

const actions = {
    media_products_info(state) {
        return state.media_products_info
    },

    mediacnt(state) {
        return state.mediacnt
    },

    dashboard(state) {
        return state.dashboard
    },
    loading(state) {
        return state.loading
    },

    mynav(state) {
        return state.mynav
    },

    main_media_type(state) {
        return state.main_media_type
    },
    advertisers(state) {
        return state.advertisers
    },

    first_media_type(state) {
        return state.first_media_type
    },
    axios_error_status(state) {
        return state.axios_error_status
    },
    user(state) {
        return state.user
    },
    rolePriority(state) {
        return state.rolePriority
    },
}

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions
})
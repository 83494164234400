<template>
  <input type="text" class="form-control" :placeholder="label"
         v-model="val"
         :style="mstyle"
         @keydown="keydown"
  >
</template>
<script>
export default {
  watch:{
    val:function (newValue) {

      if(this.number && this.val != '') {
        try {
          var orig = newValue
          orig = orig.replaceAll(',','')

          var n2 = orig
          newValue = this.number2(n2)
          this.val = newValue

          //console.log(orig)
          if(isNaN(orig) || orig == 'NaN')
            orig = ''

          this.$emit('input', orig)
          this.$emit('change', orig)
          return
        } catch (e) {}
      }

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
    value:function () {
      this.init()
    }
  },
  props: {
    mstyle: {
      default:null,
      type:String
    },
    number: {
      default:false,
      type:Boolean
    },
    value: {
      default:null,
      type:String
    },
    label: {
      default: '',
      type: String
    },
  },
  methods:{
    init:function () {
      if(this.number) {

        var a = this.val
        var b = this.value

        if(isNaN(b) || b=='NaN')
          b = ''

        if(a==null || a==undefined)
          a = ''
        if(b==null || b==undefined)
          b = ''

        a = a.toString()
        b = b.toString()

        if(a)
          a = a.replaceAll(',','')
        if(b)
          b = b.replaceAll(',','')

        if(a==b)
          return

        this.val = this.number2(b)

      } else {
        this.val = this.value
      }
    },
    number2 : function (w) {
      var reg = /(^[+-]?\d+)(\d{3})/;
      var n = (w + '');

      while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
      return n
    },
    keydown:function (e) {
      if(!this.number)
        return false

      if(e.key=='Tab')
        return true


      // e
      var a = '0123456789'
      if(a.indexOf(e.key)>=0)
        return true

      if(e.key=='.')
        return true
      if(e.key=='Backspace')
        return true
      if(e.key=='Enter')
        return true
      if(e.key=='ArrowLeft')
        return true
      if(e.key=='ArrowRight')
        return true

      e.preventDefault()
      return false
    }
  },
  mounted() {
    if(this.value != null && this.value != undefined)
      this.init()
  },
  data() {
    return {
      val:null
    }
  }
}
</script>
var myError = {
  name: 'myError',
  data() {
    return {
    }
  },
  methods:{
    resetMyError:function () {
      this.$merror = {
        status:0,
        msg:''
      }
    },
    setMyError:function (msg) {

      this.$merror.status = 1
      this.$merror.msg = msg
    },
    isMyError:function () {
      if(this.$merror.status) {
        return true
      }
      return false
    },
    whenErrorFalseAndAlert:function () {

      if(this.$merror.status) {
        if(this.$merror.msg) {
          this.$eventBus.$emit('error', this.$merror.msg)
        } else {
          this.$eventBus.$emit('error', '처리에 문제가 발생했습니다.')
        }
        return false
      } else
        return true
    }
  }
}
export default myError
<template>
  <div style="display: flex;flex-direction:column;width: 100%">

    <div style="display: flex;width: 100%">
        <div style="flex: 1;display: flex">
          <div style="width:60px;align-self: center;font-weight: bold;text-align: right">
            상품명
          </div>
          <div style="flex: 1;padding-left: 14px">
            <input class="form-control" type="text" placeholder="상품명"
                   v-model="item.placement"
            >
          </div>
        </div>
        <div style="flex: 1;display: flex">
          <div style="width:80px;align-self: center;font-weight: bold;text-align: right">
            디바이스
            <br/>
            Device
          </div>
          <div style="flex: 1;padding-left: 14px">
            <select class="form-select" aria-label="디바이스 (Device)"
                    v-model="item.deviceTemplateId"
            >
              <option
                  v-for="xitem in device"
                  :key="xitem.templateId"
                  :value="xitem.templateId">{{ xitem.displayName }}</option>
            </select>
          </div>
        </div>
        <div style="flex: 1;display: flex">
          <div style="width:80px;align-self: center;font-weight: bold;text-align: right">
            상품 유형
            <br/>
            Media Type
          </div>
          <div style="flex: 1;padding-left: 14px">
            <select class="form-select" aria-label="상품 유형 (Media Type)"
                    v-model="item.adProductMediaTypeTemplateId"
            >
              <option
                  v-for="xitem in adProductMediaType"
                  :key="xitem.templateId"
                  :value="xitem.templateId">{{ xitem.displayName }}</option>
            </select>
          </div>
        </div>
      </div>
    <div style="display: flex;width: 100%;padding-top: 12px">
      <div style="flex: 1;display: flex">
        <div style="width:60px;align-self: center;font-weight: bold;text-align: right">
          광고 형식
          <br/>
          Format
        </div>
        <div style="flex: 1;padding-left: 14px">
          <select class="form-select" aria-label="광고 형식 (Format)"
                  v-model="item.formatTemplateId"
          >
            <option
                v-for="xitem in format"
                :key="xitem.templateId"
                :value="xitem.templateId">{{ xitem.displayName }}</option>
          </select>
        </div>
      </div>
      <div style="flex: 1;display: flex">
        <div style="width:80px;align-self: center;font-weight: bold;text-align: right">
          과금 방식
          <br/>
          Buying Type
        </div>
        <div style="flex: 1;padding-left: 14px">
          <select class="form-select" aria-label="과금 방식 (Buying Type)"
                  v-model="item.buyingTypeTemplateId"
          >
            <option
                v-for="xitem in buyingType"
                :key="xitem.templateId"
                :value="xitem.templateId">{{ xitem.displayName }}</option>
          </select>
        </div>
      </div>
      <div style="flex: 1;display: flex">
      </div>
    </div>

    <div class="mb-2 d-flex mt-2" style="justify-content: center">
      <div>
        <button type="button" class="btn btn-secondary waves-effect"
                @click="add_item"
        >
          추가
          <i style="padding-left: 5px" class="fas fa-plus"></i>
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters({
      first_media_type: 'first_media_type',
    }),
    device:function () {
      return this.first_media_type.device[this.my_media_type]
    },
    adProductMediaType:function () {
      return this.first_media_type.adProductMediaType[this.my_media_type]
    },
    format:function () {
      return this.first_media_type.format[this.my_media_type]
    },
    buyingType:function () {
      return this.first_media_type.buyingType[this.my_media_type]
    },
  },
  components: {
  },
  props: {
    my_media_type: {
      type:String,
      default: null
    },
    adProductList:{
      type:Array,
      default:null
    }
  },
  mounted() {
    if(this.adProductList)
      this.items = this.adProductList
  },
  data () {
    return {
      item:{
        placement:null,
        deviceTemplateId:null,
        adProductMediaTypeTemplateId:null,
        formatTemplateId:null,
        buyingTypeTemplateId:null,
      },
      items:[],
    }
  },
  methods: {
    add_item:function () {
      if(!this.validate())
        return

      var v = this.$deepCopy(this.item)
      this.$emit('input', v)

    },
    get:function () {
      return this.items
    },
    validate:function () {

      var error = {
        placement:false,
        deviceTemplateId:false,
        adProductMediaTypeTemplateId:false,
        formatTemplateId:false,
        buyingTypeTemplateId:false,
      }

      {
        var item = this.item
        {
          if(!item.placement) {
            error.placement = true
          }
          if(!item.deviceTemplateId) {
            error.deviceTemplateId = true
          }
          if(!item.adProductMediaTypeTemplateId) {
            error.adProductMediaTypeTemplateId = true
          }
          if(!item.formatTemplateId) {
            error.formatTemplateId = true
          }
          if(!item.buyingTypeTemplateId) {
            error.buyingTypeTemplateId = true
          }
        }
      }

      var msg = ''
      if(error.placement) {
        msg += '상품명'
      }

      if(error.deviceTemplateId) {
        if(msg!='')
          msg += ', '
        msg += '디바이스'
      }

      if(error.adProductMediaTypeTemplateId) {
        if(msg!='')
          msg += ', '
        msg += '상품유형'
      }

      if(error.formatTemplateId) {
        if(msg!='')
          msg += ', '
          msg += '광고형식'
      }

      if(error.buyingTypeTemplateId) {
        if(msg!='')
          msg += ', '
          msg += '과금방식'
      }

      if(msg != '') {
        this.$eventBus.$emit('error', '추가한 상품의 ' + msg + ' 항목을 입력해주세요.')
        return false
      }

      return true
    },
  },
  watch: {
  }
}
</script>

<template>
  <div>
    <treeselect
        :multiple="true"
        :options="options"
        :placeholder="label"
        v-model="val"
    />
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components:{
    Treeselect
  },
  watch:{
    list:function () {
      this.init()
    },
    val:function (newValue) {
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
    value:function () {
      this.val = this.value
    }
  },
  props: {
    list: {
      default:null,
      type:Array
    },
    k: {
      default:null,
      type:String
    },
    v: {
      default:null,
      type:String
    },
    mstyle: {
      default:null,
      type:String
    },
    value: {
      default:null,
      type:String
    },
    label: {
      default: '',
      type: String
    },
  },
  mounted() {
    if(this.list)
      this.init()

    if(this.value)
      this.val = this.value
  },
  methods:{
    init:function () {
      this.options = []
      this.list.forEach(el=>{
        var k = el[this.k]
        var v = el[this.v]
        //el
        var o = {}
        o['id'] = k
        o['label'] = v

        this.options.push(o)
      })
    }
  },
  data() {
    return {
      val:null,

      // define options
      options: [],
    }
  }
}
</script>
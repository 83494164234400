var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dlg_open)?_c('div',[_c('div',{staticClass:"modal fade show",staticStyle:{"display":"block","background-color":"#00000055"}},[_c('div',{staticClass:"modal-dialog",staticStyle:{"width":"65% !important","max-width":"95% !important"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title mt-0"},[_vm._v(" 일자별 상세 리포트 ")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){_vm.dlg_open=false}}})]),_c('div',{staticClass:"modal-body"},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"10px"}},[_c('div',{staticStyle:{"flex-grow":"1"}}),_c('button',{staticClass:"btn btn-dark waves-effect waves-light",staticStyle:{"margin-right":"10px"},attrs:{"type":"button"},on:{"click":_vm.download}},[_vm._v(" 다운로드 "),_c('i',{staticClass:"fas fa-file-excel"})])]),_c('div',{staticStyle:{"max-height":"80vh","overflow-y":"auto","padding":"0"}},[_c('my-table',{key:'tb_present_detail_detail',attrs:{"head":['날짜',
                '매체비용(원)::@NUM',
                '노출수::@NUM',
                '클릭수::@NUM',
                '조회수::@NUM',
                '인스톨수::@NUM',
                'CPRP::@NUM',
                'GRPS::@NUM'
                ],"tableData":_vm.items,"format":['_slot_date','_slot_dailyCost','_slot_dailyImp','_slot_dailyClick','_slot_dailyView',
                '_slot_dailyInstall','_slot_dailyCprp','_slot_dailyGrps'
                ]},scopedSlots:_vm._u([{key:"_slot_date",fn:function(slotProps){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(slotProps.item.date)+" ")])])]}},{key:"_slot_dailyCost",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyCost))}}):_vm._e()]}},{key:"_slot_dailyImp",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyImp))}}):_vm._e()]}},{key:"_slot_dailyClick",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyClick))}}):_vm._e()]}},{key:"_slot_dailyView",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyView))}}):_vm._e()]}},{key:"_slot_dailyInstall",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyInstall))}}):_vm._e()]}},{key:"_slot_dailyCprp",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyCprp))}}):_vm._e()]}},{key:"_slot_dailyGrps",fn:function(slotProps){return [(slotProps.item)?_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.my_number(slotProps.item.dailyGrps))}}):_vm._e()]}}],null,false,28768117)})],1)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
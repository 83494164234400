<template>
  <div>
    <gnb class="mbg">
      <template  slot="desc">
        API 연동 가능한 매체를 등록하고 관리합니다.

        <div class="pt-4">
          <button type="button" class="btn btn-primary waves-effect waves-light"
                  @click="$router.push({path:'mapi/register'})"
          >
            API 캠페인 리포트 등록
          </button>
        </div>
      </template>
    </gnb>

    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary">
          <div class="d-flex" >
            <div>
              <select class="form-select" aria-label="광고주"
                      style="min-width: 120px"
                      v-model="params.advertiserId"
              >
                <option :value="null">광고주명</option>
                <option
                    v-for="item in list_advertisers"
                    :key="item.advertiserId"
                    :value="item.advertiserId">{{ item.advertiserName }}</option>
              </select>
            </div>
            <div style="padding-left: 3px;">
              <select class="form-select" aria-label="매체 종류"
                      v-model="params.apiTypeTemplateId"
              >
                <option :value="null">매체명</option>
                <option
                    v-for="item in first_media_type.apiType"
                    :key="item.templateId"
                    :value="item.templateId">{{ item.displayName }}</option>
              </select>
            </div>
            <div style="padding-left: 3px;flex:1">

              <my-search-box
                  style="width:100%"
                  v-model="query"
                  @focus="onfocus"
                  :options="options"
                  @selected="search_selected"
                  @onsearch2="onsearch2"
                  hint="검색어를 입력해주세요."
              />

            </div>
            <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
              <button
                  @click="search"
                  type="button" class="btn btn-secondary waves-effect waves-light">
                <i class='fal fa-search' style="margin-right: 3px"></i>
                검색
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="mt-4">

        <my-table
            @onpage="onpage"
            :head="['자동 리포트 ID::@CENTER','광고주명::@CENTER','리포트 명칭::@CENTER','매체명::@CENTER','집행 기간::@CENTER','등록일::@CENTER','마지막 수집일::@CENTER','상세보기::@CENTER','코차바상태','코차바연결::@CENTER']"
            :tableData="tableData"
            :format="['apiId','advertiserName','apiName','displayName','_slot_range','_slot_regdate','_slot_moddate','_slot1','_slot_kochava_status','_slot_kochava']"
            :pageParam="pageParam"
        >
          <template slot="_slot_range" slot-scope="slotProps">
            {{slotProps.item.startDate}}
            ~
            {{slotProps.item.endDate}}
          </template>
          <template slot="_slot_regdate" slot-scope="slotProps">
            <span v-html="getDate(slotProps.item.createAt)"/>
          </template>
          <template slot="_slot_moddate" slot-scope="slotProps">
            <span v-html="getDate(slotProps.item.modAt)"/>
          </template>
          <template slot="_slot1" slot-scope="slotProps">
              <i class="fas fa-search align-self-center" style="cursor: pointer" @click="clkView(slotProps.item)"></i>
          </template>

          <template slot="_slot_kochava_status" slot-scope="slotProps">
            <div v-for="xitem in slotProps.item.kochavaList" style="margin: 3px">
              {{xitem.kochavaMediaName}}
              <i class="fas fa-backspace align-self-center" style="cursor: pointer" @click="remove_link(xitem,slotProps.item)"></i>
            </div>
          </template>
          <template slot="_slot_kochava" slot-scope="slotProps">
            <i class="fas fa-link align-self-center" style="cursor: pointer" @click="clkLink(slotProps.item)"></i>
          </template>
        </my-table>


      </div>
    </div>

    <register-modal ref="modal" @updated="load"/>
    <link-kochava ref="link" @updated="load"/>
  </div>
</template>

<script>
import MySearchBox from "@/components/common/MySearchBox";
import linkKochava from "@/pages/setting/system/mapi/linkKochava";
import MyTable from "@/components/common/MyTable";
import registerModal from './register'

import Gnb from "@/nav/gnb";
import moment from "moment";
import {mapGetters} from "vuex";
import {GET_MEDIA_LIST} from "@/pages/setting/system/media/constants";
export default {
  computed: {
    ...mapGetters({
      first_media_type:'first_media_type'
    }),
  },
  created() {
  },
  components: {
    MySearchBox,
    Gnb,
    MyTable,
    registerModal,
    linkKochava
  },
  data() {
    return {
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      params:{
        apiTypeTemplateId:null,
        advertiserId:null
      },
      tableData:null,
      pageParam:null,
      page:0,
      query:null,
      advertiserId: null,
      list_advertisers:null
    }
  },
  mounted() {
    this.loadFilter()

    this.load()
  },
  methods: {
    onfocus:function () {
      this.onsearch()
    },

    search_selected:function (v) {
      this.query = v
      this.search()
    },
    onsearch2:function (k, resolve) {
      this.query = k

      var url = '/api/manage/list'+'?page='+this.page
      if(this.query) {
        url += '&apiName='+encodeURIComponent(this.query)
      }

      if(this.params.advertiserId)
        url += '&advertiserId='+this.params.advertiserId
      if(this.params.apiTypeTemplateId)
        url += '&apiTypeTemplateId='+this.params.apiTypeTemplateId

      this.$http.get(url)
          .then(({ data }) => {

            var arr = data.content

            var a = []
            arr.forEach(el=>{
              a.push(el.apiName)
            })

            resolve(a)
          }).catch(err=>{
      })
    },
    remove_link:function (a1, _a2) {
      var id = a1.kochavaId
      var a2 = _a2.kochavaList

      for(var i=0;i<a2.length;i++) {
        var el = a2[i].kochavaId
        if (el == id) {
          a2.splice(i, 1)
          break
        }
      }

      var api = '/api/manage/connect/Kochava'

      this.$http.post(api, _a2)
          .then( ({data}) => {
            this.$eventBus.$emit('info', '코차바 연결이 하제되었습니다.')
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.errorMessage)
          })
    },
    clkLink:function (item) {
      this.$refs.link.open_mod(item.apiId)
    },
    clkView:function (item) {
      this.$refs.modal.open_mod(item.apiId)
    },
    getDate:function (item) {
      return moment(item).format('YYYY-MM-DD')
    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {
            this.list_advertisers = data
          })
    },
    search:function () {
      this.load()
    },
    onpage:function (p) {
      this.page = p
      this.load()
    },
    load:function () {

      var url = '/api/manage/list'+'?page='+this.page
      if(this.query) {
        url += '&apiName='+encodeURIComponent(this.query)

        if(this.params.advertiserId)
          url += '&advertiserId='+this.params.advertiserId
        if(this.params.apiTypeTemplateId)
          url += '&apiTypeTemplateId='+this.params.apiTypeTemplateId
      }
      this.$http.get(url)
          .then(({ data }) => {

            this.tableData = data.content

            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages
          })
          .catch(error => {
          })
    },
  }
}
</script>
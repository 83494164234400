// import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

export const sessionManagement = (to, from, next) => {
    //console.log('to = ', to)
    //console.log('from = ', from)

    if (to.path === '/login') {
        next()
        return
    }

    var myrouting = function (to, from, next) {
        if (to.path === '/setting/system') {
            next('/setting/system/advertiser')
        }
        else if (to.path === '/setting') {
            next('/setting/presentDB')
        } else
            next()

    }

    if(store.state.user) {
        myrouting(to, from, next)
        return
    }

    axios.get('/api/user/session')
        .then(({ data }) => {
            if (!data) {
                next('/login')
                return
            }

            // test 권한
            //data.userinfo.roleId=1
            // test

            store.commit('user',data)
            var role = data.userinfo.rolePriority
            store.commit('rolePriority', role)

            // digital??
            var u = data
            if(u && u.userinfo.dashboard == 1) {
                store.commit('dashboard', true)
            }
            else
                store.commit('dashboard', false)

            myrouting(to, from, next)
        })
        .catch(error => {
            next('/login')
            return
        })
}
<template>
  <div class="vl-parent">
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      loading:'loading',
    }),
  },
  data() {
    return {
    }
  },
  components: {
    Loading
  },
  methods: {
  }
}
</script>
<template>
  <div :class="boxClass" :style="getStyle">

    <template>
      <slot name="__slot_header">
      </slot>
    </template>

    <div v-if="head && tableData2" class="table_wrapper" :style="wrapperStyle">
      <my-sort-edit
          v-if="headId && !headSplit"
          :mykey="headId"
          :header="myheader"
          :head-split="headSplit"
          @init="on_init"
          style="position: absolute;left: 0;top: 0"></my-sort-edit>

      <my-sort-edit-grp
          v-if="headId  && headSplit"
          :mykey="headId"
          :header="myheader"
          :head-split="headSplit"
          @grp="on_grp"
          @init="on_init"
          style="position: absolute;left: 0;top: 0"></my-sort-edit-grp>

      <table
          v-if="headerInit"
          :class="tbclass?'table100 ver2 '+tbclass:'table100 ver2'">
        <thead :class="tbhead_class">

        <slot name="__slot_header_th2">
        </slot>

        <tr>
          <th v-for="(item,ih) in head2" :key="item+ih" :xstyle="headerAlign(item)+';position:relative'"
              style="text-align: center;position:relative"
          >
            <span v-text="printHeader(item)"/>
            <span
                v-if="sort2&&sort2[ih]"
                @click="on_sort(item, ih)"
                style="padding-left: 5px;cursor: pointer">
              <i v-if="sort_idx==ih&&sort_desc" class="fas fa-sort-down"></i>
              <i v-else-if="sort_idx==ih&&!sort_desc" class="fas fa-sort-up"></i>
              <i v-else class="fas fa-sort"></i>
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,idx) in tableData2" :key="idx"
            :style="clickable?'cursor:pointer':''"
            @click="rowClick(item)"
        >
          <td scope="row" v-for="(x,i) in format2" :key="i" :style="headerAlign(head2[i])">
            <template v-if="x.indexOf('_slot')==0">
              <slot :name="x" v-bind:item="item" v-bind:col="i">
              </slot>
            </template>
            <template v-else>
              {{item[x]}}
            </template>
          </td>
        </tr>
        </tbody>
      </table>

      <my-pagination
          style="margin-top: 12px"
          v-if="page && pageParam"
          @onpage="onpage"
          :pageParam="pageParam"
          :key="pageFlag"
      />
    </div>
  </div>
</template>
<script>
import MySortEditGrp from "@/common/MySortEditGrp";
import MySortEdit from "@/common/MySortEdit";
import MyPagination from "@/components/common/MyPagination";
export default {
  components:{
    MySortEditGrp,
    MyPagination,
    MySortEdit
  },
  computed:{
    myheader:function () {

      var a = []
      for(var i in this.head) {
        var el = this.head[i]
        a.push({
          id:el,
          name:el
        })
      }
      return a
    },
    getStyle:function () {
      if(this.layerStyle)
        return this.layerStyle

      var a = 'background-color: white;padding:' + this.padding + 'px'
      if (this.paddingLeft)
        a += ';padding-left:' + this.paddingLeft + 'px'
      return a
    }
  },
  props: {
    wrapperStyle: {
      type:String,
      default:'padding: 0 15px;position:relative;'
    },
    layerStyle:{
      type:String,
      default:null
    },
    boxClass:{
      type:String,
      default:'card'
    },
    headSplit:{
      type:Array,
      default:null
    },
    headSort:{
      type:Array,
      default:null
    },
    headId:{
      type:String,
      default:null
    },
    paddingLeft: {
      type:Number,
      default: 0
    },
    padding:{
      type:Number,
      default: 0
    },
    clickable:{
      type:Boolean,
      default: false
    },
    head:{
      type:Array,
      default: null
    },
    tableData:{
      type:Array,
      default: null
    },
    page:{
      type:Boolean,
      default:true
    },
    format:{
      type:Array,
      default: null
    },
    pageParam:{
    type:Object,
      default:null
    },
    tbhead_class: {
      type:String,
      default:'table-light'
    },
    tbclass: {
      type:String,
      default:null
    }
  },
  methods: {
    on_sort:function (item, i) {
      this.sort_desc = !this.sort_desc
      this.sort_idx = i

      this.proc_sort()

      this.$emit('sort', this.head2[i], i, this.sort_desc)
    },
    on_grp:function (v) {
      this.$emit('grp', v)
    },
    on_init:function (v) {

      // header.
      this.headerInit = true

      this.format2 = []
      if(this.headSort)
        this.sort2 = []

      var a = []
      v.forEach(el=>{
        if(el.view) {
          a.push(el.id)

          // 포맷을 찾는다. (기존 헤더 아이디를 찾는다)
          var i = 0
          for(var j in this.head) {
            var e2 = this.head[j]
            if(e2 == el.id) {
              break
            }
            i++
          }
          var f = this.format[i]
          this.format2.push(f)

          if(this.headSort) {
            this.sort2.push(this.headSort[i])
          }
        }
      })

      this.head2 = a

      //
      {

        // 헤더 구분 완료
        //console.log(this.head2)
        //console.log(this.head2)
      }
    },
    printHeader:function (item) {
      var n = item.indexOf('::@NUM')
      if(n>0) {
        return item.substring(0,n)
      }

      n = item.indexOf('::@CENTER')
      if(n>0) {
        return item.substring(0,n)
      }


      {
        var k = '::@W_CENTER::'
        n = item.indexOf(k)
        if(n>0) {
          return item.substring(0,n)
        }
      }

      {
        var k = '::@W::'
        n = item.indexOf(k)
        if(n>0) {
          return item.substring(0,n)
        }
      }

      return item
    },
    headerAlign:function (item) {
      if(item.indexOf('::@NUM')>0) {
        return 'text-align:right'
      }
      if(item.indexOf('::@CENTER')>0) {
        return 'text-align:center'
      }

      {
        var k = '::@W_CENTER::'
        var n = item.indexOf(k)
        if(n>0) {
          var width = item.substring(n+k.length)
          return 'text-align:center;min-width:'+width+'px'
        }
      }
      {
        var k = '::@W::'
        var n = item.indexOf(k)
        if(n>0) {
          var width = item.substring(n+k.length)
          return 'min-width:'+width+'px'
        }
      }

      return ''
    },
    handleResize(event) {
      this.init()
    },
    proc_sort:function () {

      var desc = this.sort_desc
      var i = this.sort_idx

      var ty = this.sort2[i]

      var num = false
      var field = ''
      var n = ty.indexOf('::@NUM')
      if(n >=0) {
        field = ty.substring(0,n)
        num = true
      }
      else
        field = ty

      if(num) {
        this.tableData2.sort(function(a, b) {

          var aa = a[field]
          var bb = b[field]

          if(field.indexOf('@')>0) {
            var s = field.split('@')
            aa = a[s[0]]?a[s[0]][s[1]]:0
            bb = b[s[0]]?b[s[0]][s[1]]:0
          }

          if(!aa)
            aa = 0
          if(!bb)
            bb = 0

          var res = aa - bb;
          if(desc)
            return -(res)
          else
            return res
        });
      } else {
        this.tableData2.sort(function(_a, _b) {
          var aa = _a[field]
          var bb = _b[field]

          if(field.indexOf('@')>0) {
            var s = field.split('@')
            aa = _a[s[0]]?_a[s[0]][s[1]]:''
            bb = _b[s[0]]?_b[s[0]][s[1]]:''
          }


          if(!aa)
            aa = ''
          if(!bb)
            bb = ''
          var a = aa.toString()
          var b = bb.toString()

          var res = ('' + a).localeCompare(b);
          if(desc)
            return -(res)
          else
            return res
        });

        var aa = []
        this.tableData2.forEach(el=>{
          aa.push(el[field])
        })
      }

      //console.log(this.tableData2)

    },
    init:function () {
      if(!this.tableData)
        return

      this.tableData2 = []
      this.tableData.forEach(el=>{
        this.tableData2.push(el)
      })
    },
    rowClick:function (item) {
      this.$emit('rowClick',item)
    },
    onpage:function (p) {
      this.$emit('onpage',p-1)
    }
  },
  mounted() {
    this.init()

    if(!this.headId) {
      this.headerInit = true
      this.head2 = this.head
      this.format2 = this.format
    }
    //headerInit
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
  },
  watch:{
    pageParam:function () {
      this.pageFlag++
    },
    head:function () {
      this.init()
    },
    tableData:function () {
      this.init()
    }
  },
  data() {
    return {
      tableData2:null,
      head2:null,
      format2:null,
      sort2:null,
      sort_desc:false,
      sort_idx:-1,
      headerInit:false,
      tableInit:false,
      e1:0,
      e2:0,
      pageFlag:0
    }
  },
}
</script>
<style>

.table_wrapper{
  background-color: white;
  display: block;
  overflow-x:auto;
  white-space: nowrap;
}
.table-white thead th {
  background-color: #AAB6C3 !important;
}
.table-white th {
  background-color: white !important;
}

.table100 {
  width: 100%;
  border-spacing: 0px;
}

.table100.ver2 th {
  font-size: 16px;
  color: #2E498E;
  line-height: 1.4;
  padding: 10px 12px;
}

.table100.ver2 td {
  font-size: 12px;
  color: #343A40;
  line-height: 1.4;
  padding: 5px 12px;
}

.table100 td {
  font-size: 12px;
  color: #343A40;
  line-height: 1.4;
  padding: 5px 12px;
}

.table100.ver2 .ps__rail-y {
  right: 5px
}

.table100.ver2 .ps__rail-y::before {
  background-color: #ebebeb
}

.table100.ver2 .ps__rail-y .ps__thumb-y::before {
  background-color: #ccc
}

.table100.ver3 {
  background-color: #393939
}

.table100.ver3 th {
  font-family: Lato-Bold;
  font-size: 15px;
  color: #00ad5f;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: #393939
}

.table100.ver3 td {
  font-family: Lato-Regular;
  font-size: 15px;
  color: gray;
  line-height: 1.4;
  background-color: #222
}

.table100.ver3 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
}

.table100.ver3 .ps__rail-y {
  right: 5px
}

.table100.ver3 .ps__rail-y::before {
  background-color: #4e4e4e
}

.table100.ver3 .ps__rail-y .ps__thumb-y::before {
  background-color: #00ad5f
}

.table100.ver4 {
  margin-right: -20px
}

.table100.ver4 .table100-head {
  padding-right: 20px
}

.table100.ver4 th {
  font-family: Lato-Bold;
  font-size: 18px;
  color: #4272d7;
  line-height: 1.4;
  background-color: transparent;
  border-bottom: 2px solid #f2f2f2
}

.table100.ver4 .column1 {
  padding-left: 7px
}

.table100.ver4 td {
  font-family: Lato-Regular;
  font-size: 15px;
  color: gray;
  line-height: 1.4
}

.table100.ver4 .table100-body tr {
  border-bottom: 1px solid #f2f2f2
}

.table100.ver4 {
  overflow: hidden
}

.table100.ver4 .table100-body {
  padding-right: 20px
}

.table100.ver4 .ps__rail-y {
  right: 0
}

.table100.ver4 .ps__rail-y::before {
  background-color: #ebebeb
}

.table100.ver4 .ps__rail-y .ps__thumb-y::before {
  background-color: #ccc
}

.table100.ver5 {
  margin-right: -30px
}

.table100.ver5 .table100-head {
  padding-right: 30px
}

.table100.ver5 th {
  font-family: Lato-Bold;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: transparent
}

.table100.ver5 td {
  font-family: Lato-Regular;
  font-size: 15px;
  color: gray;
  line-height: 1.4;
  background-color: #f7f7f7
}

.table100.ver5 .table100-body tr {
  overflow: hidden;
  border-bottom: 10px solid #fff;
  border-radius: 10px
}

.table100.ver5 .table100-body table {
  border-collapse: separate;
  border-spacing: 0 10px
}

.table100.ver5 .table100-body td {
  border: solid 1px transparent;
  border-style: solid none;
  padding-top: 10px;
  padding-bottom: 10px
}

.table100.ver5 .table100-body td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px
}

.table100.ver5 .table100-body td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px
}

.table100.ver5 tr:hover td {
  background-color: #ebebeb;
  cursor: pointer
}

.table100.ver5 .table100-head th {
  padding-top: 25px;
  padding-bottom: 25px
}

.table100.ver5 {
  overflow: hidden
}

.table100.ver5 .table100-body {
  padding-right: 30px
}

.table100.ver5 .ps__rail-y {
  right: 0
}

.table100.ver5 .ps__rail-y::before {
  background-color: #ebebeb
}

.table100.ver5 .ps__rail-y .ps__thumb-y::before {
  background-color: #ccc
}
</style>
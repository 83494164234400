<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              거래처 등록
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div>

              <table class="table" style="width: 100%">
                <tbody>
                <tr>
                  <th scope="row">광고주명</th>
                  <td>
                    <input class="form-control" type="text" placeholder="광고주명을 기재해주세요"
                           v-model="params.advertiserName"
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row">사업자 등록번호</th>
                  <td class="d-flex">
                    <input class="form-control" type="text" placeholder="번호를 기재해주세요"
                           v-model="params.advertiserNumber"
                    >
                    <div style="min-width: 90px;margin-left: 5px;" class="d-grid">
                      <button type="button" class="btn btn-secondary waves-effect waves-light"
                              @click="checkDuplicate"
                      >
                        중복체크
                      </button>
                    </div>
                  </td>
                </tr>
                <tr class="no-border">
                  <th scope="row">업종</th>
                  <td>

                    <select class="form-select" aria-label="업종"
                            v-model="params.businessId"
                    >
                      <option :value="null" selected>업종 선택</option>
                      <option
                          v-for="item in businessOptions"
                          :key="item.value"
                          :value="item.value">{{ item.name }}</option>
                    </select>

                  </td>
                </tr>
                </tbody>
              </table>

            </div>


          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              등록 및 수정
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import myError from "@/common/myError"
import {
  GET_BUSINESS_LIST,
  CHECK_BUSINESS_NUMBER_DUPLICATE,
  ADD_ADVERTISER,
  EDIT_ADVERTISER,
  GET_ADVERTISER_INFO
} from './constants.js'

export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
  },
  props: {
  },
  computed: {
  },
  data () {
    return {
      dlg_open:false,
      params: {
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: null
      },
      businessOptions: [],
    }
  },
  methods: {
    load:function (id) {
      this.reset()
      this.params.advertiserId = id
      this.dlg_open = true

      // load
      this.init()
    },
    init:function () {
      this.getBusinessList().then(() => {
        if(this.params.advertiserId) {
          this.$http.get(GET_ADVERTISER_INFO, { 'params': { 'advertiserId': this.params.advertiserId } })
              .then(({ data }) => {
                this.params.advertiserId = data.advertiserId
                this.params.advertiserName = data.advertiserName
                this.params.advertiserNumber = data.advertiserNumber
                this.params.businessId = data.businessId
              })
              .catch(error => {
                //this.toastr('warning', error.response.data.errorMessage)
                if (error.response) {
                  console.log(error.response)
                }
              })
        }

      })
    },
    open:function () {
      this.reset()
      this.dlg_open = true

      this.init()
    },
    getBusinessList() {
      this.businessOptions = []
      //this.businessOptions.push({ name: '업종 선택', value: ''})
      return this.$http.get(GET_BUSINESS_LIST)
          .then(({ data }) => {
            data.map(v => this.businessOptions.push({ name: v.businessName, value: v.businessId}))
          })
          .catch(error => {
            //this.toastr('warning', error.response.data.errorMessage)
            if (error.response) {
              console.log(error.response)
            }
          })
    },
    checkDuplicate:function() {

      this.resetMyError()
      if(!this.params.advertiserNumber) {
        this.setMyError('사업자 등록번호를 입력해주세요.')
        this.whenErrorFalseAndAlert()
        return
      }

      this.checkDuplicateViaApi().then(()=>{
        if(this.whenErrorFalseAndAlert()) {
          this.$eventBus.$emit('info', '사용 가능한 사업자 등록번호입니다.')
        }
      })
    },
    checkDuplicateViaApi:function() {

      this.resetMyError()
      return this.$http.get(CHECK_BUSINESS_NUMBER_DUPLICATE, { 'params': { 'advertiserNumber': this.params.advertiserNumber } })
          .then(({ data }) => {
            //if (data.responseCode === 'USABLE') {
              // none;
           // } else {
           //   this.setMyError(data.responseMessage)
           // }
          })
          .catch(error => {
            this.setMyError(error.errorMessage)
          })
    },
    checkValidation:function (modification) {

      var that = this
      this.resetMyError()

      return new Promise(function(resolve, reject) {

        if(!that.params.advertiserName) {
          that.setMyError('광고주명을 입력해주세요.')
          throw 'e'
        }

        if(!that.params.advertiserNumber) {
          that.setMyError('사업자 등록번호를 입력해주세요.')
          throw 'e'
        }

        if(!that.params.businessId) {
          that.setMyError('업종을 입력해주세요.')
          throw 'e'
        }

        if(modification) {
          // api 를 통한 중복확인 안함.
          resolve()
          return
        }

        that.checkDuplicateViaApi().then(()=>{
          if(that.isMyError()) {
            throw 'e'
          } else {
            // 최종 성공
            resolve()
          }
        }).catch(err=>{
          // async 이기때문에 reject 처리
          reject()
        })
      })
    },
    onSubmit() {

      var modification = false
      if (this.params.advertiserId)
        modification = true

      // validation
      this.checkValidation(modification)
        .then(()=>{

          if (!this.params.advertiserId) {
            this.$http.post(ADD_ADVERTISER, this.params)
                .then(() => {

                  this.$eventBus.$emit('info', '등록완료 되었습니다.')
                  this.dlg_open = false

                  this.$emit('updated')
                })
                .catch(error => {
                  //this.toastr('warning', error.response.data.errorMessage)
                  this.$eventBus.$emit('error',error.responseMessage)
                })

          } else {
            this.$http.put(EDIT_ADVERTISER, this.params)
                .then(() => {
                  this.$eventBus.$emit('info', '수정완료 되었습니다.')
                  this.dlg_open = false

                  this.$emit('updated')
                })
                .catch(error => {
                  //this.toastr('warning', error.response.data.errorMessage)
                  this.$eventBus.$emit('error',error.responseMessage)
                })
          }

        })
        .catch(()=>{
          this.whenErrorFalseAndAlert()
        })
    },
    reset() {
      this.params = {
        advertiserId: this.advertiserId,
        advertiserName: '',
        advertiserNumber: '',
        businessId: null
      }
    }
  },
  watch: {
  }
}
</script>

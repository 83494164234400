<template>
  <div class="page_parent">
    <gnb class="mbg">
      <template  slot="desc">
        과거에 집행된 캠페인의 상세 이력을 조회합니다.
      </template>
    </gnb>

    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary">

          <div v-if="user.userinfo.roleId!=1 && list_advertisers" style="padding-bottom: 7px;">
            <my-multi-select
                v-model="selected_advertiser"
                :list="list_advertisers"
                k="advertiserId"
                v="advertiserName"
                label="광고주를 선택해주세요. (중복선택가능)"
            />
          </div>
          <div class="d-flex" >
            <div
                style="min-width: 200px;"
            >
              <my-range-edit style="width: 100%" v-model="range"/>
            </div>

            <div>
              <div class="position-relative" style="margin-left: 8px">
                <select class="form-select" aria-label="검색조건"
                        v-model="search_condition"
                >
                  <option value="productName">메뉴/제품</option>
                  <option value="campaignName">캠페인</option>
                  <option v-if="false" value="targeting">타겟팅</option>
                  <option v-if="false" value="2">매체</option>
                  <option v-if="false" value="3">(광고)상품</option>
                </select>
              </div>
            </div>
            <div style="flex-grow: 1">
              <div class="position-relative" style="margin-left: 8px">

                <my-search-box
                    v-model="search_txt"
                    @focus="onfocus"
                    :options="options"
                    @selected="search_selected"
                    @onsearch2="onsearch2"
                    hint="검색어를 입력해주세요"
                />
              </div>
            </div>
            <div style="padding-left: 5px;min-width: 70px" class="d-grid">
              <button type="button"
                      @click="search"
                      class="btn btn-primary waves-effect waves-light"
              >
                검색
              </button>
            </div>
          </div>

        </div>
      </div>

      <my-table
          v-if="tableData"
          clickable
          @rowClick="rowClick"
          @sort="sort"
          :key="'tb_'+myheader_id"
          :head-id="myheader_id"
          :head="myheader"
          :head-sort="myheader_sort"
          :tableData="tableData"
          :format="myformat"
      >
        <template slot="__slot_header">

          <div class="d-flex" style="padding: 15px 0 15px 0">
            <div>
              <h4>
                <span class="title_box"/>캠페인별 반응률 및 단가 효율
              </h4>
            </div>
            <div style="margin-left: 15px">
              <div style="align-self: end;">
                <input class="form-check-input" v-model="option2" value="1" type="checkbox" id="formCheck2" name="option">
                <label class="form-check-label" for="formCheck2" style="margin-left: 5px">
                  주차 단위
                </label>
              </div>

            </div>
            <div style="margin-left: 15px">
              <div style="align-self: end;">
                <input class="form-check-input" v-model="option3" value="1" type="checkbox" id="formCheck3" name="option">
                <label class="form-check-label" for="formCheck3" style="margin-left: 5px">
                  월 단위
                </label>
              </div>

            </div>
            <div style="flex-grow: 1">

            </div>

            <button type="button"
                    v-if="user.userinfo.roleId==3"
                    style="margin-right: 10px"
                    class="btn btn-dark waves-effect waves-light"
                    @click="download"
            >
              다운로드
              <i class="fas fa-file-excel"></i>
            </button>
          </div>

        </template>

        <template slot="_slot_process" slot-scope="slotProps">
          <div style="display: flex;flex-direction: column">
            <div style="min-width: 120px">
              {{ slotProps.item.startDate }} ~ {{slotProps.item.endDate}}
            </div>
            <div class="progress animated-progess"
                 v-if="false"
                 style="min-width: 120px;margin-top: 6px"
            >
              <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </template>

        <template slot="_slot_week" slot-scope="slotProps">
          {{slotProps.item.weeklyStartDate}}
          ~
          {{slotProps.item.weeklyEndDate}}
        </template>
        <template slot="_slot_month" slot-scope="slotProps">
          {{slotProps.item.month}}
        </template>
        <template slot="_slot_views" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.views)" class="price"/>
        </template>
        <template slot="_slot_engagement" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.engagement)" class="price"/>
        </template>
        <template slot="_slot_cpm" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpm)" class="price"/>
        </template>
        <template slot="_slot_cpc" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpc)" class="price"/>
        </template>
        <template slot="_slot_cpe" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpe)" class="price"/>
        </template>
        <template slot="_slot_cpvc" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpvc)" class="price"/>
        </template>
        <template slot="_slot_cpi" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpi)" class="price"/>
        </template>
        <template slot="_slot_vtr" slot-scope="slotProps">        <template slot="_slot_cpm" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpm)" class="price"/>
        </template>

          <span v-if="slotProps.item" v-html="my_number(slotProps.item.vtr)" class="price"/>
        </template>
        <template slot="_slot_ctr" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.ctr)" class="price"/>
        </template>
        <template slot="_slot_totalBudget" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.totalBudget)" class="price"/>
        </template>
        <template slot="_slot_imps" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.imps)" class="price"/>
        </template>
        <template slot="_slot_clicks" slot-scope="slotProps">
          <span v-if="slotProps.item" v-html="my_number(slotProps.item.clicks)" class="price"/>
        </template>
        <template slot="_slot_process" slot-scope="slotProps">
          <div style="display: flex;flex-direction: column">
            <div style="min-width: 120px">
              {{ slotProps.item.startDate }} ~ {{slotProps.item.endDate}}
            </div>
            <div class="progress animated-progess"
                 v-if="false"
                 style="min-width: 120px;margin-top: 6px"
            >
              <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </template>
      </my-table>

      <detail ref="detail"/>
      <download ref="download" @do_download="do_download"/>

    </div>

  </div>
</template>
<script>
import MySearchBox from "@/components/common/MySearchBox";
import MyMultiSelect from "@/components/common/MyMultiSelect";
import MyRangeEdit from "@/common/MyRangeEdit";
import Download from "@/pages/history/download";
import detail from "@/pages/history/detail";
import MyTable from "@/components/common/MyTable";
import moment from "moment";
import {mapGetters} from "vuex";
import Gnb from "@/nav/gnb";
export default {
  components: {
    MySearchBox,
    MyMultiSelect,
    MyRangeEdit,
    Download,
    Gnb,
    MyTable,
    detail
  },
  props: {},
  mounted() {

    var flag=0

    if(this.$route.query.type != null && this.$route.query.type != undefined && this.$route.query.type != '') {
      this.search_condition=this.$route.query.type
      flag++
    }

    if(this.$route.query.value) {
      this.search_txt=this.$route.query.value
      flag++
    }
    if(this.$route.query.startDate && this.$route.query.endDate) {
      try {
        this.range['startDate'] = moment(this.$route.query.startDate)
        this.range['endDate'] = moment(this.$route.query.endDate)
        flag++
      } catch (e) {}
    }

    if(flag == 3) {
      this.search()
    } else {
      //this.search_condition = ''
      this.search_txt = ''
      this.search()
    }

    this.loadFilter()

    if(this.user.userinfo.roleId==1) {
      this.selected_advertiser = [this.user.userinfo.advertiserId]
    }
  },
  watch: {
    option2:function () {
      if(this.option2) {
        this.option3 = null
        this.search()
      }
      else
        this.search()
    },
    option3:function () {
      if(this.option3) {
        this.option2 = null
        this.search()
      }
      else
        this.search()
    },
  },
  methods:{
    onfocus:function () {
      this.onsearch()
    },
    search_selected:function (v) {
      this.search_txt = v
      this.search()
    },
    onsearch2:function (k, resolve) {

      this.search_txt = k
      var data = this.getParams()
      if(!data)
        return

      this.$http.post('/api/history/dashboard-new',data)
          .then(({ data }) => {

            var a = []
            data.forEach(el=>{
              a.push(el[this.search_condition])
            })

            //console.log(a)

            resolve(a)

          }).catch(err=>{
      })

    },
    do_download:function (name,option) {

      var data = this.getParams()
      var o = data
      o['type'] = option


      // type
      //private int option; // 1: normal, 2: weekly, 3: month
      //private int type; // 1:캠페인 효율, 2: 테이블 통합, 3: 롬스 양식(Digital)



      this.$http({
        method: 'POST',
        url: '/api/history/download/dashboard?fileName='+encodeURIComponent(name),
        //params:o,
        data:o,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name+'.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    download:function () {
      this.$refs.download.open()
    },
    my_number:function (v, fl) {
      if(fl)
        v = parseFloat(v).toFixed(2)

      try {
        v = this.numberWithCommas(v)
      } catch (e) {}

      return v
    },
    loadFilter:function () {
      this.$http.get('/api/advertiser')
          .then(({ data }) => {

            this.list_advertisers = data
          })
    },
    sort:function (k, i, asc) {
      //this.$emit('sort', i, this.sort_desc)
    },
    rowClick:function (item) {
      var url = '/api/history/dashboard/detail-new?'

      var param = ''

      param += '&productId='+item.productId
      param += '&campaignHistoryId='+item.campaignHistoryId
      //param += '&deviceTemplateId='+item.deviceTemplateId
      //param += '&adProductMediaTypeTemplateId='+item.adProductMediaTypeTemplateId
      url += param

      //url = 'http://158.247.216.6:8091/api/history/dashboard/detail-new?productId=1&campaignHistoryId=26'

      this.$http.get(url)
          .then(({ data }) => {
            this.$refs.detail.open(data, param, {
              productId:item.productId,
              campaignHistoryId:item.campaignHistoryId
            })
          })
    },
    getParams:function () {

      var v = this.selected_advertiser
      /*
      if(v == null || v.length <= 0) {
        this.$eventBus.$emit('error', '광고주를 선택해주세요.')
        return null;
      }
      */

      var data = {}
      var advertiserIds = []
      if(v) {
        v.forEach(el=>{
          advertiserIds.push(parseInt(el))
        })
      }
      data['advertiserIds'] = advertiserIds
      data['startDate'] = moment(this.range.startDate).format('YYYY-MM-DD')
      data['endDate'] = moment(this.range.endDate).format('YYYY-MM-DD')
      data[this.search_condition] = this.search_txt

      if(this.option2) {
        data['option'] = 2
      }
      else if(this.option3) {
        data['option'] = 3
      }

      return data
    },
    search:function () {

      var data = this.getParams()
      this.$http.post('/api/history/dashboard-new',data)
          .then(({ data }) => {
            this.loadData(data)
          })
    },
    loadData:function (data) {
      this.tableData = data
    }
  },
  computed: {
    myheader_sort:function () {
      if(this.option2) {

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return [
          'campaignName',
          'productName',
          'endDate',
          'weeklyStartDate',
          'totalBudget::@NUM','imps::@NUM','clicks::@NUM','views::@NUM','engagement::@NUM',
          'ctr::@NUM','vtr::@NUM','cpm::@NUM','cpc::@NUM','cpe::@NUM','cpvc::@NUM','cpi::@NUM'
        ]

      }
      else if(this.option3) {
        return ['campaignName','productName','endDate',
          'month::@NUM',
          //'weeklyStartDate',
          'totalBudget::@NUM','imps::@NUM','clicks::@NUM','views::@NUM','engagement::@NUM',
          'ctr::@NUM','vtr::@NUM','cpm::@NUM','cpc::@NUM','cpe::@NUM','cpvc::@NUM','cpi::@NUM'
        ]

      }

      return ['campaignName','productName','endDate',
        'totalBudget::@NUM','imps::@NUM','clicks::@NUM','views::@NUM','engagement::@NUM',
        'ctr::@NUM','vtr::@NUM','cpm::@NUM','cpc::@NUM','cpe::@NUM','cpvc::@NUM','cpi::@NUM'
      ]
    },
    myheader:function () {
      if(this.option2) {
        return [
          '캠페인명',
          '메뉴/제품명',
          '집행기간',
          '주차',
          '광고비(원)::@NUM','노출수::@NUM','클릭수::@NUM','조회수::@NUM','참여수::@NUM',
          'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPE(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM']
      }
      else if(this.option3) {
        return ['캠페인명','메뉴/제품명','집행기간',
          '월',
          '광고비(원)::@NUM','노출수::@NUM','클릭수::@NUM','조회수::@NUM','참여수::@NUM',
          'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPE(원)::@NUM','CPVC(원)::@NUM','(CPI(원)::@NUM']
      }
      return ['캠페인명','메뉴/제품명','집행기간',
        '광고비(원)::@NUM','노출수::@NUM','클릭수::@NUM','조회수::@NUM','참여수::@NUM',
        'CTR(%)::@NUM','VTR(%)::@NUM','CPM(원)::@NUM','CPC(원)::@NUM','CPE(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM']
    },
    myheader_id:function () {
      if(this.option2) {
        return 'history_id1'
      }
      else if(this.option3) {
        return 'history_id2'
      }

      return 'history_id3'
    },
    myformat:function () {
      if(this.option3) {
        return [
          'campaignName',
          'productName',
          '_slot_process',
            '_slot_month',
          '_slot_totalBudget','_slot_imps','_slot_clicks','_slot_views','_slot_engagement',
          '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpe','_slot_cpvc','_slot_cpi']
      }
      else if(this.option2) {
        return [
          'campaignName',
          'productName',
          '_slot_process',
          '_slot_week',
          '_slot_totalBudget','_slot_imps','_slot_clicks','_slot_views','_slot_engagement',
          '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpe','_slot_cpvc','_slot_cpi']
      }
      return [
        'campaignName', 'productName',
        '_slot_process',
        '_slot_totalBudget','_slot_imps','_slot_clicks','_slot_views','_slot_engagement',
        '_slot_ctr','_slot_vtr','_slot_cpm','_slot_cpc','_slot_cpe','_slot_cpvc','_slot_cpi']
    },
    ...mapGetters({
      user:'user'
    })
  },
  data() {
    return {
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      query:'',
      head_sort:null,
      selected_advertiser:null,
      list_advertisers:null,
      search_condition:'productName',
      search_txt:null,
      tableData:null,
      option2:null,
      option3:null,
      api_media:[],
      range: {
        startDate: new Date().setTime(new Date().getTime()-(1000*60*60*24*30*12)),
        endDate: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    }
  }
}
</script>
<style>
.vc-popover-content-wrapper {
  z-index: 1000 !important;
}
.safe {
  width: 16px;height:16px;background-color: #1cbb8c;border-radius: 100%;
  display: inline-block;
}
.danger {
  width: 16px;height:16px;background-color: #ff1717;border-radius: 100%;
}
</style>
<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 65% !important;max-width: 95% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              일자별 상세 리포트
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div style="display: flex;margin-bottom: 10px;">

              <div style="flex-grow: 1">

              </div>

              <button type="button"
                      style="margin-right: 10px"
                      class="btn btn-dark waves-effect waves-light"
                      @click="download"
              >
                다운로드
                <i class="fas fa-file-excel"></i>
              </button>
            </div>

            <div
                style="max-height: 80vh;overflow-y: auto;padding:0"
            >
              <my-table
                  :head="['날짜',
                  '매체비용(원)::@NUM',
                  '노출수::@NUM',
                  '클릭수::@NUM',
                  '조회수::@NUM',
                  '인스톨수::@NUM',
                  'CPRP::@NUM',
                  'GRPS::@NUM'
                  ]"
                  :tableData="items"
                  :format="['_slot_date','_slot_dailyCost','_slot_dailyImp','_slot_dailyClick','_slot_dailyView',
                  '_slot_dailyInstall','_slot_dailyCprp','_slot_dailyGrps'
                  ]"
                  :key="'tb_present_detail_detail'"
              >
                <template slot="_slot_date" slot-scope="slotProps">
                  <div style="display: flex;flex-direction: column">
                    <div style="min-width: 120px">
                      {{ slotProps.item.date }}
                    </div>
                  </div>
                </template>

                <template slot="_slot_dailyCost" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyCost)" class="price"/>
                </template>
                <template slot="_slot_dailyImp" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyImp)" class="price"/>
                </template>
                <template slot="_slot_dailyClick" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyClick)" class="price"/>
                </template>
                <template slot="_slot_dailyView" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyView)" class="price"/>
                </template>
                <template slot="_slot_dailyInstall" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyInstall)" class="price"/>
                </template>
                <template slot="_slot_dailyCprp" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyCprp)" class="price"/>
                </template>
                <template slot="_slot_dailyGrps" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.dailyGrps)" class="price"/>
                </template>

              </my-table>


            </div>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

  </div>
</template>

<script>
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  components: {
    MyTable,
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({

    }),
  },
  data () {
    return {
      param:null,
      param2:null,
      items:[],
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      },
      businessOptions: [],
    }
  },
  methods: {
    download:function () {
      //
      this.$emit('download')
    },
    my_number:function (v, fl) {
      if(fl)
        v = parseFloat(v).toFixed(2)

      try {
        v = this.numberWithCommas(v)
      } catch (e) {}

      return v
    },
    init:function () {
    },
    open:function (data, param, param2) {

      this.param = param
      this.param2 = param2
      this.items = data
      this.reset()
      this.dlg_open = true
      this.init()
    },
    onSubmit() {

    },
    reset() {
    }
  },
  watch: {
  }
}
</script>

<template>
  <div class="mbg text-center" style="padding-top:12px;width: 55px;min-width: 55px;font-size: 21px">
    <i @click="menu" class="fa fa-solid fa-bars cursor-pointer">
    </i>
  </div>
</template>
<script>
import subMenu from "@/nav/subMenu";
import { MENU_LIST } from '@/constants'
import {mapGetters} from "vuex";

export default {
  name: 'snb2',
  computed: {
    ...mapGetters({
      mediacnt:'mediacnt'
    }),
  },

  //mediacnt
  components:{
  },
  methods:{
    menu:function () {
      this.$emit('onmenu')
    }
  }
}
</script>
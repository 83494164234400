import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from "vue-cookies"



//import '/assets/VueSearchSelect.css'
import 'vue-search-select/dist/VueSearchSelect.css'


import axios from 'axios'

import VueLoading from 'vuejs-loading-plugin'
// using default options
Vue.use(VueLoading)

import draggable from 'vuedraggable'
Vue.component('draggable',draggable)

import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.component('date-range-picker',DateRangePicker)

import 'tui-grid/dist/tui-grid.css'
import {Grid} from '@toast-ui/vue-grid'
Vue.component('grid',Grid)

Vue.prototype.$http = axios
Vue.prototype.$merror = {
            status:0,
            msg:''
        }
Vue.prototype.numberWithCommas=function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
Vue.prototype.$getAdvertisers = function () {

    return this.$http.get('/api/advertiser')
        .then(({ data }) => {
            var advertisers = []
            data.map(v => advertisers.push({ name: v.advertiserName, value: v.advertiserId}))
            store.commit('advertisers',  advertisers)
        })
        .catch(error => {
            console.log(error)
        })
}

import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


axios.interceptors.response.use(
    (response) => {
        return response;},
    function (error) {

        if(error.response.status == 401) {
            //alert('login error')
            //store.state.axios_error_status = '조회 권한에 문제가 있습니다.'
            store.commit('axios_error_status',  '조회 권한에 문제가 있습니다.')

            //console.log(store.state)
        }

        return Promise.reject(error.response.data);
    }
);


import App from './App.vue'
import router from '@/router'
import store from '@/store'

//import common from '@/common/common'
Vue.use(VueCookies)
Vue.$cookies.config("1d")
Vue.use(VueRouter)

//import Suggestions from 'v-suggestions'
//import 'v-suggestions/dist/v-suggestions.css' // you can import the stylesheets also (optional)
//Vue.use(Suggestions)

Number.prototype.price = function (w) {
    //if (this == 0) return 0;


    var isFloat=function(n){
        return Number(n) === n && n % 1 !== 0;
    }

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = (this + '');

    if(isFloat(this)) {
        n = this.toFixed(2)
        n += ''
    }

    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
    if (w != null)
        return n + " <span class='won'>" + w + "</span>";
    else
        return n
};


Vue.prototype.$deepCopy=function(o) {
    var a = JSON.stringify(o)
    return JSON.parse(a)
}


const eventBus = {}
eventBus.install = function (Vue) {
    Vue.prototype.$eventBus = new Vue()
}
Vue.use(eventBus)


import vTitle from 'vuejs-title'
Vue.use(vTitle)

Vue.config.productionTip = false
new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
      >

        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              미디어 믹스 불러오기
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="card border border-primary" style="overflow: visible">
              <div class="card-header bg-transparent border-primary">

                <div class="d-flex">
                  <input class="form-control" type="text" placeholder="믹스명을 입력해주세요."
                         v-model="params.mediaMixName"
                  >
                  <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
                    <button type="button" class="btn btn-primary waves-effect waves-light"
                            @click="search"
                    >
                      검색
                    </button>
                  </div>
                </div>

              </div>
            </div>

            <div
                style="max-height: 400px;overflow-y: scroll;padding:0;width: 100%"
            >
              <my-table
                  @onpage="onpage"
                  v-if="mix_data"
                  :head="['미디어 믹스명','등록일','선택']"
                  :tableData="mix_data"
                  :format="['mediaMixName','createAt','_slot_select']"
                  :pageParam="pageParam"
                  :page="true"
              >

                <template slot="_slot_range" slot-scope="slotProps">
                  {{slotProps.item.startDate}} ~ {{slotProps.item.endDate}}
                </template>
                <template slot="_slot_select" slot-scope="slotProps">
                  <button type="button" class="btn btn-danger waves-effect waves-light"
                          @click="select(slotProps.item)"
                  >
                    선택
                  </button>
                </template>
              </my-table>

            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    @click="onSubmit"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
import store from "@/store";
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
    MyTable
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({

    }),
  },  data () {
    return {
      page:0,
      mix_data:null,
      pageParam:null,
      dlg_open:false,
      params: {
        mediaMixName: '',
      },
    }
  },
  methods: {
    onpage:function (p) {
      this.page = p
      this.load()
    },
    select:function (item) {

      var url = '/api/media-mix/detail?mediaMixId='+item.mediaMixId
      this.$http.get(url)
          .then(({ data }) => {

            var res = data.mediaTargetList
            this.$emit('selected', res)
            this.dlg_open = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    search:function () {

      // mediaMixName
      this.load()

    },
    open:function () {
      this.reset()
      this.dlg_open = true

      this.init()
    },
    onSubmit() {

    },
    reset() {
      this.params = {
        mediaMixName: '',
      }
    },
    init:function () {
      this.load()
    },
    load:function () {
      var url = '/api/media-mix/list'
      url += '?page='+this.page
      url += '&mediaMixName='+encodeURIComponent(this.params.mediaMixName)

      this.$http.get(url)
          .then(({ data }) => {
            this.mix_data = data.content

            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  watch: {
  }
}
</script>

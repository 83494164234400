<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 85% !important;max-width: 85% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              매체/상품 등록 요청
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body"
               style="max-height: 400px;overflow-y: scroll"
               v-if="params"
          >
            <div>
              <table class="table" style="width: 100%">
                <tbody>
                <tr>
                  <th scope="row">매체 유형</th>
                  <td>
                    <select class="form-select" aria-label="디바이스 (Device)"
                            v-model="params.mediaTypeTemplateId"
                            disabled
                    >
                      <option
                          v-for="item in first_media_type.mediaType"
                          :key="item.templateId"
                          :value="item.templateId">{{ item.displayName }}</option>
                    </select>

                  </td>
                </tr>
                <tr>
                  <th scope="row">매체명</th>
                  <td>
                    <input class="form-control" type="text" placeholder="매체명"
                           v-model="params.channelNameKo"
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row">매체명(영문)</th>
                  <td>
                    <input class="form-control" type="text" placeholder="매체명(영문)"
                           v-model="params.channelNameEn"
                    >
                  </td>
                </tr>
                </tbody>
              </table>


              <div
                  style="padding: 8px 0">
                <div style="font-weight: bold;color: #4d545e;padding-bottom: 8px">
                  광고 상품 대량 등록
                </div>

                <div style="padding: 12px;background-color:white;border-radius: 8px">
                  <div class="d-grid mb-2">
                    <button type="button" class="btn btn-light waves-effect"
                            @click="download"
                    >
                      대량 등록 템플릿 다운로드
                      <i style="padding-left: 5px" class="fas fa-file-download"></i>
                    </button>
                  </div>

                  <div class="d-grid">
                    <div class="input-group">
                      <input type="file" class="form-control" id="customFile"
                             ref="file"
                             @change="handleFileUpload"
                      >
                    </div>
                  </div>
                </div>


              </div>

              <media-management-input
                  style="padding: 20px 0"
                  @input="input"
                  :my_media_type="my_media_type" :key="my_media_type+'_input'"
              />

              <media-management
                  ref="ad_product"
                  :my_media_type="my_media_type" :key="my_media_type"
                  :adProductList="params.adProductList"/>

            </div>

          </div>

          <div class="modal-footer" v-if="rolePriority==3">

            <button type="button"
                    @click="modify"
                    style="margin-right: 15px"
                    class="btn btn-light waves-effect waves-light"
            >
              수정
            </button>


            <button type="button"
                    @click="approve"
                    class="btn btn-primary waves-effect waves-light"
            >
              승인
            </button>
            <button type="button" class="btn btn-danger waves-effect"
                    @click="reject"
            >
              반려
            </button>
          </div>

          <div class="modal-footer" v-else>


            <button type="button"
                    @click="modify"
                    class="btn btn-primary waves-effect waves-light"
            >
              수정 요청
            </button>


            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
  </div>
</template>

<script>
import MediaManagementInput from "@/pages/setting/system/media/mediaManagementInput";
import mediaManagement from "@/pages/setting/system/media/mediaManagement";
import myError from "@/common/myError"
import {
  GET_MEDIA_DETAIL, MEDIA_ACTIVATE, MEDIA_REJECT, MEDIA_MODIFY
} from './constants.js'
import {mapGetters} from "vuex";
export default {
  mounted() {
  },
  computed: mapGetters({
    user:'user',
    first_media_type: 'first_media_type',
    rolePriority:'rolePriority'
  }),
  mixins:[myError],
  name: 'detailModal',
  components: {
    mediaManagement,
    MediaManagementInput
  },
  props: {
  },
  data () {
    return {
      file:null,
      my_media_type:'',
      mediaId:null,

      dlg_open:false,
      params:null,
      params2: {
        channelNameEn:null,
        channelNameKo:null,
        mediaTypeTemplateId:null,
        "adProductList": [
        ],
        "status": 1, // 1: 승인대기, 2: 승인완료, 3. 반려, 4: 사용중
        "statusMessage": null // 반려시 메시지가 존재
      },
      businessOptions: [],
    }
  },
  methods: {
    handleFileUpload:function () {
      this.file = this.$refs.file.files[0];
    },
    download:function () {
      this.$http({
        method: 'GET',
        url: '/api/media/download/ad-product-template',
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    input:function (v) {
      this.$refs.ad_product.add_item(v, true)
    },
    modify:function () {

      // 파일로 입력 (수정필요)
      if(this.file) {

        var url = '/api/media/modify-with-template?'
        //url += '&mediaTypeTemplateId='+this.params.mediaTypeTemplateId
        //url += '&channelNameEn='+encodeURIComponent(this.params.channelNameEn)
        //url += '&channelNameKo='+encodeURIComponent(this.params.channelNameKo)

        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('mediaId', this.params.mediaId);
        formData.append('mediaTypeTemplateId', this.params.mediaTypeTemplateId);
        formData.append('channelNameEn', this.params.channelNameEn);
        formData.append('channelNameKo', this.params.channelNameKo);

        this.$http.put(url,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(() => {
              this.$eventBus.$emit('info','수정 되었습니다.')
              this.dlg_open = false
              this.$emit('updated')
            })
            .catch(error => {
              //this.toastr('warning', error.response.data.errorMessage)
              this.$eventBus.$emit('error',error.errorMessage)
            })
      } else {
        // put
        this.$http.put(MEDIA_MODIFY, this.params)
            .then(({ data }) => {
              this.$eventBus.$emit('info','수정 되었습니다.')
              this.dlg_open = false
              this.$emit('updated')
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })
      }

    },
    approve:function () {

      // put
      this.$http.put(MEDIA_ACTIVATE+'?mediaId='+this.mediaId)
          .then(({ data }) => {
            this.$eventBus.$emit('info','승인 되었습니다.')
            this.dlg_open = false
            this.$emit('updated')
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.responseMessage)
          })
    },
    reject2:function (msg) {

      // put
      this.$http.put(MEDIA_REJECT,{ 'mediaId': this.mediaId, rejectMessage:msg })
          .then(({ data }) => {
            this.$eventBus.$emit('info','반려 되었습니다.')
            this.dlg_open = false
            this.$emit('updated')
          })
          .catch(error => {
            this.$eventBus.$emit('error',error.responseMessage)
          })
    },
    reject:function () {

      var that = this
      this.$eventBus.$emit('dlg_prompt', '반려 사유', '반려 사유를 입력해주세요.', function (v) {
        that.reject2(v)
      })
    },
    open:function (mediaId) {

      this.mediaId = mediaId
      this.reset()
      this.dlg_open = true

        this.$http.get(GET_MEDIA_DETAIL, { 'params': { 'mediaId': mediaId } })
            .then(({ data }) => {

              this.params = data

              this.first_media_type.mediaType.forEach(el=>{
                if(el.templateId==this.params.mediaTypeTemplateId) {
                  this.my_media_type = el.displayName.toLowerCase()
                }
              })

            })
            .catch(error => {
            })
    },
    onSubmit() {

    },
    reset() {
      this.params = null
    }
  },
  watch: {
  }
}
</script>

<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 300px !important;max-width: 95% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              캠페인 상태 변경
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div
                style="max-height: 400px;padding:0"
            >

              <div style="display: flex;padding: 10px 0">
                <div style="display: flex;width: 100%">
                  <div style="flex: 1">
                    <div style="display: flex;    align-items: center;">
                      <input type="radio"
                             v-model="mstatus"
                             name="status" value="1" style="margin-right: 5px"/>
                      <div class="safe"></div>
                      &nbsp;안전
                    </div>
                  </div>
                  <div style="flex: 1">
                    <div style="display: flex;    align-items: center;">
                      <input type="radio"
                             v-model="mstatus"
                             name="status" value="2" style="margin-right: 5px"/>
                      <img src="/img/bulb_icon.png" style="width: 16.5px"/>
                      &nbsp;주의
                    </div>
                  </div>
                </div>

              </div>

              <div v-if="mstatus == '2'">
                <input type="text" class="form-control" placeholder="주의 내용"
                       v-model="comment"
                >
              </div>
            </div>

          </div>


          <div class="modal-footer">
            <button type="button"
                    @click="ok"
                    class="btn btn-primary waves-effect waves-light"
            >
              확인
            </button>

            <button type="button"
                    @click="dlg_open=false"
                    class="btn btn-light waves-effect waves-light"
            >
              취소
            </button>

          </div>
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

  </div>
</template>

<script>
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  name: 'registerModal',
  components: {
  },
  props: {
    subitem:{
      default:null,
      type:Boolean
    }
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({

    }),
  },
  data () {
    return {
      myid:null,
      mediaid:null,
      comment:'',
      mstatus:1,
      api_media:[],
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      },
      businessOptions: [],
    }
  },
  methods: {
    ok:function () {
      var param = {
        campaignId:this.myid,
        status:this.mstatus,
        mediaId:this.mediaid,
        statusMessage:this.comment
      }

      if(this.subitem) {
        this.$http.post('/api/campaign/change/media-status', param)
            .then( ({data}) => {

              this.$eventBus.$emit('info','상태가 수정되었습니다.')
              this.dlg_open = false
              this.$emit('updated')
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })

      } else {
        this.$http.post('/api/campaign/change/status', param)
            .then( ({data}) => {

              this.$eventBus.$emit('info','상태가 수정되었습니다.')
              this.dlg_open = false
              this.$emit('updated')
            })
            .catch(error => {
              this.$eventBus.$emit('error',error.errorMessage)
            })
      }

    },
    init:function () {
    },
    open:function (item) {
      this.reset()
      this.dlg_open = true

      this.myid = item.campaignId
      this.mediaid = item.mediaId
      this.mstatus = item.status
      this.comment = item.statusMessage

      this.init()
    },
    onSubmit() {

    },
    reset() {
    }
  },
  watch: {
  }
}
</script>

<style>
.safe {
  width: 16px;height:16px;background-color: #1cbb8c;border-radius: 100%;
  display: inline-block;
}
.danger {
  width: 16px;height:16px;background-color: #ff1717;border-radius: 100%;
}
</style>
<template>
  <div class="snb">
    <ul class="text-left"
        :style="level!=0?'padding-left:20px':''"
    >
      <li
          v-for="item in list"
          :key="item.path"
          class="nav-item"
          style="position: relative"
          v-on:mouseover="focusin_menu(item)"
          v-if="!item.hide"
      >
        <div style="position: absolute;top:9px;font-size:14px;left:-5px;font-weight: bold" class="level2" v-if="drawSelected(item)">ㄴ</div>

        <div
            v-on:mouseup="clk(item)"
        >
          <router-link
              :class="getMenuClass(item)"
              :to="item.path"
              :style="item.bold?'font-weight:bold':''"
          >
          <span
          >{{ item.name }}

            <span
                v-if="item.name=='매체/상품' && mediacnt"
            >
              <i class="fas fa-bell"
                 v-title="'승인이 필요한 매체/상품이 있습니다.'"
              ></i>
            </span>
          </span>
          </router-link>
        </div>

        <sub-menu
            @focusin_menu="focusin_menu"
            @close="close"
            :level="level+1"
            class="submenu2"
            :key="item.path+$route.path"
            v-if="item.selected && item.subMenu && item.subMenu.length>0"
            :list="item.subMenu"/>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name:'sub-menu',
  computed:{
    ...mapGetters({
      user:'user',
      mediacnt:'mediacnt'
    }),
  },
  components:{
  },
  props:{
    list:{
      type:Array,
      default:null
    },

    level:{
      type:Number,
      default:0
    }
  },
  activated() {
    console.log('aaaa')
        /*
    <i
    className = "fas fa-bell" > < /i>
         */
  },
  methods:{
    clk:function (item) {

      this.$eventBus.$emit('menu_close')

      // route-link 따라 이동한다. 별도 처리 안함 (버그인듯)
      this.$router.push({path:item.path})
    },
    focusin_menu:function (item) {
      this.$emit('focusin_menu', item)
    },
    drawSelected:function (item) {
      if(this.level==0)
        return false

      if(item.selected)
        return true
      return false
    },
    getMenuClass:function (item) {

      if(this.level==0) {
        if (item.selected)
          return 'nav-active'

        return ''
      }
      return 'level2'
    }
  },
  mounted() {
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
ul {
  padding-right: 0;
}
.level2 {
  color: #8d9bad !important;
}
.sub-title {
  display: flex;
  justify-content: flex-start;
  padding: 10px 30px;
  padding-top: 30px;
  color: #333;
  font-size: 19px;
  width: 180px;
}
.snb {
}
.snb ul {
  list-style: none;
  padding-left: 37px;
}
.snb ul li {
  margin: 2px 0;
  padding: 5px 8px;
}
.snb ul li:hover {
  color: #4f6c96;
  cursor: pointer;
}
.nav-item a {
  padding-left: 12px;
  display: block;
  color: white;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}
a.nav-active {
  color: white;
  border-left: 3px solid white;
}
.submenu2 {
  padding-top: 10px;
}
</style>
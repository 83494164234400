export const MENU_LIST = [
    {
        bold: true,
        code: 'INTRO',
        name: 'Intro',
        ico: '/img/ico_home.svg',
        tabId: 0,
        path: '/intro',
        subMenu: []
    },
    {
        bold: true,
        code: 'HISTORY',
        name: 'History',
        ico: '/img/ico_history.svg',
        tabId: 1,
        path: '/history',
        subMenu: []
    },
    {
        bold: true,
        code: 'PRESENT',
        name: 'Present',
        ico: '/img/ico_present.svg',
        tabId: 2,
        path: '/present',
    },
    {
        bold: true,
        code: 'FORECAST',
        name: 'Forecast',
        ico: '/img/ico_forecast.svg',
        tabId: 3,
        path: '/forecast',
        subMenu: []
    },
    {
        bold: true,
        code: 'SETTING',
        name: 'Setting',
        ico: '/img/ico_setting.svg',
        tabId: 4,
        path: '/setting',
        subMenu: [
            {
                code: 'PRESENTDB',
                bold: true,
                name: 'PresentDB',
                path: '/setting',
                subMenu: [
                    {
                        bold: true,
                        code: 'PRESENT_MANAGEMENT',
                        name: '캠페인 관리',
                        path: '/setting/presentDB',
                    },
                    {
                        code: 'API',
                        bold: true,
                        name: 'API 연동 매체',
                        path: '/setting/system/mapi',
                    },
                    {
                        hide: true,
                        code: 'API',
                        bold: true,
                        name: 'API 연동 매체 등록',
                        path: '/setting/system/mapi/register',
                    },
                ]
            },
            {
                code: 'HISTORYDB',
                bold: true,
                name: 'HistoryDB',
                path: '/setting/historyDB',
                subMenu: []
            },
            {
                bold: true,
                code: 'SYSTEM',
                name: 'System',
                path: '/setting/system',
                subMenu: [
                    {
                        code: 'ADVERTISER',
                        bold: true,
                        name: '거래처',
                        path: '/setting/system/advertiser',
                        subMenu: []
                    },
                    {
                        code: 'MEDIA/PRODUCT',
                        name: '매체/상품',
                        bold: true,
                        path: '/setting/system/mediaProduct',
                        path_alt: ['/setting/system/mediaProduct/detail'],
                        subMenu: []
                    },
                ]
            }
        ]
    }
]
<template>
  <div>
    <gnb class="mbg">
      <template  slot="desc">
        신규 매체 및 광고 상품 등록을 요청합니다.

        <div class="pt-4">
          <button type="button" class="btn btn-primary waves-effect waves-light"
                  @click="$refs.modal.open()"
          >
            매체/상품 등록 요청
          </button>
        </div>
      </template>
    </gnb>

    <div class="mcontent">

      <div class="card border border-primary" style="overflow: visible">
        <div class="card-header bg-transparent border-primary">
          <div class="d-flex" >

            <my-search-box
                style="flex: 1"
                v-model="query"
                @focus="onfocus"
                :options="options"
                @selected="search_selected"
                @onsearch2="onsearch2"
                hint="검색어를 입력해주세요."
            />
            <div style="min-width: 80px;margin-left: 5px;" class="d-grid">
              <button type="button"
                      @click="search"
                      class="btn btn-secondary waves-effect waves-light">
                <i class='fal fa-search' style="margin-right: 3px"></i>
                검색
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 10px">
        검색결과 : {{totalElements}}건
      </div>

      <div class="mt-4">

        <my-table
            @onpage="onpage"
            :head="['매체명 (Media)','매체 유형 (Channel Group)','상품 수','등록일','상태','상세보기::@CENTER']"
            :tableData="tableData"
            :format="['_slot_name','_slot_channel_group','_slot_count','_slot_date','_slot_status','_slot1']"
            :pageParam="pageParam"
        >
          <template slot="_slot_channel_group" slot-scope="slotProps">
            {{get_mediaTypeTemplateName(slotProps.item.mediaTypeTemplateId)}}
          </template>
          <template slot="_slot_name" slot-scope="slotProps">
            {{slotProps.item.channelNameKo}}
            <br/>
            ({{slotProps.item.channelNameEn}})
          </template>
          <template slot="_slot1" slot-scope="slotProps">
              <i class="fas fa-search align-self-center" style="cursor: pointer" @click="clkView(slotProps.item)"></i>
          </template>
          <template slot="_slot_date" slot-scope="slotProps">
            {{getDate(slotProps.item)}}
          </template>
          <template slot="_slot_count" slot-scope="slotProps">
            {{slotProps.item.adProductList.length}}
          </template>
          <template slot="_slot_status" slot-scope="slotProps">
            {{STATUS[slotProps.item.status]}}
            <i @click="info_status(slotProps.item)" v-if="slotProps.item.status==3" style="cursor: pointer" class="fad fa-info-circle"></i>
          </template>
        </my-table>

      </div>
    </div>

    <register-modal ref="modal" @updated="load"/>
    <media-detail ref="detail" @updated="load"/>
  </div>
</template>

<script>
import MySearchBox from "@/components/common/MySearchBox";
import Gnb from "@/nav/gnb";
import MyTable from "@/components/common/MyTable";
import registerModal from './register'
import mediaDetail from "@/pages/setting/system/media/mediaDetail";
import moment from 'moment'
import {
  GET_MEDIA_LIST,
} from './constants.js'
import {mapGetters} from "vuex";
// 검색안됨
// 상태 변이 필요에 따라 버튼 숨김 필요함
// 반려 => ?
// 승인 => ?
// 수정 => ?
export default {
  computed: {
    ...mapGetters({
      STATUS: 'STATUS',
      first_media_type: 'first_media_type',
    }),
  },
  components: {
    MySearchBox,
    Gnb,
    MyTable,
    mediaDetail,
    registerModal
  },
  data() {
    return {
      options:{
        placeholder:'검색어를 입력해주세요.',
        inputClass:'form-control'
      },
      query:null,
      totalElements:0,
      tableData:null,
      pageParam:null,
      page:0,
      params: {
        campaignName: ''
      },
      advertiserId: null
    }
  },
  created() {
  },
  mounted() {
    this.load()
  },
  methods: {
    onfocus:function () {
      this.onsearch()
    },

    search_selected:function (v) {
      this.query = v
      this.search()
    },
    onsearch2:function (k, resolve) {
      this.query = k

      var url = GET_MEDIA_LIST+'?page='+this.page
      if(this.query) {
        url += '&searchName='+encodeURIComponent(this.query)
      }

      this.$http.get(url)
          .then(({ data }) => {

            var arr = data.content
            var a = []
            arr.forEach(el=>{
              a.push(el.channelNameKo)
            })

            resolve(a)
          }).catch(err=>{
      })
    },
    search:function () {
      this.page=0
      this.load()
    },
    get_mediaTypeTemplateName:function (id) {

      for(var i in this.first_media_type.mediaType) {
        var el = this.first_media_type.mediaType[i]
        if(el.templateId == id) {
          return el.displayName
        }
      }
    },
    info_status:function (item) {
      //item.statusMessage
      this.$eventBus.$emit('dlg_alert','반려 사유',item.statusMessage)
    },
    getDate:function (item) {
      return moment(item.createdAt).format('YYYY-MM-DD')
    },
    clkView:function (item) {
      this.$refs.detail.open(item.mediaId)
    },
    onpage:function (p) {
      this.page = p
      this.load()
    },
    load:function () {
      var url = GET_MEDIA_LIST+'?page='+this.page
      if(this.query) {
        url += '&searchName='+encodeURIComponent(this.query)
      }

      this.$http.get(url, this.params)
          .then(({ data }) => {

            this.totalElements = data.totalElements

            this.tableData = data.content
            this.pageParam = {}
            this.pageParam['number'] = data.number
            this.pageParam['totalPages'] = data.totalPages

          })
          .catch(error => {
          })
    },
  }
}
</script>
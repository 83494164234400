<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog">
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              계정 사용자 초대
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <block-tab
                v-model="mytab"
                :tabs="[{name:'계정 추가', id:0},{name:'계정 관리', id:1}]"
            />

            <div v-if="mytab==0">

              <div class="row" style="height: 60px;align-items:center">
                <radio-list
                    v-model="selected_type"
                    :list="[{name:'Team member',id:0},{name:'Client',id:1}]"
                />
              </div>

              <hr style="margin-top: 0"/>

              <div class="form-control"
                   v-if="selected_type==0 && users.length>0"
                   style="max-height: 180px;overflow-y: scroll;padding:0"
              >

                <div v-for="(item,idx) in users" :key="idx"
                     :style="users.length-1==idx?'padding:8px 10px;':'padding:8px 10px;border-bottom:1px #ced4da solid'"
                >
                  <div class="d-flex">
                    <div style="flex: 2;padding-left: 12px" class="align-self-center">
                      {{ item.email }}
                    </div>
                    <div style="flex: 1;padding-left: 20px" class="align-self-center">
                      {{ item.name }}
                    </div>
                    <div style=";padding-left: 8px">
                      <input class="btn btn-danger" type="button" value="삭제" @click="del_user(item)">
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-control"
                   v-if="selected_type==1 && users_client.length>0"
                   style="max-height: 180px;overflow-y: scroll;padding:0"
              >

                <div v-for="(item,idx) in users_client" :key="idx"
                     :style="users_client.length-1==idx?'padding:8px 10px;':'padding:8px 10px;border-bottom:1px #ced4da solid'"
                >
                  <div class="d-flex">
                    <div style="flex: 2;padding-left: 12px" class="align-self-center">
                      {{ item.email }}
                    </div>
                    <div style="flex: 1;padding-left: 20px" class="align-self-center">
                      {{ item.name }}
                    </div>
                    <div style=";padding-left: 8px">
                      <input class="btn btn-danger" type="button" value="삭제" @click="del_user(item)">
                    </div>
                  </div>
                </div>

              </div>


              <div class="d-flex" style="margin-top: 20px">
                <div style="flex: 2">
                  <input class="form-control" type="text" placeholder="사용자 이메일 주소를 입력해주세요."
                         v-model="new_user.email"
                         @keydown.enter="add_user"
                  >
                </div>
                <div style="flex: 1;padding-left: 8px" v-if="selected_type==1">
                  <select class="form-select" aria-label="소속회사"
                          v-model="new_user.advertiserId"
                  >
                    <option :value="null">소속회사</option>
                    <option
                        v-for="item in advertisers"
                        :key="item.value"
                        :value="item.value">{{item.name}}</option>
                  </select>
                </div>
                <div style="flex: 1;padding-left: 8px" v-else>
                  <select class="form-select" aria-label="소속회사"
                          v-model="new_user.advertiserId"
                  >
                    <option :value="null">{{user.userinfo.companyName}}</option>
                  </select>
                </div>
                <div style=";padding-left: 8px">
                  <input class="btn btn-info" type="button" value="추가"
                         @click="add_user()"
                  >
                </div>
              </div>


              <div class="d-flex mt-2">
                <div style="width: 100px">
                  <select class="form-select" aria-label="권한"
                          v-model="selected_role"
                  >
                    <option
                        v-for="(item,idx) in first_media_type.userRole"
                        v-if="user.userinfo.roleId>=item.roleId"
                        :key="idx"
                        :value="item.roleId">{{ item.roleName }}</option>
                  </select>
                </div>
                <div class="form-check align-self-center" style="margin-left: 8px"  v-if="false">
                  <input class="form-check-input" type="checkbox" id="formCheck2">
                  <label class="form-check-label" for="formCheck2">
                    사용자에게 알림 메일 발송
                  </label>
                </div>
              </div>

            </div>

            <div v-if="mytab==1">

              <div class="row" style="height: 60px;align-items:center">
                <radio-list
                    v-model="selected_type"
                    :list="[{name:'Team member',id:0},{name:'Client',id:1}]"
                />
              </div>

              <hr style="margin-top: 0"/>

              <div
                  style="max-height: 180px;overflow-y: scroll;padding: 10px 0"
              >
                <div v-for="(item,idx) in my_users_list" :key="idx"
                     style="margin-bottom: 8px;">
                  <div class="d-flex"
                  >
                    <div style="flex: 2;padding-left: 12px" class="align-self-center">
                      {{ item.userId }}
                    </div>
                    <div style="flex: 1;padding-left: 20px" class="align-self-center">

                      <select class="form-select" aria-label="권한"
                              v-model="item.roleId"
                      >
                        <option
                            v-for="(item,idx) in first_media_type.userRole"
                            :key="idx"
                            :value="item.roleId">{{ item.roleName }}</option>
                      </select>

                    </div>
                    <div style=";padding-left: 8px">
                      <input class="btn btn-info" type="button" value="저장" @click="save_user(item)">
                      <input class="btn btn-danger"
                             style="margin-left: 4px"
                             type="button" value="삭제" @click="del_user2(item)">
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>

          <div class="modal-footer" v-if="mytab==0">
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              취소
            </button>
            <button type="button"
                    class="btn btn-primary waves-effect waves-light"
                    @click="invite"
            >초대
            </button>
          </div>

          <div class="modal-footer" v-else>
            <button type="button" class="btn btn-light waves-effect"
                    @click="dlg_open=false"
            >
              닫기
            </button>
          </div>
        </div><!-- /.modal-content -->

      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>
<script>
import RadioList from "@/components/common/RadioList";
import BlockTab from "@/components/common/BlockTab";
import moment from "moment";
import {mapGetters} from "vuex";
export default {
  props: {
    value: {
      default: null
    }
  },
  computed: {
    my_users_list:function () {
      if(this.selected_type==0)
        return this.my_users

      return this.my_users_client
    },
    ...mapGetters({
      user:'user',
      advertisers:'advertisers',
      first_media_type:'first_media_type'
    }),
  },
  components:{
    RadioList,
    BlockTab
  },
  data () {
    return {
      selected_role:1,
      mytab:0,
      selected_type:0,

      my_users:[],
      my_users_client:[],

      users:[],
      users_client:[],

      new_user:{
        email:'',
        group:'',
        advertiserId:null
      },
      dlg_open:false,
      modalTitle: '계정 사용자 초대',
      apiUrl: 'xxx',
      pass1:'',
      pass2:'',
    }
  },
  watch:{
    mytab:function () {

      // 조회
      if(this.mytab==1) {
        this.$http.get('/api/user/list')
            .then(({ data }) => {
              var a = data.content

              this.my_users = []
              this.my_users_client = []

              a.forEach(el=>{

                if(el.userType==1) {
                  this.my_users.push(el)
                } else {
                  this.my_users_client.push(el)
                }
              })
            })
      }
    }
  },
  mounted() {
    //console.log(this.useruserinfo)
    //console.log(this.first_media_type.userRole)
  },
  methods: {
    invite:function () {

      var err = false
      if(this.selected_type==0) {
        if(this.users.length==0) {
          err = true
        }
      } else {
        if(this.users_client.length==0) {
          err = true
        }
      }

      if(err) {
        this.$eventBus.$emit('error', '초대할 사용자가 없습니다.')
        return
      }

      var id = this.selected_role
      var v = this.first_media_type.userRole.find(item=>item.roleId==id).roleName

      var permission = '<b>'+v+'</b>'
      var that = this
      this.$eventBus.$emit('dlg_confirm', '사용자 초대', '입력된 이메일 주소 모두를 ' + permission + ' 권한으로 초대합니다.<br/>계속 하시겠습니까?',
          function () {
            //alert(this.selected_role)
            that.invite2()
          })
    },
    invite2:function () {
      var params = []

      if(this.selected_type==0) {
        this.users.forEach(el=>{
          el.userId = el.email
          el.roleId = this.selected_role
          el.emailSent = 1
          el.userType = 1
          params.push(el)
        })
      } else {
        this.users_client.forEach(el=>{
          el.userId = el.email
          el.roleId = this.selected_role
          el.emailSent = 1
          el.userType = 2
          params.push(el)
        })
      }

      this.$http.post('/api/user/signup', {user:params})
          .then(({data})=>{
            this.$eventBus.$emit('info', '사용자를 초대하였습니다.')

            if(this.selected_type==0) {
              this.users=[]
            } else {
              this.users_client=[]
            }
          }).catch(err=>{
        console.log(err)
      })
    },
    add_user:function () {

      const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
      };

      var b = validateEmail(this.new_user.email)
      if(!b) {
        this.$eventBus.$emit('error', '이메일 주소를 정확하게 입력해주세요.')
        return
      }

      if(this.selected_type==0) {
        // clone
        var o = {}
        o['email'] = this.new_user.email
        o['name'] = this.user.userinfo.companyName

        this.users.push(o)
        this.new_user.email = ''

      } else {
        // clone
        if(!this.new_user.advertiserId) {
          this.$eventBus.$emit('error', '소속을 입력해주세요.')
          return
        }

        var o = {}
        var v = this.new_user.advertiserId
        o['email'] = this.new_user.email
        o['name'] = this.advertisers.find(item=>item.value==v).name
        o['advertiserId'] = this.new_user.advertiserId

        this.users_client.push(o)
        this.new_user.email = ''
      }
    },
    save_user:function (item) {
      //Put http://localhost:8091/api/user/modify-role?roleId=2
      var url = '/api/user/modify-role?userId=' + item.userId + '&roleId='+item.roleId

      this.$http.put(url)
          .then(({data}) => {
            this.$eventBus.$emit('info', '권한을 변경하였습니다.')
          }).catch(error=>{
        this.$eventBus.$emit('error', error.errorMessage)
      })

    },
    del_user2:function (item) {
      var url = '/api/user/delete?userId=' + item.userId
      //삭제: http://localhost:8091/api/user/delete?userId=test@email.com

      var u = this.my_users_list

      this.$http.delete(url)
          .then(({data}) => {

            for (var i = 0; i < u.length; i++) {
              var el = u[i]
              if (el == item) {
                u.splice(i, 1)
                break
              }
            }
            this.$eventBus.$emit('info', '사용자를 삭제하였습니다.')

          }).catch(error => {
        this.$eventBus.$emit('error', error.errorMessage)
      })
    },
    del_user:function (item) {

      var u = this.users
      for (var i = 0; i < u.length; i++) {
        var el = u[i]
        if (el == item) {
          u.splice(i, 1)
          break
        }
      }

      u = this.users_client
      for (var i = 0; i < u.length; i++) {
        var el = u[i]
        if (el == item) {
          u.splice(i, 1)
          break
        }
      }
    },
    confirm () {

    },
    open () {

      // 광고주 목록
      this.$getAdvertisers()

      this.dlg_open = true
    },
    requestChange () {

    }
  },
}
</script>

<style>
.th-width {
  width: 150px;
  vertical-align: middle;
}
.td-width {
  padding:1em;
}
</style>
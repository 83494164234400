<template>
  <div class="d-flex justify-content-center">
    <ul class="pagination pagination-rounded">

      <li v-if="e1" :class="e1?'paginate_button page-item previous':'paginate_button page-item previous disabled'"
      >
        <a
            style="line-height:15px"
            @click="p1" aria-controls="datatable-buttons" class="page-link"><i class="mdi mdi-chevron-left"></i></a>
      </li>

      <li :class="i+start-1==page?'paginate_button page-item active':'paginate_button page-item'"
          v-for="i in end-start+1"
          :key="i"
          style="margin: 0 3px"
      ><a @click="p(i)" aria-controls="datatable-buttons"
          style="line-height:15px"
          class="page-link">{{i+start-1}}</a></li>

      <li v-if="e2" :class="e2?'paginate_button page-item next':'paginate_button page-item next disabled'" >
        <a
            style="line-height:15px"
            @click="p2" aria-controls="datatable-buttons" tabindex="0" class="page-link"><i class="mdi mdi-chevron-right"></i></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    pageParam:{
      type:Object,
      default:null
    }
  },
  methods: {
    p1:function () {
      var a = this.start-1
      this.$emit('onpage',a)
    },
    p2:function () {
      var a = this.end+1
      this.$emit('onpage',a)
    },
    p:function (i) {
      this.$emit('onpage',this.start-1+i)
    },

    init:function () {
      this.page = this.pageParam.number+1

      //1-6
      //7-12
      //this.pageParam.totalPages = 100


      var perpage = 6
      var p = parseInt(this.pageParam.number / perpage)

      var start = p * perpage+1
      var end = start+5

      if(end>this.pageParam.totalPages)
        end = this.pageParam.totalPages

      if(start>1) {
        this.e1 = true
      }
      if(end<this.pageParam.totalPages) {
        this.e2 = true
      }

      this.end = end
      this.start = start
    }
  },
  created() {
  },
  mounted() {
    this.init()
  },
  watch:{
  },
  data() {
    return {
      e1:false,
      e2:false,
      page:0,
      end:0,
      start:0
    }
  },
}
</script>
export const GET_ADVERTISER_LIST = '/api/advertiser/list'

export const TABLE_FIELDS = [{
        key: 'advertiserName',
        label: '광고주명',
        class: 'w-30',
        slot: true
    },
    {
        key: 'advertiserNumber',
        label: '사업자등록번호',
        class: 'w-40'
    },
    {
        key: 'businessName',
        label: '업태 / 종목',
        class: 'w-30'
    }
]

export const GET_BUSINESS_LIST = '/api/company/business/list'

export const ADD_ADVERTISER = '/api/advertiser/add'

export const EDIT_ADVERTISER = '/api/advertiser/modify'

export const CHECK_BUSINESS_NUMBER_DUPLICATE = '/api/advertiser/validate'

export const GET_ADVERTISER_INFO = '/api/advertiser/detail'
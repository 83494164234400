<template>
  <div
      v-if="dlg_open"
  >
    <div
        class="modal fade show"
        style="display: block;background-color:#00000055;"
    >
      <div class="modal-dialog"
           style="width: 95% !important;max-width: 95% !important;"
      >
        <div class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title mt-0">
              매체별 반응률 및 단가 효율
            </h5>
            <button type="button" class="btn-close"
                    @click="dlg_open=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

<div style="display: flex;margin-bottom: 10px;">

  <div style="flex-grow: 1">

  </div>

  <button type="button"
          v-if="user.userinfo.roleId==3"
          style="margin-right: 10px"
          class="btn btn-dark waves-effect waves-light"
          @click="download"
  >
    다운로드
    <i class="fas fa-file-excel"></i>
  </button>
</div>

            <div
                style="max-height: 80vh;overflow-y: auto;padding:0"
            >
              <my-table
                  clickable
                  @rowClick="rowClick"
                  :head="['디바이스','매체유형','매체명','상품명','집행기간',
                  '광고비(원)::@NUM','GRPs::@NUM','노출수::@NUM','클릭수::@NUM','조회수::@NUM','참여수::@NUM',
                  'PCR(%)::@NUM','CTR(%)::@NUM','VTR(%)::@NUM',
                  'CPM(원)::@NUM','CPC(원)::@NUM','CPE(원)::@NUM','CPVC(원)::@NUM','CPI(원)::@NUM',
                  '타겟1','타겟2','타겟3','타겟4','초수'
                  ]"
                  :tableData="items"
                  :format="['deviceName','adProductMediaType','mediaName','productName','_slot_process',
                  '_slot_totalBudget','_slot_grps','_slot_imps','_slot_clicks','_slot_views','_slot_engagement',
                  '_slot_pcr','_slot_ctr','_slot_vtr',
                  '_slot_cpm','_slot_cpc','_slot_cpe','_slot_cpvc','_slot_cpi',
                  'target1','target2','target3','target4','duration'
                  ]"

                  :key="'tb_history_detail'"
                  :head-id="'tb_history_detail'"
                  :head-sort="['deviceName','adProductMediaType','mediaName','productName','endDate',
                  'totalBudget::@NUM','grps::@NUM','imps::@NUM','clicks::@NUM','views::@NUM','engagement::@NUM',
                  'pcr::@NUM','ctr::@NUM','vtr::@NUM',
                  'cpm::@NUM','cpc::@NUM','cpe::@NUM','cpvc::@NUM','cpi::@NUM',
                  'target1','target2','target3','target4','duration'
                  ]"
              >
                <template slot="_slot_process" slot-scope="slotProps">
                  <div style="display: flex;flex-direction: column">
                    <div style="min-width: 120px">
                      {{ slotProps.item.startDate }} ~ {{slotProps.item.endDate}}
                    </div>
                    <div class="progress animated-progess"
                         v-if="false"
                         style="min-width: 120px;margin-top: 6px"
                    >
                      <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </template>
                <template slot="_slot_cpm" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpm)" class="price"/>
                </template>
                <template slot="_slot_cpc" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpc)" class="price"/>
                </template>
                <template slot="_slot_cpe" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpe)" class="price"/>
                </template>
                <template slot="_slot_cpvc" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpvc)" class="price"/>
                </template>
                <template slot="_slot_cpi" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.cpi)" class="price"/>
                </template>
                <template slot="_slot_grps" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.grps)" class="price"/>
                </template>



                <template slot="_slot_views" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.views)" class="price"/>
                </template>
                <template slot="_slot_engagement" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.engagement)" class="price"/>
                </template>
                <template slot="_slot_pcr" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.pcr)" class="price"/>
                </template>
                <template slot="_slot_ctr" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.ctr)" class="price"/>
                </template>
                <template slot="_slot_vtr" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.vtr)" class="price"/>
                </template>
                <template slot="_slot_totalBudget" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.totalBudget)" class="price"/>
                </template>
                <template slot="_slot_imps" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.imps)" class="price"/>
                </template>
                <template slot="_slot_clicks" slot-scope="slotProps">
                  <span v-if="slotProps.item" v-html="my_number(slotProps.item.clicks)" class="price"/>
                </template>

              </my-table>


            </div>

          </div>

        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>

    <detail2 @download="download2" ref="detail"/>

  </div>
</template>

<script>
import Detail2 from "@/pages/history/detail2";
import MyTable from "@/components/common/MyTable";
import myError from "@/common/myError"
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  mixins:[myError],
  components: {
    Detail2,
    MyTable,
  },
  props: {
  },
  computed: {
    getRangeDates:function () {
      return moment(this.range.start).format('YYYY.MM.DD') + ' ~ ' + moment(this.range.end).format('YYYY.MM.DD')
    },
    ...mapGetters({
      user:'user'
    }),
  },
  data () {
    return {
      row_param:null,
      param:null,
      param2:null,
      items:[],
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      dlg_open:false,
      params: {
        advertiserId: '',
        advertiserName: '',
        advertiserNumber: '',
        businessId: ''
      },
      businessOptions: [],
    }
  },
  methods: {
    download2:function() {

      //console.log('d2')
      //return
      /*
      var url = '/api/history/download/dashboard/detail-detail?'

      this.$http.post(url,this.row_param)
          .then(({ data }) => {
            this.$refs.detail.open(data)
          })

       */

      var payloadString = Object.entries(this.row_param).map(e => e.join('=')).join('&');

      this.$http({
        method: 'GET',
        url: '/api/history/download/dashboard/detail-detail?'+payloadString,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'history-detail-detail.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    rowClick:function (item) {
      var url = '/api/history/dashboard/detail-detail'

      var o = {}
      o['productId'] = this.param2['productId']
      o['campaignHistoryId'] = this.param2['campaignHistoryId']
      o['mediaId'] = item['mediaId']
      o['adProductId'] = item['adProductId']
      o['dayPart'] = item['dayPart']
      o['program'] = item['program']
      o['duration'] = item['duration']

      this.row_param = o

      this.$http.post(url,o)
          .then(({ data }) => {
            this.$refs.detail.open(data)
          })
    },
    download:function () {
      //


      this.$http({
        method: 'GET',
        url: '/api/history/download/dashboard/detail-new?'+this.param,
        responseType: 'blob' // 가장 중요함
      })
          .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'history-detail.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    },
    my_number:function (v, fl) {
      if(fl)
        v = parseFloat(v).toFixed(2)

      try {
        v = this.numberWithCommas(v)
      } catch (e) {}

      return v
    },
    init:function () {
    },
    open:function (data, param, param2) {

      this.param = param
      this.param2 = param2
      this.items = data
      this.reset()
      this.dlg_open = true
      this.init()
    },
    onSubmit() {

    },
    reset() {
    }
  },
  watch: {
  }
}
</script>
